import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {connect} from "react-redux";
import LoaderButton from "../components/LoaderButton";
import {signIn} from "../services/AccountService";
import {bindActionCreators} from "redux";
import * as accountActions from "../actions/AccountActions";
import {Auth} from "aws-amplify";
import Alert from "@material-ui/lab/Alert";
import Button from '@material-ui/core/Button';
import {utilStyles} from "../Constants";
import TPageHeader from "../widgets/TPageHeader";
import TLink from "../widgets/TLink";
import TButtonLink from "../widgets/TButtonLink";


const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));


function Login(props) {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorCode, setErrorCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = 'Login';
        return () => {
            setIsLoading(false);
        };
    }, []);


    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    function validateConfirmationForm() {
        return confirmationCode.length > 0;
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        setErrorCode(undefined);
        setErrorMessage(undefined);
        try {
            let user = await signIn(email, password, {
                actions: props.actions
            });

            setIsLoading(false);
            const queryString = props.location.search;
            const params = new URLSearchParams(queryString);
            const redirect = params.get("redirect");
            if (redirect) {
                props.history.push(redirect);
            } else {
                props.history.push("/");
            }
        } catch (e) {
            console.log(e.message);
            // window.alert(JSON.stringify(e));
            setErrorCode(e.code);
            setErrorMessage(e.message);
            setIsLoading(false);
        }
    };


    const handleConfirmationSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        setErrorCode(undefined);
        setErrorMessage(undefined);
        try {
            await Auth.confirmSignUp(email, confirmationCode);
        } catch (e) {
            if (e.code === "CodeMismatchException") {
                setErrorCode(e.code);
                setErrorMessage(e.message);
            } else {
                console.error(e);
            }
        }
    };

    return (
        <Container maxWidth="xs">
            <TPageHeader icon={LockOutlinedIcon} title="Login" />
            <div className={classes.spacer3Top}>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <div className={classes.spacer1TopAndBottom}>
                        <LoaderButton
                            color="primary"
                            size="large"
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                            text="Login"
                            loadingText="Logging in..."
                            fullWidth
                        />
                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TLink props={props} to="/reset-password" text="Reset Password" className={classes.loginLink} />
                        </Grid>
                        <Grid item xs={12}>
                            <TButtonLink text="Don't have an account?"
                                         to='/signup'
                                         color="secondary"
                                         variant='outlined'
                                         size="small"
                                         fullWidth
                                         props={props} />
                        </Grid>
                    </Grid>
                </form>
            { errorCode === "PasswordResetRequiredException" &&
                <span>
                    <div className={classes.spacer2}></div>
                    <Alert severity="error">{errorMessage}</Alert>
                    <div className={classes.spacer1}></div>
                    <Button variant="outlined"
                            color="secondary"
                            size="small"
                            align="center"
                            fullWidth
                            href="/reset-password">
                        Reset Password
                    </Button>
                </span>
            }
            { errorCode === "NotAuthorizedException" &&
                <span>
                    <div className={classes.spacer2}></div>
                    <Alert severity="error">{errorMessage}</Alert>
                </span>
            }
            { errorCode === "UserNotFoundException" &&
            <span>
                <div className={classes.spacer2}></div>
                <Alert severity="error">{errorMessage}</Alert>
            </span>
            }
            { errorCode === "XXXXX" &&
                renderConfirmationForm()
            }
            </div>
        </Container>
    );

    function renderConfirmationForm() {
        return (
            <div>
                <div className={classes.spacer2}></div>
                <Typography
                    variant="body1"
                    align="center">
                    Please enter the confirmation code emailed to you, in the field below.
                </Typography>
                <form onSubmit={handleConfirmationSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Confirmation Code"
                        name="confirmationCode"
                        value={confirmationCode}
                        onChange={e => setConfirmationCode(e.target.value)}
                        id="confirmationCode"
                    />
                    <LoaderButton
                        size="large"
                        disabled={!validateConfirmationForm()}
                        type="submit"
                        isLoading={isLoading}
                        text="Verify"
                        loadingText="Verifying…"
                    />
                </form>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(Login);





