import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'


const useStyles = makeStyles(theme => ({
    input: {
        display: 'none',
    },
    progress: {
        margin: theme.spacing(0, 1),
    }
}));

export default function LoaderButton({
                                         isLoading,
                                         text,
                                         loadingText,
                                         className = "",
                                         disabled = false,
                                         isOutlined = false,
                                         ...props
                                     }) {
    const classes = useStyles();
    return (
        <span>
            <Button
                variant={isOutlined ? 'outlined' : 'contained' }
                disabled={disabled || isLoading}
                {...props}
            >
                {isLoading && <CircularProgress className={classes.progress} size={20} />}
                {!isLoading ? text : loadingText}
            </Button>
        </span>
    );

}

