import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone';
import {utilStyles} from '../Constants';
import TButtonLink from "../widgets/TButtonLink";
import TPageHeader from "../widgets/TPageHeader";
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from "@material-ui/icons/Business";

const useStyles = makeStyles(theme => ({
    buttonContent: {
        margin: theme.spacing(2),
    },
    ...utilStyles(theme)
}));

function SignupHome(props) {
    const classes = useStyles();

    useEffect(() => {
        document.title = 'Signup Home';
    }, []);

    return (
        <Container maxWidth="sm">
            <TPageHeader icon={FaceTwoToneIcon} title='How are you signing up?' />
            <div className={classes.spacer3Top}>
                <div className={classes.buttonContent}>
                <Typography component='div' variant='subtitle1'>
                    First, tell us how you are going to use Try a Buy.
                </Typography>
                <div className={classes.spacer3}></div>
                <TButtonLink text="I AM A BUYER"
                             to='/signup/buyer'
                             color="primary"
                             variant='contained'
                             props={props}
                             startIcon={<PersonIcon />}
                             fullWidth />
                <div className={classes.spacer2TopAndBottom}></div>
                <TButtonLink text="I AM WITH A RETAIL COMPANY"
                             to='/signup/retailer'
                             variant='contained'
                             color="secondary"
                             props={props}
                             startIcon={<BusinessIcon />}
                             fullWidth />
                </div>
            </div>
        </Container>
    );
}

export default SignupHome;

