import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import * as signupActions from "../actions/SignupActions";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {utilStyles} from '../Constants';
import CompanySearchField from "../components/CompanySearchField";
import Button from "@material-ui/core/Button";
import CompanySignupStepper from "../components/CompanySignupStepper";
import RepresentativeSignupStepper from "../components/RepresentativeSignupStepper";
import TPageHeader from "../widgets/TPageHeader";
import BusinessIcon from "@material-ui/icons/Business";


const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

function SignupRetailer(props) {
    const classes = useStyles();
    const { setShowStepper } = props.actions;

    useEffect(() => {
        document.title = 'Retailer Signup Start';
    }, []);

    function RenderStepper() {
        return (
            <div>
                { !props.isRetailerSet
                    ? <CompanySignupStepper {...props} />
                    : <RepresentativeSignupStepper  {...props} />
                }
            </div>
        );
    }

    return (
        <Container maxWidth="sm">
            { props.showStepper
                ? <RenderStepper />
                :
                <div>
                    <TPageHeader icon={BusinessIcon} title="Retailer Signup" />
                    <div className={classes.spacer2Top}>
                        <Typography component="div" variant="subtitle1" align="center">
                            First, let's try to find your company.
                        </Typography>
                        <div className={classes.spacer2Top}></div>
                        <CompanySearchField />
                    </div>
                    <div className={classes.spacer2Top}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={e => setShowStepper(true)}>
                            Not seeing your company listed?
                        </Button>
                    </div>
                </div>
            }
        </Container>
    );

}

const mapStateToProps = state => {

    return {
        retailer: state.signup.retailer,
        isRetailerSet:  state.signup.isRetailerSet,
        showStepper: state.signup.showStepper
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(signupActions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupRetailer);

