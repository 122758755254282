import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import BuyRequestCard from "../components/BuyRequestCard";
import {API} from "aws-amplify";
import {getUser} from "../services/AccountService";
import TProgress from "../widgets/TProgress";
import Container from "@material-ui/core/Container";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/core";
import {utilStyles} from "../Constants";

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

function BuyRequestPage(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [buyRequest, setBuyRequest] = useState(null);
    const [user, setUser] = useState(null);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'))
    // {isXs ? 'xs' : 'sm'}

    useEffect(() => {
        const buyRequestId = props.match.params.buyRequestId;
        API.get("tryabuy", '/buy-request/' + buyRequestId).then(response => {
           // console.log("*** found buy request: " + response);

            setUser(getUser());
            setBuyRequest(response);
            // document.title = `Buy Request - ${response.title}`;
            document.title = 'Buy Request';

            setIsLoaded(true);
        }).catch(error => {
            console.log(JSON.stringify(error.response));
            if (error.response.status == 404) {
                props.history.push('/not-found');
            }
        });
        return () => {
            setIsLoaded(true);
        };
    }, []);

    return (

        <Container maxWidth="sm" className={classes.spacer3Top}>
            {isLoaded
            ?
            <BuyRequestCard buyRequest={buyRequest} isLoaded={isLoaded} user={user} {...props} />
            :
            <div>
                <TProgress />
                <BuyRequestCard />
            </div>
            }
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        account: state.account
    };
};

export default connect(mapStateToProps)(BuyRequestPage);

