import React from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import {utilStyles} from '../Constants';
import CompanySignupStepper from "../components/CompanySignupStepper";
import TPageHeader from "../widgets/TPageHeader";
import BusinessIcon from '@material-ui/icons/Business';

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

export default function CompleteCompanySignupPage(props) {
    const classes = useStyles();

    const queryString = props.location.search;
    const params = new URLSearchParams(queryString);
    var goToStep4 = false;
    const step = params.get("s");
    if (step && step === '4') {
        goToStep4 = true;
    }

    return (
        <Container maxWidth="sm">
            <CompanySignupStepper {...props} goToStep4={goToStep4} />
        </Container>
    );
}

