import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import {utilStyles} from '../Constants';
import {getUser} from "../services/AccountService";
import CreateEditBuyRequest from "../components/CreateEditBuyRequest";
import TProgress from "../widgets/TProgress";
import CollectShippingAndPaymentInfoForm from "../components/CollectShippingAndPaymentInfoForm";

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

export default function CreateBuyRequestPage(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [hasPaymentInfo, setHasPaymentInfo] = useState(getUser().hasPaymentInfo);

    useEffect(() => {
        document.title = 'Create a Buy Request';
    }, []);

    const doneWithShippingAndBillingSetupCallback = function (hasPaymentInfo) {
        setHasPaymentInfo(hasPaymentInfo);
    };

    return (
        <Container maxWidth="sm">
            <div className={classes.spacer3}></div>
            {
                isLoading
                ? <TProgress text="We are confirming we have your shipping and payment information." />
                    :
                    hasPaymentInfo
                    ?
                        <CreateEditBuyRequest {...props} />
                        :
                        <CollectShippingAndPaymentInfoForm {...props} callback={doneWithShippingAndBillingSetupCallback} />
            }
        </Container>
    );
}

