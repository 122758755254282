import * as Constants from "./Constants";
import {Storage} from "aws-amplify";

export function generateUserFullName(user) {
    return `${user.firstName} ${user.lastName}`;
}

export function generateDisplayName(user) {
    return `${user.firstName} ${user.lastName.substr(0, 1)}.`;
}

export function isBuyRequestActive(buyRequest) {
    return buyRequest.status === Constants.BuyRequestStatus.ACTIVE.key;
}

export function isBuyRequestPending(buyRequest) {
    return buyRequest.status === Constants.BuyRequestStatus.PENDING.key;
}


export async function s3Upload(s3Key, file) {
    // const filename = `${Date.now()}-${file.name}`;

    // const stored = await Storage.vault.put(filename, file, {
    const stored = await Storage.put(s3Key, file, {
        contentType: file.type
    });

    return stored.key;
}

