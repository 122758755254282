import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import Routes from "./Routes";
import ButtonAppBar from "./components/ButtonAppBar";
import {connect} from 'react-redux';
import * as accountActions from "./actions/AccountActions";
import {bindActionCreators} from "redux";
import * as accountServices from "./services/AccountService";
import {makeStyles} from "@material-ui/core";
import {utilStyles} from "./Constants";
import CssBaseline from "@material-ui/core/CssBaseline";
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import TProgress from "./widgets/TProgress";
import Footer from "./components/Footer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import OffLimits from "./containers/OffLimits";
import 'react-image-lightbox/style.css';

// touched on 20210827

// From Logo
// COLORS
// - Blue: #00408c
// - Yellow: #fab119
// - Orange: #ff5622
// - White: #ffffff

const theme = createTheme({
    palette: {
        // https://material-ui.com/customization/palette/
        primary: {
            main: '#00408c' // blue
        },
        secondary: {
            main: '#ff5622', // orange
        },
        background: {
            default: "rgba(0, 0, 0, 0.04)"
        },
        contrastThreshold: 3
    },
    typography: {
        fontFamily:  [
            'Nunito',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    },
    overrides: {
        MuiCardContent: {
            root: {
                padding: 16,
                "&:last-child": {
                    paddingBottom: 16,
                }
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 34
            }
        }
    }
});

const useStyles = makeStyles(theme => ({
    verticalContentOffsetXs: {
        marginTop: theme.spacing(11)
    },
    verticalContentOffsetSmAndUp: {
        marginTop: theme.spacing(12)
        // position: 'fixed',
        // top: '200px'
    },
    ...utilStyles(theme)
}));

function App(props) {
    const [isLoading, setIsLoading] = useState(true);
    const classes = useStyles();
    // see: https://material-ui.com/customization/breakpoints/
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    // const isSmAndUp = useMediaQuery(theme.breakpoints.up('sm'));

    // gk
    const queryString = props.location.search;
    const params = new URLSearchParams(queryString);
    const [gk] = useState(params.get("gk"));
    // console.log(`REACT_APP_STAGE=${process.env.REACT_APP_STAGE} and gk=${params.get("gk")}`);

    (async () => {
        const options =  {
            actions: props.actions,
            callback: function () {
                setIsLoading(false);
            }
        };
        await accountServices.initializeAccount(options);
    })();

    function FullPage() {
        const childProps = {
            history: props.history,
            refresh: function() {}
        };

        return (
            <div>
                <ButtonAppBar {...childProps} />
                <div className={isXs ? classes.verticalContentOffsetXs : classes.verticalContentOffsetSmAndUp}></div>
                <Routes childProps={childProps} />
                <Footer {...childProps} />
            </div>
        )
    }

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            { process.env.REACT_APP_STAGE === 'dev' && gk !== 'lmi'
                ?
                <OffLimits />
                :
                isLoading ?
                    <TProgress />
                    :
                    <FullPage />
            }

        </MuiThemeProvider>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    }
};

export default withRouter(connect(null, mapDispatchToProps)(App));

