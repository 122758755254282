import React from "react";
import {Link} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export default ({ props: cProps, to: to, text: text, icon: Icon }) =>
    <Link href="#" onClick={() => {cProps.history.push(to)}}>
        <Icon />
        <Typography variant="caption" component="div">
            {text}
        </Typography>
    </Link>
