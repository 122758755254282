import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LoaderButton from "../components/LoaderButton";
import {Auth} from "aws-amplify";
import {utilStyles} from "../Constants";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Alert from "@material-ui/lab/Alert";
import TPageHeader from "../widgets/TPageHeader";
import TProgress from "../widgets/TProgress";

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));


export default function ForgotPassword(props) {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [step, setStep] = useState(1);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = 'Reset Password';
    }, []);

    function validateEmailForm() {
        return email.length > 0;
    }

    function validateConfCodeForm() {
        return password.length > 0 && confirmationCode.length > 0;
    }

    const handleEmailSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        setErrorMessage(undefined);
        await Auth.forgotPassword(email)
            .then(data => {
                setStep(2);
                setIsLoading(false);
            })
            .catch(e => {
                console.log(e);
                setErrorMessage(e.message);
                setIsLoading(false);
            });

    };

    const handleConfirmationSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        setErrorMessage(undefined);
        Auth.forgotPasswordSubmit(email, confirmationCode, password)
            .then(data => {
                setStep(3);
                setIsLoading(false);
            })
            .catch(e => {
                console.log(e);
                setErrorMessage(e.message);
                setIsLoading(false);
            });
    };

    function renderStep1Email() {
        return (
            <form noValidate onSubmit={handleEmailSubmit}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    autoComplete="email"
                    autoFocus
                />
                <LoaderButton
                    color="primary"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateEmailForm()}
                    text="Send Code to Email"
                    loadingText="Sending email..."
                    fullWidth
                />
            </form>
        );
    }

    function renderStep2ConfirmationCodeAndPassword() {
        return (
            <form noValidate onSubmit={handleConfirmationSubmit}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Confirmation Code"
                    name="confirmationCode"
                    value={confirmationCode}
                    onChange={e => setConfirmationCode(e.target.value)}
                    id="confirmationCode"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <LoaderButton
                    color="primary"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateConfCodeForm()}
                    text="Confirm Code and Reset Password"
                    loadingText="Resetting..."
                    fullWidth
                />
            </form>
        );
    }

    function renderStep3Completed() {
        return (
            <div className={classes.spacer2}>
                <Typography className={classes.successMessage}
                            variant="h6"
                            align="center">
                    Password reset.
                </Typography>
            </div>
        );
    }

    function renderStep() {
        switch (step) {
            case 1:
                return renderStep1Email();
            case 2:
                return renderStep2ConfirmationCodeAndPassword();
            case 3:
                return renderStep3Completed();
            default:
                return 'Unknown step';
        }
    }

    return (
        <Container maxWidth="xs">
            <TPageHeader icon={VisibilityIcon} title="Reset Password" />
            {
                isLoading
                    ? <TProgress />
                    : renderStep()
            }
            {errorMessage &&
                <Alert severity="error" className={classes.spacer1Top}>
                    {errorMessage}
                </Alert>
            }
        </Container>
    );




}






