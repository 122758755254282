

export const Category = {
    APPLIANCES: {
        key: 'appliances',
        label: 'Appliances'
    },
    ARTS_AND_CRAFTS: {
        key: 'arts-and-crafts',
        label: 'Arts and Crafts'
    },
    BABY: {
        key: 'baby',
        label: 'Baby'
    },
    CLOTHING_SHOES_AND_APPAREL: {
        key: 'clothing-shoes-and-apparel',
        label: 'Clothing, Shoes and Apparel'
    },
    COMPUTERS: {
        key: 'computers',
        label: 'Computers'
    },
    ELECTRONICS: {
        key: 'electronics',
        label: 'Electronics'
    },
    FURNITURE: {
        key: 'furniture',
        label: 'Furniture'
    },
    HOME_AND_KITCHEN: {
        key: 'home-and-kitchen',
        label: 'Home and Kitchen'
    },
    MUSICAL_INSTRUMENTS: {
        key: 'musical-instruments',
        label: 'Musical Instruments'
    },
    PRO_AUDIO: {
        key: 'pro-audio',
        label: 'Pro Audio Equipment'
    },
    SPORTS_AND_OUTDOORS: {
        key: 'sports-and-outdoors',
        label: 'Sports and Outdoors'
    },
    TOYS: {
        key: 'toys',
        label: 'Toys'
    },
    keyToCategory: function(categoryKey) {
        let returnCategory = 'UNDEFINED';
        let categories = Object.getOwnPropertyNames(Category);
        categories.forEach(function(categoryString, idx) {
            let category = Category[categoryString];
            if (category.key === categoryKey) {
                returnCategory = category;
            }
        });
        return returnCategory;
    },
    values: function () {
        let enumValues = [];
        for (let key in Category) {
            let value = Category[key];
            if (typeof value !== 'function') {
                enumValues.push(value);
            }
        }
        return enumValues;
    }
};


export const BuyRequestSpecification = {
    BRAND: {
        key: 'brand',
        label: 'Brand'
    },
    MODEL: {
        key: 'model',
        label: 'Model'
    },
    YEAR: {
        key: 'year',
        label: 'Year'
    },
    COLOR: {
        key: 'color',
        label: 'Color'
    },
    SIZE: {
        key: 'size',
        label: 'Size'
    },
    MEASUREMENTS: {
        key: 'measurements',
        label: 'Measurements'
    },
    keyToSpecification: function(key) {
        let returnSpecification = 'UNDEFINED';
        let specifications = Object.getOwnPropertyNames(BuyRequestSpecification);
        specifications.forEach(function(specificationString, idx) {
            let specification = BuyRequestSpecification[specificationString];
            if (specification.key === key) {
                returnSpecification = specification;
            }
        });
        return returnSpecification;
    },
    toLabel: function(key) {
        const specification = BuyRequestSpecification.keyToSpecification(key);
        return specification.label;
    },
    values: function () {
        let enumValues = [];
        for (let key in BuyRequestSpecification) {
            let value = BuyRequestSpecification[key];
            if (typeof value !== 'function') {
                enumValues.push(value);
            }
        }
        return enumValues;
    }
};

export const BuyRequestStatus = {
    ACTIVE: {
        key: 'active',
        label: 'Active'
    },
    PENDING: {
        key: 'pending',
        label: 'Pending'
    },
    COMPLETE: {
        key: 'complete',
        label: 'Complete'
    },
    EXPIRED: {
        key: 'expired',
        label: 'Expired'
    },
    CANCELLED: {
        key: 'cancelled',
        label: 'Cancelled'
    },
    SUSPENDED: {
        key: 'suspended',
        label: 'Suspended'
    },
    PENDING_BUYER_DECISION: {
        key: 'pending-buyer-decision',
        label: 'Pending Buyer Decision'
    },
    keyToStatus: function(key) {
        let returnStatus = 'UNDEFINED';
        let statuses = Object.getOwnPropertyNames(BuyRequestStatus);
        statuses.forEach(function(statusString, idx) {
            let status = BuyRequestStatus[statusString];
            if (status.key === key) {
                returnStatus = status;
            }
        });
        return returnStatus;
    },
    toLabel: function(key) {
        const status = BuyRequestStatus.keyToStatus(key);
        return status.label;
    }
};

export const UserStatus = {
    ACTIVE: {
        key: 'active',
        label: 'Active'
    },
    PENDING: {
        key: 'pending',
        label: 'Pending'
    },
    CLOSED: {
        key: 'closed',
        label: 'Closed'
    },
    SUSPENDED: {
        key: 'suspended',
        label: 'Suspended'
    },
    keyToStatus: function(key) {
        let returnStatus = 'UNDEFINED';
        let statuses = Object.getOwnPropertyNames(UserStatus);
        statuses.forEach(function(statusString, idx) {
            let status = UserStatus[statusString];
            if (status.key === key) {
                returnStatus = status
            }
        });
        return returnStatus;
    },
    toLabel: function(key) {
        const status = UserStatus.keyToStatus(key);
        return status.label;
    }
};

export const RetailerStatus = {
    ACTIVE: {
        key: 'active',
        label: 'Active'
    },
    PENDING: {
        key: 'pending',
        label: 'Pending'
    },
    CLOSED: {
        key: 'closed',
        label: 'Closed'
    },
    SUSPENDED: {
        key: 'suspended',
        label: 'Suspended'
    },
    keyToStatus: function(key) {
        let returnStatus = 'UNDEFINED';
        let statuses = Object.getOwnPropertyNames(RetailerStatus);
        statuses.forEach(function(statusString, idx) {
            let status = RetailerStatus[statusString];
            if (status.key === key) {
                returnStatus = status
            }
        });
        return returnStatus;
    },
    toLabel: function(key) {
        const status = RetailerStatus.keyToStatus(key);
        return status.label;
    }
};


export const EventType = {
    TEST_HELLO: {
        key: 'test.hello'
    },
    CONTACT_US: {
        key: 'contact-us'
    },
    BUY_REQUEST_CREATE: {
        key: 'buyRequest.create'
    },
    BUY_REQUEST_MESSAGE: {
        key: 'buyRequest.message'
    },
    BUY_REQUEST_MESSAGE_REPLY: {
        key: 'buyRequest.message.reply'
    },
    keyToEventType: function(key) {
        let returnEventType = 'UNDEFINED';
        let eventTypes = Object.getOwnPropertyNames(EventType);
        eventTypes.forEach(function(eventTypeAsString) {
            let eventType = EventType[eventTypeAsString];
            if (eventType.key === key) {
                returnEventType = eventType;
            }
        });
        return returnEventType;
    }
};


export const utilStyles = theme => ({
    /*
     * Branding Colors from logo
     *
     *  Blue: #00408c
     *  Yellow: #fab119
     *  Orange: #ff5622
     *  White: #ffffff
     */
    successMessage: {
        // color: theme.palette.success.main
        color: "green"
    },
    greenMessage: {
        color: "green"
    },
    yellowMessage: {
        color: "yellow"
    },
    orangeMessage: {
        color: "orange"
    },
    redMessage: {
        color: "red"
    },
    blueMessage: {
        color: "blue"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    bold: {
        fontWeight: 600
    },
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    paperCentered: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        alignItems: 'center'
    },
    pullRight: {
        float: "right"
    },
    spacer1: {
        margin: theme.spacing(1)
    },
    spacer1Top: {
        marginTop: theme.spacing(1)
    },
    spacer1Bottom: {
        marginBottom: theme.spacing(1)
    },
    spacer1TopAndBottom: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    spacer1Left: {
        marginLeft: theme.spacing(1)
    },
    spacer1Right: {
        marginRight: theme.spacing(1)
    },
    spacer2: {
        margin: theme.spacing(2)
    },
    spacer2Top: {
        marginTop: theme.spacing(2)
    },
    spacer2Right: {
        marginRight: theme.spacing(2)
    },
    spacer2Left: {
        marginLeft: theme.spacing(2)
    },
    spacer2Bottom: {
        marginBottom: theme.spacing(2)
    },
    spacer2TopAndBottom: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    spacer3: {
        margin: theme.spacing(3)
    },
    spacer3Top: {
        marginTop: theme.spacing(3)
    },
    spacer3Bottom: {
        marginBottom: theme.spacing(3)
    },
    spacer3TopAndBottom: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    spacer3Left: {
        marginLeft: theme.spacing(3)
    },
    spacer4: {
        margin: theme.spacing(4)
    },
    spacer4Top: {
        marginTop: theme.spacing(4)
    },
    spacer4Bottom: {
        marginBottom: theme.spacing(4)
    },
    spacer4TopAndBottom: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    spacer4Left: {
        marginLeft: theme.spacing(4)
    },
    spacer5: {
        margin: theme.spacing(5)
    },
    spacer5Top: {
        marginTop: theme.spacing(5)
    },
    spacer5Bottom: {
        marginBottom: theme.spacing(5)
    },
    spacer5TopAndBottom: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    spacer5Left: {
        marginLeft: theme.spacing(5)
    },
    tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
    },
    hr: {
        borderTop: '1px solid gray'
    }
});