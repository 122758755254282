import React, {useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Divider, Hidden, Paper} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import * as Constants from "../Constants";
import {BuyRequestStatus, utilStyles} from "../Constants";
import Grid from "@material-ui/core/Grid";
import Skeleton from '@material-ui/lab/Skeleton';
import moment from "moment";
import {isBuyRequestActive, isBuyRequestPending} from "../Utils";
import {canUserPerformActions, isRetailerActive, userOwnsBuyRequest} from "../services/ApplicationService";
import Button from "@material-ui/core/Button";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import {isBuyer, isRetailer} from "../services/AccountService";
import NumberFormat from "react-number-format";
import {Storage} from "aws-amplify";
import * as tmpActions from "../actions/TmpActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TButtonLink from "../widgets/TButtonLink";
import TLink from "../widgets/TLink";
import Container from "@material-ui/core/Container";
import { ImageListItem } from '@material-ui/core'
import Lightbox from "react-image-lightbox";
import ImageList from '@material-ui/core/ImageList'

const useStyles = makeStyles(theme => ({
    specsAvatar: {
        width: 35,
        height: 35
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        // transform: 'translateZ(0)',
    },
    paperTop: {
        marginBottom: theme.spacing(0),
        borderRadius: '4px 4px 0px 0px',
        boxShadow: '5px 3px 5px 0px rgba(0,0,0,0.12)'
    }, // https://www.w3schools.com/cssref/css3_pr_box-shadow.asp
    paperTopXs: {
        padding: theme.spacing(2)
    },
    paperTopSm: {
        padding: theme.spacing(3, 3, 2, 3)
    },
    paperMiddle: {
        boxShadow: '5px 4px 5px 0px rgba(0,0,0,0.12)'
    },
    paperBottom: {
        marginTop: theme.spacing(0),
        borderRadius: '0px 0px 4px 4px',
        boxShadow: '5px 4px 5px 0px rgba(0,0,0,0.12)'
    },
    paperBottomXs: {
        padding: theme.spacing(2)
    },
    paperBottomSm: {
        padding: theme.spacing(2, 3, 3, 3)
    },
    mobileFont: {
        fontSize: '90%'
        // fontSize: 'x-small'
    },
    lightbox: {

        // position: 'fixed',
        // top: '200px'
    },
    ...utilStyles(theme)
}));

function BuyRequestCard(props) {
    const classes = useStyles();
    const [imagesLoaded, setImagesLoaded] = React.useState(false);
    const buyRequest = props.buyRequest;
    const { setTmpObject } = props.actions;
    const [openLightBox, setOpenLightBox] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(0);

    // see: https://material-ui.com/customization/breakpoints/
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    let paperTopSizeStyling = isXs ? classes.paperTopXs : classes.paperTopSm;
    let paperBottomSizeStyling = isXs ? classes.paperBottomXs : classes.paperBottomSm;
    let cardSpacing = isXs ? classes.spacer2TopAndBottom : classes.spacer4TopAndBottom;

    useEffect(() => {
        if (buyRequest) {
            let imagesToBeLoaded = buyRequest.images.length;
            buyRequest.images.forEach(function (image) {
                const key = `buy-requests/${buyRequest.buyRequestId}/${image.fileName}`;
                Storage.get(key, {expires: 60})
                    .then(result => {
                            // console.log(result);
                            image.absoluteImageUIrl = result;
                            imagesToBeLoaded--;
                            if (imagesToBeLoaded === 0) {
                                setImagesLoaded(true);
                            }
                        }
                    )
                    .catch(err => console.log(err));
            });
        }
        // return () => {
        //     setIsLoading(false);
        // };
    }, []);

    return render();

    function getBuyerInitials(displayName) {
        let firstInit = displayName.substr(0, 1);
        let lastInitialIdx = displayName.indexOf(' ') + 1;
        let lastInitial = displayName.substr(lastInitialIdx, 1);
        return firstInit + lastInitial;
    }

    function getSpecInitial(label) {
        return label.substr(0, 1).toUpperCase();
    }

    function getFormattedDate(unixSeconds) {
        return moment(unixSeconds).format('MMMM Do YYYY');
    }

    function getDisplayTitle() {
        return props.buyRequest.title.substr(0, 50);
    }

    function getHeaderDisplayText(subject) {
        let displayText = subject;
        if (isXs && displayText.length >= 20) {
            displayText = `${displayText.substr(0, 20)}...`;
        }
        return displayText;
    }

    function getStatusClassNames() {
        const status = props.buyRequest.status;
        let statClasses = [classes.uppercase];
        if (isXs) {
            statClasses.push(classes.mobileFont);
        }
        switch (status) {
            case BuyRequestStatus.ACTIVE.key: statClasses.push(classes.greenMessage);      break;
            case BuyRequestStatus.PENDING.key: statClasses.push(classes.orangeMessage);    break;
            case BuyRequestStatus.COMPLETE.key: statClasses.push(classes.blueMessage);     break;
            case BuyRequestStatus.EXPIRED.key: statClasses.push(classes.redMessage);       break;
            default: statClasses.push(classes.redMessage);
        }

        return statClasses.join(' ');
    }

    function handleMessageBuyerClick() {
        setTmpObject(buyRequest);
        props.history.push(`buy-request/${buyRequest.buyRequestId}/message`);
    }

    function render() {
        return (
            <Container maxWidth={isXs ? 'xs' : 'sm'} disableGutters={true} className={cardSpacing}>
                <div>
                    <Paper className={[classes.paperTop, paperTopSizeStyling].join(' ')} elevation={0}>
                        <HeaderSection />
                    </Paper>
                    <ImagesSection />
                    <Paper className={[classes.paperBottom, paperBottomSizeStyling].join(' ')} elevation={0}>
                        <ContentSection />
                    </Paper>
                </div>
            </Container>
        );
    }

    function HeaderSection() {
        return (
            <div>
                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                    {/*BROWSER ISSUE: (works in Chrome desktop but breaks in Safari and Chrome mobile.
                    ISSUE: This grid spacing is due to some weird constraint on the first part wrapping (regardless of nowrap). Flex is restricting its length, causing buyer name to break to a new line.
                    SOLUTION: add the xs={8} to allocate a spoon-fed 2/3s of the width.*/}
                    <Grid item xs={8}>
                        <Grid container spacing={useMediaQuery(theme.breakpoints.down('xs')) ? 1 : 2} alignItems="center">
                            <Grid item>
                                {
                                    props.isLoaded ? (
                                        <Avatar className={classes.avatar}>
                                            {getBuyerInitials(buyRequest.displayName)}
                                        </Avatar>
                                    ) : (
                                        <Skeleton variant="circle" width={35} height={35}/>
                                    )
                                }
                            </Grid>
                            <Grid item >
                                <Typography component="span" variant="body1"
                                            className={isXs ? classes.mobileFont : ""}>
                                    {props.isLoaded
                                        ?
                                        getHeaderDisplayText(props.buyRequest.displayName)
                                        :
                                        <Skeleton height={6} width={40} />
                                    }
                                </Typography>
                                <Typography component="div" variant="body2"
                                            color="textSecondary"
                                            className={isXs ? classes.mobileFont : ""}>
                                    {props.isLoaded
                                        ?
                                        getFormattedDate(buyRequest.createTimestamp)
                                        :
                                        <Skeleton height={6} width={30} />
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {
                            props.isLoaded ? (
                                <span className={getStatusClassNames()}>
                                    {Constants.BuyRequestStatus.toLabel(buyRequest.status)}
                                </span>
                            ) : (
                                <Skeleton height={6} width={20} />
                            )
                        }
                    </Grid>
                </Grid>
                <div className={classes.spacer1TopAndBottom}></div>
                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h5" component="h5">
                            {props.isLoaded ? (
                                <TLink to={`/buy-request/${buyRequest.buyRequestId}`}
                                       props={props}
                                       text={getDisplayTitle()}/>
                            ) : (
                                <Skeleton height={6} width="80%"/>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" component="h6">
                            {props.isLoaded ? (
                                <NumberFormat value={buyRequest.price}
                                              displayType={'text'}
                                              thousandSeparator={true}
                                              prefix={'$'}
                                              decimalScale="2"
                                              fixedDecimalScale="true"/>
                            ) : (
                                <Skeleton height={6} width="80%"/>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }

    function ImagesSection() {


        return (
            <div>
                {imagesLoaded &&
                <Paper className={classes.paperMiddle} elevation={0}>
                    <ImageList className={classes.gridList}
                              cols={buyRequest.images.length <= 2 ? buyRequest.images.length : 2}>
                        {buyRequest.images.map((image) => (
                            <ImageListItem key={image.absoluteImageUIrl}
                                          onClick={e => setOpenLightBox(true)}>
                                <img src={image.absoluteImageUIrl} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Paper>
                }
                {openLightBox && (
                    <Lightbox className={classes.lightbox}
                        mainSrc={buyRequest.images[photoIndex].absoluteImageUIrl}
                        nextSrc={buyRequest.images[(photoIndex + 1) % buyRequest.images.length].absoluteImageUIrl}
                        prevSrc={buyRequest.images[(photoIndex + buyRequest.images.length - 1) % buyRequest.images.length].absoluteImageUIrl}
                        onCloseRequest={() => setOpenLightBox(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + buyRequest.images.length - 1) % buyRequest.images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % buyRequest.images.length)
                        }
                    />
                )}
            </div>
        );
    }

    function ContentSection() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                        {props.isLoaded ?
                            <div>
                                <Typography component="div" variant="overline" color="textSecondary">
                                    Specifications
                                </Typography>
                                <Divider/>
                            </div>
                            : null}
                        {props.isLoaded ?
                            (
                                <List dense={true} disablePadding={true}>
                                    {getSpecifications(buyRequest.specifications)}
                                </List>
                            ) : (
                                <Skeleton height={6} width="40%"/>
                            )}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        {props.isLoaded ?
                            <div>
                                <Typography component="div" variant="overline" color="textSecondary">
                                    Additional Info
                                </Typography>
                                <Divider/>
                            </div>
                            : null}
                        {props.isLoaded ?
                            (
                                <div className={classes.spacer1Top}>{buyRequest.additionalInfo}</div>
                            ) : (
                                <Skeleton height={6} width="40%"/>
                            )}
                    </Grid>
                </Grid>
                <div className={classes.spacer1Top}></div>
                <Grid container spacing={2} alignItems="center">
                    <Hidden xsDown>
                        <Grid item xs={12} sm={6}>
                            {props.isLoaded ? (
                                <span>
                                    <Typography component="span" variant="body2" color="textSecondary">
                                        Listing expires&nbsp;
                                    </Typography>
                                    <Typography component="span" variant="body2">
                                        {getFormattedDate(buyRequest.expirationTimestamp)}
                                    </Typography>
                                </span>
                            ) : (
                                <Skeleton height={6} width="40%"/>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    {getUserContent()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden smUp>
                        <Grid item xs={12} sm={6}>
                            <Grid container justifyContent="flex-end">
                                <Grid item xs={12}>
                                    {getUserContent()}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {props.isLoaded ? (
                                <div align="center">
                                    <Typography component="span" variant="body2" color="textSecondary">
                                        Listing expires&nbsp;
                                    </Typography>
                                    <Typography component="span" variant="body2">
                                        {getFormattedDate(buyRequest.expirationTimestamp)}
                                    </Typography>
                                </div>
                            ) : (
                                <Skeleton height={6} width="40%"/>
                            )}
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        );
    }

    function getSpecifications(specifications) {
        return (
            specifications.map(function(item) {
                const label = Constants.BuyRequestSpecification.toLabel(item.specType);
                return (
                    <ListItem key={item.specType}>
                        <ListItemAvatar>
                            <Avatar variant="rounded" className={[classes.avatar, classes.specsAvatar].join(' ')}>
                                {getSpecInitial(label)}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.value} secondary={label} />
                    </ListItem>
                );
            })
        );
    }

    function getUserContent() {
        if (props.user == null) {
            return getGuestContent();
        } else if (isBuyer(props.user)) {
            return getBuyerContent();
        } else if (isRetailer(props.user)) {
            return getRetailerContent();
        } else {
            return "UNIDENTIFIED!"
        }
    }

    function getGuestContent() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {props.isLoaded ? (
                        <TButtonLink text="Login for Actions"
                                    to='/login'
                                    variant='outlined'
                                    size='small'
                                    fullWidth={!isSm}
                                    props={props} />
                        ) : (
                            <Skeleton height={6} width="80%"/>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }

    function getBuyerContent() {
        return (
            <div>
                { userOwnsBuyRequest(props.user, props.buyRequest)
                    ?
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {props.isLoaded ? (
                                <TButtonLink text="Modify"
                                             to={`/buy-request/${props.buyRequest.buyRequestId}/edit`}
                                             variant='outlined'
                                             size={isXs ? 'medium' : 'small'}
                                             color="primary"
                                             fullWidth={!isSm}
                                             props={props}/>
                            ) : (
                                <Skeleton height={6} width="80%"/>
                            )}
                        </Grid>
                    </Grid>
                    : ""
                }
            </div>
        );
    }

    function getRetailerContent() {
        return (
            <div>
                { isBuyRequestActive(props.buyRequest) &&
                    canUserPerformActions(props.user) &&
                    isRetailerActive(props.retailer) &&
                    <div>
                        {props.isLoaded ? (
                            <TButtonLink text="Sell"
                                     to={`/buy-request/${props.buyRequest.buyRequestId}/sale/initiate`}
                                     variant='contained'
                                     size={isXs ? 'large' : 'small'}
                                     color="primary"
                                     props={props}
                                     fullWidth={!isSm} />
                        ) : (
                            <Skeleton height={6} width="80%"/>
                        )}
                        {isSm ?
                            <span className={classes.spacer2Left}></span>
                            :
                            <div className={classes.spacer1Top}></div>
                        }
                        {props.isLoaded ? (
                            <Button variant="outlined"
                                    size={isXs ? 'medium' : 'small'}
                                    onClick={handleMessageBuyerClick}
                                    fullWidth={!isSm}>
                                Message Buyer
                            </Button>
                        ) : (
                        <Skeleton height={6} width="80%"/>
                        )}
                    </div>
                }
                { isBuyRequestPending(props.buyRequest)
                    ?
                (buyRequest.retailerId === props.user.retailerId &&
                            canUserPerformActions(props.user))
                        ?
                            <TButtonLink text="Complete Sale"
                                        to={`/buy-request/${props.buyRequest.buyRequestId}/sale/complete`}
                                        variant='contained'
                                        size={isXs ? 'large' : 'small'}
                                        color="primary"
                                        fullWidth={!isSm}
                                        props={props} />
                        :
                            <Typography component="div" variant="overline" className={classes.orangeMessage}>
                                Sale Pending
                            </Typography>
                    :
                    ""
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        retailer: state.account.retailer
    };
};

const mapDispatchToProps = (dispatch) => {
    const actions = { ...tmpActions };
    return {
        actions: bindActionCreators(actions,  dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyRequestCard);

