import {RetailerStatus, UserStatus} from "../Constants";
import {Storage} from "aws-amplify";

export async function userOwnsBuyRequest(user, buyRequest) {
    return user.userId === buyRequest.userId;
}

export function canUserPerformActions(user) {
    return user.status === UserStatus.ACTIVE.key;
}

export function isRetailerActive(retailer) {
    return retailer && retailer.status === RetailerStatus.ACTIVE.key;
}

export async function buildBuyRequestAbsoluteImageUrl(buyRequestId, fileName) {
    // const absoluteUrl = `https://${config.s3.BUCKET}/public/buy-requests/${buyRequestId}/${fileName}`;
    // console.log('absoluteUrl = ' + absoluteUrl);

    let absoluteUrl = null;
    const key = `buy-requests/${buyRequestId}/${fileName}`;
    await Storage.get(key, { expires: 60 })
        .then(result => {
                console.log(result);
                absoluteUrl = result;
                // debugger;
            }
        )
        .catch(err => console.log(err));
    debugger;

    return absoluteUrl;
}

// export async function decorateLoadedBuyRequest(buyRequest)

