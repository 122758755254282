import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import {utilStyles} from '../Constants';
import TPageHeader from "../widgets/TPageHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {NavHashLink} from "react-router-hash-link";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InfoIcon from '@material-ui/icons/Info';
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone';
import BusinessIcon from "@material-ui/icons/Business";
import HelpIcon from '@material-ui/icons/Help';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SettingsIcon from '@material-ui/icons/Settings';
import TButtonLink from "../widgets/TButtonLink";

const useStyles = makeStyles(theme => ({
    navLinkActive: {
        color: theme.palette.secondary.main
    },
    headingSpacer: {
        marginBottom: theme.spacing(10)
    },
    ...utilStyles(theme)
}));

export default function LearnMorePage(props) {
    const classes = useStyles();
    // see: https://material-ui.com/customization/breakpoints/
    const theme = useTheme();
    // const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmAndUp = useMediaQuery(theme.breakpoints.up('sm'));

    // inner statics
    const navLinkVariant = "subtitle2";
    const contentSectionClasses = classes.spacer2TopAndBottom;

    useEffect(() => {
        document.title = 'Learn More';
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxWidth="sm">
            <TPageHeader icon={InfoIcon} title='Learn More' />
            <div className={classes.spacer1Top}>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <Menu />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <OurValueToYouContent />
                        <span  className={classes.headingSpacer}></span>
                        <HowItWorksContent />
                        <BuyerGuidelinesContent />
                        <RetailerGuidelinesContent />
                        <FAQsContent />
                        <div className={classes.spacer4Top}>
                            <TButtonLink text="Questions? Please reach out."
                                         to='/contact-us'
                                         color="primary"
                                         variant='outlined'
                                         props={props}
                                         fullWidth />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );

    function Menu() {
        return (
            <List dense={true}>
                <ListItem>
                    <ListItemIcon>
                        <FaceTwoToneIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <NavHashLink to="#ourValueToYou"
                                         activeClassName="selected"
                                         activeStyle={{ color: theme.palette.secondary.main }}
                                         smooth>
                                <Typography variant={navLinkVariant} component="span">
                                    Our Value To You
                                </Typography>
                            </NavHashLink>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <SettingsIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <NavHashLink to="#howItWorks"
                                         activeClassName="selected"
                                         activeStyle={{ color: theme.palette.secondary.main }}
                                         smooth>
                                <Typography variant={navLinkVariant} component="span">
                                    How It Works
                                </Typography>
                            </NavHashLink>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        {/*<FaceTwoToneIcon color="primary" />*/}
                        <ShoppingCartIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <NavHashLink to="#buyerGuidelines"
                                         activeClassName="selected"
                                         activeStyle={{ color: theme.palette.secondary.main }}
                                         smooth>
                                <Typography variant={navLinkVariant} component="span">
                                    Buyer Guidelines
                                </Typography>
                            </NavHashLink>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <BusinessIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <NavHashLink to="#retailerGuidelines"
                                         activeClassName="selected"
                                         activeStyle={{ color: theme.palette.secondary.main }}
                                         smooth>
                                <Typography variant={navLinkVariant} component="span">
                                    Retailer Guidelines
                                </Typography>
                            </NavHashLink>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <HelpIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <NavHashLink to="#faqs"
                                         activeClassName="selected"
                                         activeStyle={{ color: theme.palette.secondary.main }}
                                         smooth>
                                <Typography variant={navLinkVariant} component="span">
                                    FAQs
                                </Typography>
                            </NavHashLink>
                        }
                    />
                </ListItem>
            </List>
        );
    }


    function OurValueToYouContent() {
        return (
            <div id="ourValueToYou" className={contentSectionClasses}>
                <Typography component="h6" variant="h6" align="center">
                    Our Value To You
                </Typography>
                <Typography component="div" variant="body2">
                    <p>Try a Buy is an online marketplace platform facilitating buying and selling of <b><u>new</u></b> retail goods where buyers and retailers benefit.</p>
                    <p>
                        Our intent is to make a value-add experience for both our buyers and retailers.
                        Buyers benefit from discounts on new items. Retailers benefit from making an immediate discounted sale for a one-time commitment.
                    </p>
                </Typography>
            </div>
        )
    }

    function HowItWorksContent() {
        return (
            <div id="howItWorks" className={contentSectionClasses}>
                <Typography component="h6" variant="h6" align="center">
                    How It Works
                </Typography>
                <Typography component="div" variant="body2">
                    <ol>
                        <li>The online marketplace allows buyers to post a requested item for purchase, or a "buy request", visible for browsing by retailers and other buyers.</li>
                        <li>A buy request lists the desired item, the requested discounted price, category, specifications to disambiguate what is desired, images and additional information.</li>
                        <li>Retailers browse open requests, typically in their category of interest and can choose to sell an item. If needed a retailer may message the buyer for additional questions.</li>
                        <li>If the retailer decides to sell an item, it initiates a sale, which puts the item into pending status so no other retailer can sell the item.</li>
                        <li>Once the item has been shipped by the retailer, the buyer's card is charged. He/she will be notified via email containing a link to the order screen for tracking your item.</li>
                        <li>Retailers are generally paid-out in 2-4 business days.</li>
                    </ol>
                    <p><b>Done!</b> The buyer has a new item they bought at discount and the retailer was able to sell it quickly for a one-time discount.</p>
                </Typography>
            </div>
        )
    }

    function BuyerGuidelinesContent() {
        return (
            <div id="buyerGuidelines" className={contentSectionClasses}>
                <Typography component="h6" variant="h6" align="center">
                    Buyer Guidelines
                </Typography>
                <Typography component="div" variant="body2">
                    <ul>
                        <li>Try to be as specific as possible in your buy request. This helps retailers understand exactly what you want such as make, model, color, year. Additionally this helps to avoids complications later such as returns of being sold the wrong item due to not being specific enough.</li>
                        <li>Taxes standard for the product and state are applied to the final charge.</li>
                        <li>Shipping is paid for by the buyer. This is to help retailers offset their costs. Unless the retailer offers to pay for it up-front.</li>
                        <li>Only buyers and fulfillment in the United States are permitted at this time on the Try a Buy online marketplace.</li>
                        <li>Returns are handled outside of the Try a Buy marketplace. When buying through Try a Buy, retailers are expected (but not held to) use their normal store return policy.</li>
                    </ul>
                </Typography>
            </div>
        )
    }

    function RetailerGuidelinesContent() {
        return (
            <div id="retailerGuidelines" className={contentSectionClasses}>
                <Typography component="h6" variant="h6" align="center">
                    Retailer Guidelines
                </Typography>
                {/*<Typography component="div" variant="subtitle1" align="center" className={classes.spacer1TopAndBottom}>*/}
                {/*    How retailers use the platform and expected responsibilities.*/}
                {/*</Typography>*/}
                <Typography component="div" variant="body2">
                    <ul>
                        <li>Be clear on what you sell to buyers. This will reduce complications later with issues such as returns, due to selling items to buy requests that are ambiguous.</li>
                        <li>If an item is ambiguous or lacks specifications, please message the buyer. There is a "Message" button on the buy request listing.</li>
                        <li>Retailers pay a <b>4% fee</b> to Try a Buy of the base item price for selling through the online marketplace. It is deducted from the final payout.</li>
                        <li>Retailers pay an additional Stripe payment processor fee of <b>2.9% + 30&cent;</b> on each buy request charge.</li>
                        <li>A payout fee of <b>0.25% + 25&cent;</b> Stripe payment processor fee is charged for bank payouts.</li>
                        <li>Shipping by default is charged to the sellers to help offset fees to retailers. If retailers wish they may cover shipping, by supplying no shipping amount when completing a sale.</li>
                        <li>Tax remittance is the responsibility of the retailer. During the sale completion step, Try a Buy will calculate <i>estimated</i> taxes and will collect those upon charging the customer. They will be a part of the retailer payout. However, the retailer must remit all tax to the respective state entities.</li>
                    </ul>
                </Typography>
            </div>
        )
    }

    function FAQsContent() {
        return (
            <div id="faqs" className={contentSectionClasses}>
                <Typography component="h6" variant="h6" align="center">
                    FAQs
                </Typography>
                <Typography component="div" variant="subtitle1" align="center" className={classes.spacer1TopAndBottom}>
                    Frequently Asked Questions
                </Typography>
                <Typography component="div" variant="body2">
                    <p><i>Is Try a Buy a reverse auction?</i></p>
                    <p>
                        No. First, reverse auctions usually allow bidding on requests in an allotted time period and the request goes to the lowest bid. Try a Buy allows for no such bidding.
                        Secondly any retailer that agrees to sell the item at the stated-requested price may initiate the sale immediately. This avoids an arduous and tiring bidding process.
                    </p>
                    <p><i>Where is Try a Buy based out of?</i></p>
                    <p>
                        Try a Buy is a company in the United States of America, based out of the state of Ohio. Presently we only serve buyers and retailers in the United States.
                    </p>
                </Typography>
            </div>
        )
    }
}
