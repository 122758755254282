import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from "@material-ui/core/Grid";
import LoaderButton from "./LoaderButton";
import {bindActionCreators} from "redux";
import * as signupActions from "../actions/SignupActions";
import * as accountActions from "../actions/AccountActions";
import * as tmpActions from "../actions/TmpActions";
import {connect} from "react-redux";
import {API, Auth} from "aws-amplify";
import {Category, utilStyles} from "../Constants";
import config from '../config';
import {signIn, signOut} from "../services/AccountService";
import TPageHeader from "../widgets/TPageHeader";
import Divider from '@material-ui/core/Divider';
import BusinessIcon from "@material-ui/icons/Business";
import Alert from "@material-ui/lab/Alert";
import TButtonLink from "../widgets/TButtonLink";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    homeLogoImageXs: {
        height: '30px',
        width: 'auto',
        margin: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle'
    },
    homeLogoImageSmAndUp: {
        height: '40px',
        width: 'auto',
        margin: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle'
    },
    ...utilStyles(theme)
}));


function CompanySignupStepper(props) {
    const classes = useStyles();

    const [activeStep, setActiveStep] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
    const [isDoneWithSignup, setIsDoneWithSignup] = useState(false);
    const [doShowNavButton, setDoShowNavButton] = useState(false);
    // const [doShowNavButton, setDoShowNavButton] = useState(true);
    const steps = getSteps();

    const [newRetailer, setNewRetailer] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [emailHelperText, setEmailHelperText] = useState("");
    const [password, setPassword] = useState("");
    const [passwordHelperText, setPasswordHelperText] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [adminButtonDisabled, setAdminButtonDisabled] = useState(false);
    const [confirmationCodeHelperText, setConfirmationCodeHelperText] = useState("");
    const [confirmationCodeButtonDisabled, setConfirmationCodeButtonDisabled] = useState(false);
    const [businessAddressLine1, setBusinessAddressLine1] = useState('');
    const [businessCity, setBusinessCity] = useState('');
    const [businessState, setBusinessState] = useState('');
    const [businessPostalCode, setBusinessPostalCode] = useState('');
    const [categories, setCategories] = useState([]);

    // let nextAction = null;
    const [nextAction, setNextAction] = useState(null);
    
    const queryString = props.location.search;
    const params = new URLSearchParams(queryString);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));


    let goToStep4 = false;
    useEffect(() => {

        goToStep4 = props.goToStep4 && props.goToStep4 === true;
        // this is when returning from Stripe
        if (goToStep4) {
            setDoShowNavButton(true);
            setIsDoneWithSignup(true);
            setActiveStep(6);
            addCompanyInfoAndConfirm();
        }


        document.title = 'Retailer Signup';

        if (activeStep === 1) {
            validateCategories();
        }
        if (activeStep === 4) {
            validateBusinessAddress();
        }
    }, [categories, businessAddressLine1, businessCity, businessState, businessPostalCode]);

    function getSteps() {
        return ['Welcome', 'Categories', 'Admin Signup', 'Confirm Email', 'Business Address', 'Company Information and Payout Account', 'Next Steps'];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return getRetailerWelcomeStep();
            case 1:
                return getBusinessCategoriesContentStep();
            case 2:
                return getCollectAdministratorInfoStep();
            case 3:
                return getConfirmEmailCodeStep();
            case 4:
                return getBusinessAddressContentStep();
            case 5:
                return getRedirectToStripeExplanationStep();
            case 6:
                return getFinishStep();
            default:
                return 'Unknown step';
        }
    }

    function moveToStep1() {
        setActiveStep(1);
        setDoShowNavButton(true);
    }

    function isAdminInfoValid() {
        return(
            firstName.length >= 2 &&
            lastName.length >= 2 &&
            email.length >= 2 &&
            password.length >= 2 &&
            password === confirmPassword
        );
    }

    function isConfirmationCodeValid() {
        return confirmationCode.length > 0;
    }

    const handleAdminSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);

        setEmailHelperText("");
        setPasswordHelperText("");

        try {
            const signupUser = await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    'custom:userType': 'retailer-admin',
                    given_name: firstName,
                    family_name: lastName
                }
            });
            const adminUserId = signupUser.userSub;
            await API.post("tryabuy", '/signup/retailer/add-retailer', {
                body: adminUserId
            }).then(response => {
                // console.log(response);

                let newRetailer = response;
                setNewRetailer(newRetailer);
                setIsNextDisabled(false);
                setShowFeedbackMessage(true);
                setAdminButtonDisabled(true);
            }).catch(error => {
                console.log(error.response);
            });
        } catch (e) {
            if (e.code === "UsernameExistsException") {
                setEmailHelperText(e.message);
            } else if (e.code === "InvalidPasswordException") {
                setPasswordHelperText(e.message);
            } else {
                console.log(e.code);
            }
        }
        setIsLoading(false);
    };

    const handleConfirmationCodeSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        setConfirmationCodeHelperText("");

        try {
            // TODO: DELETE ME ONCE CONFIRMED WORKING WITH NEW WAY BELOW
            // await Auth.confirmSignUp(email, confirmationCode);
            // const cognitoUser = await updateAdminWithRetailerId(newRetailer.retailerId);
            // const adminUser = convertCognitoUserToAppUser(cognitoUser);

            // NEW WAY
            await Auth.confirmSignUp(email, confirmationCode);
            await updateAdminWithRetailerId(newRetailer.retailerId);
            const adminUser = await signIn(email, password, {
                isRegistrationInProcess: true,
                actions: props.actions
            });

            await API.post("tryabuy", '/signup/retailer/add-admin', {
                body: adminUser
            }).then(response => {
                // console.log(response);

                setConfirmationCodeButtonDisabled(true);
                setShowFeedbackMessage(true);
                setIsNextDisabled(false);
            }).catch(error => {
                console.log(error.response);
            });
        } catch (e) {
            if (e.code === "CodeMismatchException") {
                setConfirmationCodeHelperText(e.message);
            } else {
                console.log(e);
            }
        }
        setIsLoading(false);
    };

    async function updateAdminWithRetailerId(retailerId) {
        const cognitoUser = await Auth.signIn(email, password);
        let updateStatus = await Auth.updateUserAttributes(cognitoUser, {
            'custom:retailerId': retailerId
        });
        await signOut({actions: props.actions});

        // note: need to manually add-back retailer ID. It is not done through Amplify unless signing
        //      back into the platform, then here, needing to sign-out yet again. Too performance
        //      intensive. Therefore, just adding it manually here
        cognitoUser.attributes['custom:retailerId'] = retailerId;

        return cognitoUser;
    }


    const validateCategories = () => {
        if (categories.length >= 1) {
            setIsNextDisabled(false);
        } else {
            setIsNextDisabled(true);
        }
    };

    const validateBusinessAddress = () => {
        if (
            businessAddressLine1.length > 0 &&
            businessCity.length > 0 &&
            businessState.length > 0 &&
            businessPostalCode.length > 0
        ) {
            setIsNextDisabled(false);
            // Node/Javascript note: I need to embed the reference to a function sub-property "action" to
            //      later invoke. Making the function as a direct property did not work. React invokes
            //      it immediately upon setting. Therefore it needs to be embedded for later use.
            setNextAction({action: handleSubmitBusinessAddress});
        } else {
            setIsNextDisabled(true);
            setNextAction(null);
        }
    };

    const handleSubmitBusinessAddress = async () => {
        let categoryKeys = [];
        categories.forEach(value => {
            categoryKeys.push(value.key);
        });
        try {
            const body = {
                retailerId: newRetailer.retailerId,
                businessAddress: {
                    addressLine1: businessAddressLine1,
                    city: businessCity,
                    state: businessState,
                    postalCode: businessPostalCode
                },
                categories: categoryKeys
            };

            await API.post("tryabuy", '/signup/retailer/add-business-address', {
                body: body
            }).then(response => {
                // console.log(response);
                setNextAction(null);
            }).catch(error => {
                console.log(error.response);
                setNextAction(null);
            });
        } catch (e) {
            console.log(e);
        }
    };

    function redirectToStripe() {
        issueOauthAuthorizeRedirect();
    }

    function issueOauthAuthorizeRedirect() {
        const returnRedirect = encodeURI(`${config.publicDomain}/signup/retailer/complete?s=4`);
        const state = JSON.stringify({
            r: newRetailer.retailerId
        });
        const querystring =
            `client_id=${config.stripe.clientId}&` +
            'scope=read_write&' +
            `redirect_uri=${returnRedirect}&` +
            `state=${state}&` +
            // 'stripe_user[business_type]=individual&' +
            'stripe_user[business_type]=company&' +
            `stripe_user[first_name]=${firstName}&` +
            `stripe_user[last_name]=${lastName}&` +
            `stripe_user[email]=${email}&` +
            'stripe_user[country]=US&' +

            // NOT WORKING, UNFORTUNATELY.
            //    Only for standard: https://stripe.com/docs/connect/oauth-reference#get-authorize
            // `stripe_user[street_address]=${businessAddressLine1}&` +
            // `stripe_user[city]=${businessCity}&` +
            // `stripe_user[state]=${businessState}&` +
            // `stripe_user[zip]=${businessPostalCode}&` +

            'suggested_capabilities[]=card_payments';
        // console.log('Querystring: ', querystring);

        const stripeRedirect = config.stripe.authorizeUri + '?' + querystring;
        // console.log("stripeRedirect = " + stripeRedirect);

        window.location.replace(stripeRedirect);
    }

    const addCompanyInfoAndConfirm = async event => {

        const stateStr = params.get("state");
        const state = JSON.parse(stateStr);

        const body = {
            authCode: params.get("code"),
            retailerId: state.r
        };
        await API.post("tryabuy", '/signup/retailer/add-company-info-and-confirm', {
            body: body
        }).then(response => {
            // console.log(response);

            setIsNextDisabled(false);
            setShowFeedbackMessage(true);
        }).catch(error => {
            console.log(error.response);
        });
    };

    function getRetailerWelcomeStep() {
        return (
            <div>
                <Typography component="div" variant="overline" align="center">
                    We look forward to having you join our retailer network at
                </Typography>
                <Typography component="div" align="center" className={classes.spacer1TopAndBottom}>
                    <img src="/logo/horizontal-transparent.png"
                         className={isXs ? classes.homeLogoImageXs : classes.homeLogoImageSmAndUp}
                         alt="Try a Buy Home" />
                </Typography>
                <Typography component="div" variant="caption" align="center">
                    Try a Buy presents a great opportunity to allow retailers opportunities to sell inventory to buyers at a reduced price,
                    on a one-off basis.
                    You do not need to discount items for an extended time.
                    This allows you to sell at one-time discounted prices without liquidating more of your inventory at lower prices than you want to commit to.
                </Typography>
                <div className={classes.spacer1Top}></div>
                <Typography component="div" variant="caption" align="center">
                    Doing this allows you more control over selling items at discounted prices and how low you are willing to go.
                </Typography>
                <div className={classes.spacer1Top}></div>
                <Typography component="div" variant="body1" align="center">
                    Before we get started you must understand the following.
                </Typography>
                <div className={classes.spacer1Top}></div>
                <Typography variant="caption" display="block">
                    <ol>
                        <li>The company you are enlisting is a legally, state-registered entity such as an LLC, sole proprietorship, non-profit. You are <i>not</i> an individual seller, selling used goods.</li>
                        <li>You will be required to enter supporting business information such as formal business address, IRS Employment Identification Number (EIN,) payout bank account information through our secure payment processor, Stripe&copy;.</li>
                        <li>Your company must reside in the United States.</li>
                        <li>You represent a retail company and will act as an authority and administrator on its behalf.</li>
                        <li>Retailers pay a 4% fee of the base item price for using this online marketplace.</li>
                        <li>Retailers pay an additional Stripe payment processor fee of 2.9% + 30&cent; on each buy request charge and a 0.25% + 25&cent; fee on bank payouts.</li>
                        <li>
                            Try a Buy will collect the estimated sales tax for you.
                            <br />
                            You will be responsible for filing and remitting sales tax.
                        </li>
                        <li>Your submission will be quickly reviewed by Try a Buy.</li>
                    </ol>
                </Typography>
                <Typography component="div" variant="body1" align="center">
                    We look forward to having you with us!
                </Typography>
                <div className={classes.spacer1Top}></div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={ e => moveToStep1()}
                    className={classes.spacer1Top}
                    align="center"
                    fullWidth>
                    Get Started
                </Button>
                <div className={classes.spacer2Top}></div>
                <Button onClick={ e => window.open('https://calendly.com/tryabuy/30min')}
                             color="primary"
                             variant='outlined'
                             size="small"
                             props={props}
                             fullWidth>
                    Schedule a Call To Learn More
                </Button>
            </div>
        );
    }

    function getCollectAdministratorInfoStep() {
        return (
            <div>
                <Typography variant="subtitle1" component="div" align="center" className={classes.spacer1Top}>
                    Because you are registering your retail company, <i>you</i> will act as the administrator.
                </Typography>
                    <form className={classes.form} onSubmit={handleAdminSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    name="firstName"
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                    autoComplete="firstName"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                    autoComplete="lastName"
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            autoComplete="email"
                            error={emailHelperText !== ""}
                            helperText={emailHelperText}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    error={passwordHelperText !== ""}
                                    helperText={passwordHelperText}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    type="password"
                                    id="confirmPassword"
                                    autoComplete="current-confirmPassword"
                                />
                            </Grid>
                        </Grid>
                        <Typography component="div" variant="caption" align="center" className={classes.spacer1TopAndBottom}>
                            By signing up, you agree to the
                            &nbsp;
                            <a href="/terms-of-service" target="_blank">
                                Terms of Service
                            </a>
                            &nbsp;and&nbsp;
                            <a href="/privacy-policy" target="_blank">
                                Privacy Policy
                            </a>
                            .
                        </Typography>
                        {!showFeedbackMessage &&
                        <LoaderButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            isLoading={isLoading}
                            disabled={!isAdminInfoValid() || adminButtonDisabled}
                            text="Signup"
                            fullWidth
                            loadingText="Registering admin..." />
                        }
                        {showFeedbackMessage &&
                            <Alert variant="outlined" severity="success">
                                Admin user created.
                            </Alert>
                        }
                </form>
            </div>
        );
    }

    function getConfirmEmailCodeStep() {
        return (
            <div>
                <Typography variant="subtitle1" component="div" align="center" className={classes.spacer1Top}>
                    Please enter the code sent to you in your email.
                </Typography>
                <form onSubmit={handleConfirmationCodeSubmit}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Confirmation Code"
                        name="confirmationCode"
                        value={confirmationCode}
                        onChange={e => setConfirmationCode(e.target.value)}
                        id="confirmationCode"
                        error={confirmationCodeHelperText !== ""}
                        helperText={confirmationCodeHelperText}
                    />
                    {!showFeedbackMessage &&
                    <LoaderButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        isLoading={isLoading}
                        disabled={!isConfirmationCodeValid() || confirmationCodeButtonDisabled}
                        text="Confirm"
                        fullWidth
                        loadingText="Confirming..." />
                    }
                    {showFeedbackMessage &&
                        <Alert variant="outlined" severity="success">
                            Confirmed.
                        </Alert>
                    }
                </form>
            </div>
        );
    }

    function getBusinessCategoriesContentStep() {
        return (
            <div>
                <Typography variant="subtitle1" component="div" align="center" className={classes.spacer1Top}>
                    What categories of goods do you sell?
                </Typography>
                <Autocomplete
                    multiple
                    fullwidth="true"
                    options={Category.values()}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                        setCategories(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="standard"
                          label="Categories"
                          placeholder="Categories"
                      />
                  )}
                />
            </div>
        );
    }

    function getBusinessAddressContentStep() {
        return (
            <div>
                <Typography variant="subtitle1" component="div" align="center" className={classes.spacer1Top}>
                    Please enter the business address.
                </Typography>
                <Typography variant="subtitle2" component="div" align="center" className={classes.spacer1Top}>
                    This will be used in calculating sales tax.
                </Typography>
                <TextField
                    margin="dense"
                    required
                    fullWidth
                    id="businessAddressLine1"
                    label="Address Line 1"
                    name="businessAddressLine1"
                    value={businessAddressLine1}
                    onChange={e => setBusinessAddressLine1(e.target.value) }
                    autoComplete="businessAddressLine1"
                />
                <TextField
                    margin="dense"
                    required
                    fullWidth
                    id="businessCity"
                    label="City"
                    name="businessCity"
                    value={businessCity}
                    onChange={e => setBusinessCity(e.target.value) }
                    autoComplete="businessCity"
                />
                <TextField
                    margin="dense"
                    required
                    fullWidth
                    label="State"
                    name="businessState"
                    value={businessState}
                    onChange={e => setBusinessState(e.target.value) }
                    id="businessState"
                    autoComplete="businessState"
                />
                <TextField
                    margin="dense"
                    required
                    fullWidth
                    label="Postal Code"
                    name="businessPostalCode"
                    value={businessPostalCode}
                    onChange={e => setBusinessPostalCode(e.target.value) }
                    id="businessPostalCode"
                    autoComplete="businessPostalCode"
                />
            </div>
        );
    }

    function getRedirectToStripeExplanationStep() {
        return (
            <div>
                <Typography variant="subtitle1" component="div" align="center" className={classes.spacer1TopAndBottom}>
                    Try a Buy is
                </Typography>
                <div align={'center'}>
                         <img srcSet=" /stripe/badge/Solid%20Dark/powered_by_stripe@3x.png 3x,
                                  /stripe/badge/Solid%20Dark/powered_by_stripe@2x.png 2x,
                                  /stripe/badge/Solid%20Dark/powered_by_stripe.png 1x "
                         src="/stripe/badge/Solid%20Dark/powered_by_stripe.png"
                         alt="" />
                </div>
                <div className={classes.spacer1Top}></div>
                <Typography
                    variant="caption"
                    align="center"
                    display="block">
                    Stripe&trade; is our secure payment processing partner. As a retailer, you will manage your payments, returns and payment monitoring through Stripe's secure dashboard.
                    <br /><br />
                    Stripe collects basic business information required for secure and compliant payment processing.
                    It is required to enter this information to sell items on Try a Buy.
                    <br /><br />
                    <b>Please click the button below to continue registration on Stripe.</b>
                    <br />
                    You will return here after completed.
                </Typography>
                <div className={classes.spacer1Top}></div>
                <div align="center">
                    <a style={{cursor: 'pointer'}} onClick={redirectToStripe}>
                    <img srcSet=" /stripe/connect-button/png-blue/blue-on-light@3x.png 3x,
                                  /stripe/connect-button/png-blue/blue-on-light@2x.png 2x,
                                  /stripe/connect-button/png-blue/blue-on-light.png 1x "
                         src="/stripe/connect-button/png-blue/blue-on-light.png"
                        alt="" />
                    </a>
                </div>
            </div>
        );
    }

    function getFinishStep() {
        return (
            <div>
                <Alert severity="success" className={classes.spacer1Top}>
                    Great! Your request is in.
                </Alert>
                <Typography component="div" variant="subtitle1" align="center" className={classes.spacer1Top}>
                    Please sit-tight while Try a Buy reviews your company request.
                </Typography>
                <Typography component="div" variant="subtitle1" align="center" className={classes.spacer1TopAndBottom}>
                    You should hear back in 1-2 business days.
                </Typography>
                <TButtonLink text="Browse Buy Requests"
                             to='/browse'
                             color="primary"
                             variant='outlined'
                             props={props}
                             fullWidth />
            </div>
        );
    }

    const handleNext = () => {

        if (nextAction != null) {
            (async () => {
                // Node/Javascript note: I need to dereference the function sub-property "action" to
                //      invoke. Making the function as a direct property did not work - React invokes
                //      it immediately upon setting. Therefore it needs to be embedded.
                await nextAction.action();
                setNextAction(null);
            })();
        }

        // const nextStep = prevActiveStep + 1;
        // setActiveStep(prevActiveStep => prevActiveStep + 1);

        setActiveStep(prevActiveStep => prevActiveStep + 1);

        setIsNextDisabled(true);
        setShowFeedbackMessage(false);

        if ((activeStep + 1) === 5) { // redirect to Stripe step
            setDoShowNavButton(false);
        } else {
            setDoShowNavButton(true);
        }

        // TODO: This still needed?
        // minus 2 because hook doesn't take affect until after function call
        // if (activeStep == steps.length - 2) {
        //     setIsDoneWithSignup(true);
        // }
    };

    // const handleBack = () => {
    //     setActiveStep(prevActiveStep => prevActiveStep - 1);
    //
    //     setIsNextDisabled(false);
    // };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div>
            <TPageHeader icon={BusinessIcon}
                     title='Retail Company Registration'
                         subtitle="Let's get your company signed up."/>
            <Button onClick={ e => window.open('https://calendly.com/tryabuy/30min')}
                    color="primary"
                    variant='outlined'
                    size="small"
                    props={props}
                    fullWidth>
                Schedule a Call To Learn More
            </Button>
            <Stepper activeStep={activeStep} orientation="vertical" className={classes.spacer2Top}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <Typography component={'span'}>{getStepContent(index)}</Typography>
                            <div className={classes.actionsContainer}>
                                { doShowNavButton &&
                                <div>
                                    {!isDoneWithSignup &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={isNextDisabled}
                                            onClick={handleNext}
                                            className={[classes.spacer1Top, classes.pullRight].join(" ")}
                                            align="center"
                                        >
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    }
                                    {isDoneWithSignup &&
                                        <TButtonLink text="Checkout Listings"
                                                 to='/browse'
                                                 color="primary"
                                                 variant='outlined'
                                                 props={props}
                                                 fullWidth />
                                    }
                                </div>
                                    }
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.spacer1Top}>
                        Reset
                    </Button>
                </Paper>
            )}
        </div>
    );
}


const mapStateToProps = state => {
    return {
        signup: state.signup,
        retailer: state.signup.retailer,
        newRetailerId: state.tmp.newRetailerId
    };
};

const mapDispatchToProps = (dispatch) => {
    const actions = { ...signupActions, ...accountActions, ...tmpActions };
    return {
        actions: bindActionCreators(actions,  dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySignupStepper);

