import React from "react";
import Typography from '@material-ui/core/Typography';


export default ({ component: C, text: text, ...rest }) => {
    return (
        <div>
            <C  {...rest} />
            <Typography variant="caption" component="div">
                {text}
            </Typography>
        </div>
    );
}
