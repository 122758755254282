import React, {useEffect, useState} from "react";
import {makeStyles, Paper} from "@material-ui/core";
import {blue, green, orange, red} from '@material-ui/core/colors';
import {connect} from "react-redux";
import {UserStatus, utilStyles} from '../Constants';
import {API} from "aws-amplify";
import {getUser} from "../services/AccountService";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import AddressForm from "../components/AddressForm";
import {generateUserFullName} from "../Utils";
import PersonOutlineSharpIcon from '@material-ui/icons/PersonOutlineSharp';
import EmailSharpIcon from '@material-ui/icons/EmailSharp';
import Alert from '@material-ui/lab/Alert';
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from '@material-ui/core/Snackbar';
import TLink from "../widgets/TLink";
import TPageHeader from "../widgets/TPageHeader";
import AccountCircle from "@material-ui/icons/AccountCircle";
import TProgress from "../widgets/TProgress";
import TButtonLink from "../widgets/TButtonLink";


const useStyles = makeStyles(theme => ({
    orange: {
        // color: theme.palette.getContrastText(orange[500]),
        color: '#fff',
        backgroundColor: orange[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    blue: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
    },
    red: {
        color: '#fff',
        backgroundColor: red[500],
    },
    ...utilStyles(theme)
}));

function AccountPage(props) {

    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [response, setResponse] = useState();
    const [user, setUser] = useState();
    const [isAddressValid, setIsAddressValid] = useState(true);
    const [shippingAddress, setShippingAddress] = useState();
    const [repUsers, setRepUsers] = useState();
    const [openMessage, setOpenMessage] = useState(false);
    const HtmlTooltip = withStyles(theme => ({
        tooltip: classes.tooltip
    }))(Tooltip);
    const closeMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMessage(false);
    };

    useEffect(() => {
        (() => {
            const user = getUser();
            setUser(user);
            const url = `/user/${user.userId}/account/${user.userType}`;
            API.get("tryabuy", url).then(response => {
                // console.log(JSON.stringify(response));

                // TODO: remove below for testing
                // response.stripeInfo.errorMessage = "You need to verify your company account in Stripe in order to make sale transactions.";
                // response.stripeInfo.errorMessage2 = "Please log into Stripe to complete the verification process.";

                if (response.repUsers) {
                    setRepUsers(response.repUsers);
                }
                setResponse(response);
                setIsLoaded(true);
                document.title = 'Account';
            }).catch(error => {
                console.error("**** error: " + error.response);
            });
        })();
    }, []);

    function addressValuesCallback(address) {
        // console.log("***** addressValuesCallback: " + JSON.stringify(address));
        setIsAddressValid(address.line1.length > 0 &&
                            address.city.length > 0 &&
                            address.state.length > 0 &&
                            address.postalCode.length > 0);
        setShippingAddress(address);
    }

    function saveBuyerAccountInfo() {
        setIsLoaded(false)
        const url = `/user/${user.userId}/account/${user.userType}`;

        let body = {
            userId: user.userId,
            shippingAddress: shippingAddress
        };
        API.post("tryabuy", url, {
            body: body
        }).then(response => {
            // console.log(response);
            setResponse(response);
            setIsLoaded(true);
            setOpenMessage(true);
        }).catch(error => {
            console.error(error);
            setIsLoaded(true);
        });
    }

    function updateUserStatus(userId, status) {
        const url = `/user/${userId}/status/${status}`;

        API.post("tryabuy", url).then(updatedUser => {
            const newReps = [...repUsers];
            repUsers.forEach(function(repUser, idx) {
                if (repUser.userId === userId) {
                    newReps[idx] = updatedUser;
                }
            });
            setRepUsers(newReps)
        }).catch(error => {
            console.error(error);
        });
    }

    function renderProgress() {
        return (
            <div>
                <div className={classes.spacer4}></div>
                <Typography component="h5" variant="h5" align="center">
                    Loading...
                </Typography>
                <div className={classes.spacer3}></div>
                <LinearProgress />
            </div>
        );
    }

    function renderContent() {
        let content;
        switch (user.userType) {
            case 'buyer':
                content = renderBuyerContent();
                break;
            case 'retailer-admin':
                content = renderRetailerAdminContent();
                break;
            case 'retailer-rep':
                content = renderRetailerRepContent();
                break;
            default: content = 'NO CONTENT';
        }

        return (
            <div>
                <Paper elevation={3} className={classes.spacer2Top}>
                    <Grid container justifyContent="flex-start">
                        <Grid item sm={6} xs={12}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <PersonOutlineSharpIcon fontSize={'small'} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={generateUserFullName(user)} secondary="Name" />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid container item sm={6} xs={12}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <EmailSharpIcon fontSize={'small'} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={user.email} secondary="Email" />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
                <div className={classes.spacer3TopAndBottom}></div>
                {content}
                <div className={classes.spacer3TopAndBottom}></div>
                {renderFooterContent()}
                <div className={classes.spacer5Bottom}></div>
            </div>
        );
    }

    function renderBuyerContent() {
        return (
            <div>
                <Typography variant="subtitle1" component="div" align="center">
                    Shipping Address
                </Typography>
                {response.shippingAddress ?
                    <div>
                        <AddressForm address={response.shippingAddress} valuesCallback={addressValuesCallback} />
                        <div className={classes.spacer2Top}></div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveBuyerAccountInfo}
                            className={classes.button}
                            disabled={!isAddressValid}
                            fullWidth>
                            Save
                        </Button>
                    </div>
                    :
                    <Typography variant="body2" component="div" align="center">
                        You will be prompted to enter your shipping address when you create your first buy request.
                    </Typography>
                }
            </div>
        );
    }

    function renderRetailerRepContent() {
        return (
            <div>
                <Typography variant="body1" component="div" align="center">
                    Your account status is&nbsp;
                    <span className={classes.bold}>{UserStatus.toLabel(response.status)}</span>
                </Typography>
                <div className={classes.spacer2TopAndBottom}></div>
                { response.status === UserStatus.ACTIVE.key &&
                <div>
                    <Typography variant="body2" component="div" align="center">
                        You are able to sell items to buyers on Try a Buy.
                    </Typography>
                </div>
                }
                { (response.status === UserStatus.PENDING.key ||
                    response.status === UserStatus.SUSPENDED.key) &&
                <div>
                    <Typography variant="body2" component="div" align="center">
                        Your account is pending activation from your company's administrator.
                        <br />
                        After activated, you may sell items to buyers on Try a Buy.
                    </Typography>
                </div>
                }
            </div>
        );
    }

    function renderRetailerAdminContent() {
        return (
            <div>
                <div className={classes.spacer4Top}></div>
                {repUsers.length >= 1 &&
                <div>
                    <Typography variant="h6" component="h6" align="center">
                        Your sales representatives
                    </Typography>
                    {
                        repUsers.map(function (repUser) {
                            const keyVal = repUser.userId;
                            return (
                                <div key={keyVal}>
                                    {renderRepUser(repUser)}
                                </div>
                            )
                        })
                    }
                </div>
                }
                {repUsers.length ==0 &&
                    <Typography variant="body1" component="div" align="center">
                        No registered sales representatives as of yet.
                    </Typography>
                }
                <div className={classes.spacer2TopAndBottom}></div>
                <Typography variant="h6" component="h6" align="center">
                    Stripe&trade; Payments
                </Typography>
                <Typography variant="body1" component="div" align="center">
                    Log into Stripe to manage payments and payouts from Try a Buy
                </Typography>
                <div className={classes.spacer2TopAndBottom}></div>
                { response.stripeInfo.errorMessage &&
                    <div>
                        <Alert severity="error">
                            {response.stripeInfo.errorMessage}
                            { response.stripeInfo.errorMessage2 &&
                                <div className={classes.bold} align={'center'}>
                                    {response.stripeInfo.errorMessage2}
                                </div>
                            }
                        </Alert>
                        <div className={classes.spacer2TopAndBottom}></div>
                    </div>
                }

                <Button variant="outlined"
                        color="secondary"
                        fullWidth
                        href={response.stripeInfo.link}
                        target="_blank">
                    Stripe Dashboard
                </Button>
                <div className={classes.spacer2TopAndBottom}></div>
                <div align={'center'}>
                    <img srcSet=" /stripe/badge/Solid%20Dark/powered_by_stripe@3x.png 3x,
                                  /stripe/badge/Solid%20Dark/powered_by_stripe@2x.png 2x,
                                  /stripe/badge/Solid%20Dark/powered_by_stripe.png 1x "
                         src="/stripe/badge/Solid%20Dark/powered_by_stripe.png"
                        alt="" />
                </div>
                <div className={classes.spacer3Bottom}></div>
            </div>
        );
    }


    function renderRepUser(repUser) {
        const statusInfo = getStatusInformation(repUser);
        return (
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={statusInfo.iconClass}>
                            {statusInfo.icon}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography variant="body1" component="div">
                                {generateUserFullName(repUser)}
                            </Typography>
                        }
                        secondary={
                            <span>
                                <span className={statusInfo.statusClass}>{UserStatus.toLabel(repUser.status)}</span>
                            </span>
                        }
                    />
                    {repUser.status === UserStatus.ACTIVE.key &&
                        <ListItemSecondaryAction>
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        Suspend a representative if you need to prevent him/her from making sales on Try a Buy.
                                    </React.Fragment>
                                }
                                placement="bottom">
                                <Button variant="outlined"
                                        size="small"
                                        color="secondary"
                                        edge="end"
                                        onClick={() => {
                                            updateUserStatus(repUser.userId, UserStatus.SUSPENDED.key)
                                        }}>
                                    Suspend
                                </Button>
                            </HtmlTooltip>
                        </ListItemSecondaryAction>
                    }
                    {(repUser.status === UserStatus.PENDING.key ||
                        repUser.status === UserStatus.SUSPENDED.key) &&
                    <ListItemSecondaryAction>
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    Activate a representative to allow him/her to make sales on Try a Buy.
                                </React.Fragment>
                            }
                            placement="bottom">
                            <Button variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        updateUserStatus(repUser.userId, UserStatus.ACTIVE.key)
                                    }}>
                                Activate
                            </Button>
                        </HtmlTooltip>
                    </ListItemSecondaryAction>
                    }
                </ListItem>
                <Divider variant="inset" component="li" />
            </List>
        );
    }

    function getStatusInformation(buyRequest) {
        let retval = {};
        switch (buyRequest.status) {
            case UserStatus.ACTIVE.key:
                retval.icon = <FlashOnIcon />;
                retval.iconClass = classes.green;
                retval.statusClass = [classes.greenMessage].join(' ');
                break;
            case UserStatus.PENDING.key:
                retval.icon = <AutorenewIcon />;
                retval.iconClass = classes.orange;
                retval.statusClass = [classes.orangeMessage].join(' ');
                break;
            case UserStatus.SUSPENDED.key:
                retval.icon = <CloseIcon />;
                retval.iconClass = classes.red;
                retval.statusClass = [classes.redMessage].join(' ');
                break;

            // TODO: Add image avatar if image is supplied
            default:
                return 'Unknown status';
        }
        return retval;
    }

    function renderFooterContent() {
        return (
            <div>
                <Grid container justifyContent="flex-start">
                    <Grid item xs={6}>
                        <TLink props={props} to="/account/password/change" text="Change password" variant="body2">
                        </TLink>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-start" direction="column" alignItems="flex-end">
                        <TLink props={props} to="/contact-us" text="Need help with anything else?" variant="body2">
                        </TLink>
                    </Grid>
                </Grid>
                <div className={classes.spacer2Top}></div>
                <Grid container justifyContent="flex-start">
                    <Grid item xs={6}>

                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-start" direction="column" alignItems="flex-end">
                        <TButtonLink text="Subscriptions"
                                     to='/account/subscription'
                                     color="secondary"
                                     variant='text'
                                     props={props} />
                    </Grid>
                </Grid>
                <Snackbar open={openMessage} autoHideDuration={3000} onClose={closeMessage}>
                    <Alert onClose={closeMessage} severity="success">
                        Account information saved.
                    </Alert>
                </Snackbar>
            </div>
        );
    }

    return (
        <Container maxWidth="sm">
            <TPageHeader icon={AccountCircle} title="Account Information" />
            { isLoaded
                ? renderContent()
                : <TProgress />
            }
        </Container>
    );
}


const mapStateToProps = state => {
    return {
        retailer: state.account.retailer
    };
};

export default connect(mapStateToProps, null)(AccountPage);
