import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import {utilStyles} from "../Constants";
import CreditCardElement from "./CreditCardElement";
import {Elements, StripeProvider} from "react-stripe-elements";
import config from "../config";
import {getUser} from "../services/AccountService";
import {API} from "aws-amplify";
import TPageHeader from "../widgets/TPageHeader";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Paper from "@material-ui/core/Paper";
import Divider from '@material-ui/core/Divider';
import LoaderButton from "./LoaderButton";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
    containerPaper: {
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    ...utilStyles(theme)
}));

export default function CollectShippingAndPaymentInfoForm(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [shippingAddressLine1, setShippingAddressLine1] = useState('');
    const [shippingAddressLine2, setShippingAddressLine2] = useState('');
    const [shippingCity, setShippingCity] = useState('');
    const [shippingState, setShippingState] = useState('');
    const [shippingPostalCode, setShippingPostalCode] = useState('');
    const [paymentToken, setPaymentToken] = useState('');
    const [isPaymentValid, setIsPaymentValid] = useState(false);


    useEffect(() => {
        return () => {
            setIsLoading(false);
        };
    }, []);


    const updatePaymentInfo = (isPaymentValid, paymentToken) => {
        setIsPaymentValid(isPaymentValid);
        setPaymentToken(paymentToken);
    };

    const shippingAddressValidator = () => {
        return(
            isPaymentValid &&
            shippingAddressLine1.length > 0 &&
            shippingCity.length > 0 &&
            shippingState.length > 0 &&
            shippingPostalCode.length > 0
        );
    };

    const submitPaymentInformationAction = async event => {
        event.preventDefault();
        setIsLoading(true);
        const user = getUser();

        const paymentInformationRequest = {
            shippingAddress: {
                line1: shippingAddressLine1,
                line2: shippingAddressLine2,
                city: shippingCity,
                state: shippingState,
                postalCode: shippingPostalCode
            },
            paymentToken: paymentToken,
            user: {
                userId: user.userId,
                email: user.email
            }
        };

        await API.post("tryabuy", `/user/${user.userId}/payment-info`, {
            body: paymentInformationRequest
        }).then(response => {
            // console.log(response);
            props.callback(true);
            setIsLoading(false);
        }).catch(error => {
            console.log(error.response);
            setIsLoading(false);
        });
    };


    return (
        <div>
            <TPageHeader icon={CreditCardIcon} title='Shipping and Billing Information' align="center" />
            <Paper elevation={3} className={[classes.containerPaper, classes.spacer2TopAndBottom].join(' ')}>
                <div>
                    <div>
                        <Typography variant="subtitle1" component="div">
                            Why am I being asked for this now?
                        </Typography>
                        <Divider />
                        <Typography variant="caption" component="div" align="center">
                            When a retailer agrees to sell you an item at your requested price, your shipping and credit card
                            information need to be present for when the sale is initiated.
                            Once the sale is shipped and marked completed by the retailer, your card will be charged.
                        </Typography>
                    </div>
                    <div className={classes.spacer3Top} >
                        <Typography variant="body2" component="div" align="center">
                            Please enter your billing information.
                        </Typography>
                        <StripeProvider apiKey={config.stripe.publishableKey}>
                            <Elements>
                                <CreditCardElement tokenCallback={updatePaymentInfo} />
                            </Elements>
                        </StripeProvider>
                    </div>
                    <Divider className={classes.spacer2TopAndBottom} />
                    <Typography variant="body2" component="div" align="center">
                        Please enter your <b>shipping</b> address.
                    </Typography>
                    <form noValidate onSubmit={submitPaymentInformationAction}>
                        <TextField
                            margin="dense"
                            required
                            fullWidth
                            id="shippingAddressLine1"
                            label="Address Line 1"
                            name="shippingAddressLine1"
                            value={shippingAddressLine1}
                            onChange={e => setShippingAddressLine1(e.target.value) }
                            autoComplete="shippingAddressLine1"
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="addressLine2"
                            label="Address Line 2"
                            name="shippingAddressLine2"
                            value={shippingAddressLine2}
                            onChange={e => setShippingAddressLine2(e.target.value) }
                            autoComplete="addressLine2"
                        />
                        <TextField
                            margin="dense"
                            required
                            fullWidth
                            id="shippingCity"
                            label="City"
                            name="shippingCity"
                            value={shippingCity}
                            onChange={e => setShippingCity(e.target.value) }
                            autoComplete="shippingCity"
                        />
                        <TextField
                            margin="dense"
                            required
                            fullWidth
                            label="State"
                            name="shippingState"
                            value={shippingState}
                            onChange={e => setShippingState(e.target.value) }
                            id="shippingState"
                            autoComplete="state"
                        />
                        <TextField
                            margin="dense"
                            required
                            fullWidth
                            label="Postal Code"
                            name="shippingPostalCode"
                            value={shippingPostalCode}
                            onChange={e => setShippingPostalCode(e.target.value) }
                            id="shippingPostalCode"
                            autoComplete="shippingPostalCode"
                        />
                        <div className={classes.spacer1Top}></div>
                        <LoaderButton
                            color="primary"
                            type="submit"
                            variant="outlined"
                            isLoading={isLoading}
                            disabled={!shippingAddressValidator()}
                            text="Next"
                            loadingText="Saving..."
                            endIcon={<NavigateNextIcon />}
                            fullWidth
                        />
                    </form>
                </div>
            </Paper>
        </div>
    );
}
