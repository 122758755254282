import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {utilStyles} from '../Constants';
import BuyRequestCard from "../components/BuyRequestCard";
import {API} from "aws-amplify";
import {getUser, isBuyer, isRetailer} from "../services/AccountService";
import TProgress from "../widgets/TProgress";

const useStyles = makeStyles(theme => ({

    ...utilStyles(theme)
}));

function BuyRequestsPage(props) {

    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [buyRequests, setBuyRequests] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        loadBuyRequests();
    }, []);

    async function loadBuyRequests() {
        const user = getUser();
        setUser(user);
        let url = '/buy-requests?';
        if (isBuyer(user)) {
            url += `userId=${user.userId}`;
        } else if (isRetailer(user)) {
            url += `retailerId=${user.retailerId}`;
        }

        API.get("tryabuy", url).then(response => {
            setBuyRequests(response);
            document.title = 'Buy Requests';
            setIsLoaded(true);
        }).catch(error => {
            console.log("**** error: " + error.response);
        });
    }

    function getSkeletons() {
        let retval = [];
        let i = 0;
        for (; i < 5; i++) {
            retval.push(<BuyRequestCard key={i} />);
        }
        return retval;
    }

    return (
        <div className={classes.spacer3Top}>
        { isLoaded
        ?
            buyRequests.map(function(buyRequest) {
                const keyVal = buyRequest.buyRequestId;
                return (
                    <div key={keyVal}>
                        <BuyRequestCard buyRequest={buyRequest} isLoaded={isLoaded} user={user} {...props} />
                    </div>
                )
            })
        :
            <div>
                <div className={classes.spacer3Top}></div>
                <TProgress />
                <div className={classes.spacer3Top}></div>
                {getSkeletons()}
            </div>
        }
        </div>
    );
}



const mapStateToProps = state => {
    return {
        retailer: state.account.retailer
    };
};

export default connect(mapStateToProps, null)(BuyRequestsPage);
