import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import * as signupActions from "../actions/SignupActions";
import {connect} from "react-redux";
import {utilStyles} from '../Constants';
import CompanySignupStepper from "../components/CompanySignupStepper";


const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

function SignupRetailer(props) {
    const classes = useStyles();

    useEffect(() => {
        document.title = 'Retailer Signup Direct';
    }, []);

    return (
        <Container maxWidth="sm">
            <CompanySignupStepper {...props} />
        </Container>
    );
}

const mapStateToProps = state => {

    return {
        retailer: state.signup.retailer,
        isRetailerSet:  state.signup.isRetailerSet,
        showStepper: state.signup.showStepper
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(signupActions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupRetailer);

