import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";

export default function AddressForm({address, valuesCallback}) {
    const [line1, setLine1] = useState(address.line1);
    const [line2, setLine2] = useState(address.line2);
    const [city, setCity] = useState(address.city);
    const [state, setState] = useState(address.state);
    const [postalCode, setPostalCode] = useState(address.postalCode);

    // const [line1, setLine1] = useState("");
    // const [line2, setLine2] = useState("");
    // const [city, setCity] = useState("");
    // const [state, setState] = useState("");
    // const [postalCode, setPostalCode] = useState("");

    useEffect(() => {

        // if (address) { // could be blank if not yet supplied on new buy request
        //     setLine1(address.line1);
        //     setLine2(address.line2);
        //     setCity(address.city);
        //     setState(address.state);
        //     setPostalCode(address.postalCode);
        // }

        valuesCallback({
            line1: line1,
            line2: line2,
            city: city,
            state: state,
            postalCode: postalCode
        });
    // });
    }, [line1, line2, city, state, postalCode]);

   return (
       <React.Fragment>
            <TextField
                margin="dense"
                required
                fullWidth
                id="line1"
                label="Address Line 1"
                name="line1"
                value={line1}
                onChange={e => setLine1(e.target.value) }
                autoComplete="line1"
            />
            <TextField
                margin="dense"
                fullWidth
                id="line2"
                label="Address Line 2"
                name="line2"
                value={line2}
                onChange={e => setLine2(e.target.value) }
                autoComplete="line2"
            />
            <TextField
                margin="dense"
                required
                fullWidth
                id="city"
                label="City"
                name="city"
                value={city}
                onChange={e => setCity(e.target.value) }
                autoComplete="city"
            />
            <TextField
                margin="dense"
                required
                fullWidth
                label="State"
                name="state"
                value={state}
                onChange={e => setState(e.target.value) }
                id="state"
                autoComplete="state"
            />
            <TextField
                margin="dense"
                required
                fullWidth
                label="Postal Code"
                name="postalCode"
                value={postalCode}
                onChange={e => setPostalCode(e.target.value) }
                id="postalCode"
                autoComplete="postalCode"
            />
       </React.Fragment>
    );

}

