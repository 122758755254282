import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PanToolIcon from '@material-ui/icons/PanTool';
import {utilStyles} from '../Constants';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    typography: {
        flexGrow: 1,
        align: "center"
    },
    ...utilStyles(theme)
}));

export default function OffLimits(props) {
    const classes = useStyles();

    useEffect(() => {
        document.title = 'Off Limits';
    }, []);

    function renderOffLimitsMessage() {
        return (

            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <div className={classes.spacer5Top}></div>
                    <PanToolIcon
                        fontSize="large"
                        style={{ fontSize: 100 }}
                    />
                    <Typography component="h1"
                                variant="h1"
                                align="center">
                        Stop
                    </Typography>
                    <div className={classes.spacer2Top}></div>
                    <Typography component="h3"
                                variant="h3"
                                align="center">
                        Nothing to see here.
                    </Typography>
                </div>
            </Container>
        );
    }

    return (
        renderOffLimitsMessage()
    );
}
