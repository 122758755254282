import React from "react";
import {Route, Switch} from "react-router-dom";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import Login from "./containers/Login";
import SignupHome from "./containers/SignupHome";
import Signup from "./containers/Signup";
import SignupRetailer from "./containers/SignupRetailer";
import BuyRequestListing from "./containers/BuyRequestPage";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ResetPassword from "./containers/ResetPassword";
import Test from "./containers/Test";
import SignupRepConfirmationLandingPage from "./containers/SignupRepConfirmationLandingPage";
import CreateBuyRequestPage from "./containers/CreateBuyRequestPage";
import BuyRequestListingPage from "./containers/BuyRequestListingPage";
import CompleteSale from "./containers/CompleteSale";
import AccessDenied from "./containers/AccessDenied";
import EditBuyRequestPage from "./containers/EditBuyRequestPage";
import OrderPage from "./containers/OrderPage";
import BuyRequestsPage from "./containers/BuyRequestsPage";
import AccountPage from "./containers/AccountPage";
import ContactUs from "./containers/ContactUs";
import BuyRequestMessagePage from "./containers/BuyRequestMessagePage";
import BuyRequestMessageReplyPage from "./containers/BuyRequestMessageReplyPage";
import ChangePassword from "./containers/ChangePassword";
import SubscriptionsPage from "./containers/SubscriptionsPage";
import InitiateSale from "./containers/InitiateSale";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import TermsOfService from "./containers/TermsOfService";
import Home from "./containers/Home";
import LearnMorePage from "./containers/LearnMorePage";
import BrowseRequestsPage from "./containers/BrowseRequestsPage";
import CompleteCompanySignupPage from "./containers/CompleteCompanySignupPage";
import SignupRetailerDirect from "./containers/SignupRetailerDirect";

export default ({ childProps }) =>

        <Switch>
                <AppliedRoute path="/" exact component={Home} props={childProps}/>

                <AuthenticatedRoute path="/account" exact component={AccountPage} props={childProps}/>
                <AuthenticatedRoute path="/account/subscription" exact component={SubscriptionsPage} props={childProps}/>
                <AuthenticatedRoute path="/account/password/change" exact component={ChangePassword} props={childProps}/>
                <AuthenticatedRoute path="/buy-request/new" exact component={CreateBuyRequestPage} props={childProps}/>
                <AuthenticatedRoute path="/buy-request/:buyRequestId/edit" exact component={EditBuyRequestPage} props={childProps}/>
                <AuthenticatedRoute path="/buy-request/:buyRequestId/message" exact component={BuyRequestMessagePage} props={childProps}/>
                <AuthenticatedRoute path="/buy-request/:buyRequestId/message/reply" exact component={BuyRequestMessageReplyPage} props={childProps}/>
                <AuthenticatedRoute path="/buy-request/:buyRequestId/sale/initiate" exact component={InitiateSale} props={childProps}/>
                <AuthenticatedRoute path="/buy-request/:buyRequestId/sale/complete" exact component={CompleteSale} props={childProps}/>
                <AuthenticatedRoute path="/buy-requests" exact component={BuyRequestsPage} props={childProps}/>
                <AuthenticatedRoute path="/buy-requests/list" exact component={BuyRequestListingPage} props={childProps}/>
                <AuthenticatedRoute path="/order/:orderNumber" exact component={OrderPage} props={childProps}/>


                <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
                <UnauthenticatedRoute path="/signup" exact component={SignupHome} props={childProps}/>
                <UnauthenticatedRoute path="/signup/buyer" exact component={Signup} props={childProps}/>
                <UnauthenticatedRoute path="/signup/retailer" exact component={SignupRetailer} props={childProps}/>
                <UnauthenticatedRoute path="/signup/retailer/new" exact component={SignupRetailerDirect} props={childProps}/>
                { /* This is used when returning from Stripe signup */}


                <Route path="/signup/retailer/rep-conf" exact component={SignupRepConfirmationLandingPage} props={childProps}/>
                <Route path="/signup/retailer/complete" exact component={CompleteCompanySignupPage} props={childProps}/>
                <Route path="/test" exact component={Test} props={childProps}/>
                <Route path="/contact-us" exact component={ContactUs} props={childProps}/>
                <Route path="/reset-password" exact component={ResetPassword} props={childProps}/>
                <Route path="/browse" exact  component={BrowseRequestsPage} props={childProps}/>
                <Route path="/buy-request/:buyRequestId" exact  component={props => <BuyRequestListing {...props} />} props={childProps}/>
                <Route path="/privacy-policy" exact component={PrivacyPolicy} props={childProps}/>
                <Route path="/terms-of-service" exact component={TermsOfService} props={childProps}/>
                <Route path="/learn-more" exact component={LearnMorePage} props={childProps}/>


                { /* Finally, catch all unmatched routes */}
                <Route path="/access-denied" component={AccessDenied}/>
                <Route path="/not-found" component={NotFound}/>
                <Route component={NotFound}/>
        </Switch>
