import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {utilStyles} from '../Constants';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PaymentIcon from '@material-ui/icons/Payment';
import CheckIcon from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from "react-number-format";
import Divider from "@material-ui/core/Divider";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import TextField from "@material-ui/core/TextField";
import LoaderButton from "../components/LoaderButton";
import {getUser} from "../services/AccountService";
import TProgress from "../widgets/TProgress";
import TPageHeader from "../widgets/TPageHeader";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {Paper} from "@material-ui/core";
import {Alert, AlertTitle} from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    bodyPaper: {
        padding: theme.spacing(2, 3, 2, 3)
    },
    table: {
        maxWidth: 350,
        margin: 'auto'
    },
    list: {
        listStyleType: 'square'
    },
    listItem: {
        // marginLeft: '-1.0em',
        // why not working!?!
        '&::before': {
            color: 'red !important;',
        }
    },
    ...utilStyles(theme)
}));

const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);


export default function CompleteSale(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    // Custom vars
    const [isLoading, setIsLoading] = useState(true);
    const [buyRequest, setBuyRequest] = useState(null);
    const [stepResult, setStepResult] = useState(null);
    const [shippingCost, setShippingCost] = useState(0);
    const [trackingNumber, setTrackingNumber] = useState("");
    const [showResubmitButton, setShowResubmitButton] = useState("");


    const HtmlTooltip = withStyles(theme => ({
        tooltip: classes.tooltip
    }))(Tooltip);

    useEffect(() => {
        const buyRequestId = props.match.params.buyRequestId;
        API.get("tryabuy", '/buy-request/' + buyRequestId).then(response => {
            // console.log("*** found buy request: " + JSON.stringify(response));
            setBuyRequest(response);
            document.title = 'Complete Sale';
            setIsLoading(false);
        }).catch(error => {
            console.log("**** error: " + error.response);
            setIsLoading(false);
        });
    }, []);

    function getShippingStep() {
        return (
            <div>
                <Typography component="h6" variant="h6" align="center">
                    {buyRequest.title}
                </Typography>
                <div>
                    <div className={classes.spacer2}></div>
                    <Typography component="div" variant="body2" align="center">
                        Please supply the final shipping amount and tracking.
                    </Typography>
                    <div className={classes.spacer1Top}></div>
                    <Typography component="div" variant="body2" align="center">
                        You will be reimbursed for the shipping amount in your payout.
                    </Typography>
                    <div className={classes.spacer1Top}></div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <CurrencyTextField
                                required
                                fullWidth
                                label="Shipping Cost"
                                variant="standard"
                                currencySymbol="$"
                                outputFormat={'number'}
                                value={shippingCost}
                                onChange={(event, value)=> formatAndSetShipping(value)}
                                placeholder="Shipping Cost"
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                required
                                fullWidth
                                id="trackingNumber"
                                label="Tracking Number"
                                name="trackingNumber"
                                value={trackingNumber}
                                onChange={e => setTrackingNumber(e.target.value)}
                                autoComplete="trackingNumber"
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

    function getReviewAndChargeStep() {
        return (
            <span>
            <TableContainer className={classes.table}>
                <Table size="small">
                    <TableBody>
                          <TableRow>
                              <TableCell component="th" scope="row">
                                  <Typography variant="body2">
                                      Buy Request Price
                                  </Typography>
                              </TableCell>
                              <TableCell align="right">
                                  <Typography variant="body2">
                                      <NumberFormat value={buyRequest.price}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={'$'} />
                                  </Typography>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell component="th" scope="row">
                                <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        Calculated based on the sales tax for the jurisdiction the buyer is buying the item from.
                                        <br /><br />
                                        <b>
                                            Remember: You are responsible for remitting and filing sales tax.
                                        </b>
                                    </React.Fragment>
                                }
                                placement="bottom">
                                <Typography variant="body2">
                                    Estimated Tax*
                                </Typography>
                                </HtmlTooltip>
                              </TableCell>
                              <TableCell align="right">
                                  <Typography variant="body2">
                                      <NumberFormat value={buyRequest.taxResult.amount_to_collect}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={'$'} />
                                  </Typography>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell component="th" scope="row">
                                  <Typography variant="body2">
                                      Shipping
                                  </Typography>
                              </TableCell>
                              <TableCell align="right">
                                  <Typography variant="body2">
                                      <NumberFormat value={buyRequest.shipping.cost}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={'$'} />
                                  </Typography>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell component="th" scope="row" colSpan={2}>
                                  <Divider />
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell component="th" scope="row">
                                  <Typography variant="subtitle2">
                                      Charge to Customer
                                  </Typography>
                              </TableCell>
                              <TableCell align="right">
                                  <Typography variant="subtitle2">
                                      <NumberFormat value={buyRequest.calculations.chargeToCustomer}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={'$'} />
                                  </Typography>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell component="th" scope="row" colSpan={2}>
                                  <div className={classes.spacer1Top}></div>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell component="th" scope="row">
                                  <HtmlTooltip
                                      title={
                                          <React.Fragment>
                                              Payment fees are the sum of the <b>4%</b> Try a Buy fee and the Stripe payment fee of <b>2.9% + 30&cent;</b>.
                                              <br />
                                              <div align="center">
                                                  <b>
                                                      <NumberFormat value={buyRequest.calculations.totalPaymentFees} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                  </b>
                                                  &nbsp;=&nbsp;
                                                  <NumberFormat value={buyRequest.calculations.applicationFee} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                  &nbsp;+&nbsp;
                                                  <NumberFormat value={buyRequest.calculations.stripeTransactionFee} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                              </div>
                                          </React.Fragment>
                                      }
                                      placement="bottom">
                                      <Typography variant="body2">
                                            Payment Fees*
                                      </Typography>
                                  </HtmlTooltip>
                              </TableCell>
                              <TableCell align="right">
                                  <Typography variant="body2">
                                      <NumberFormat value={buyRequest.calculations.totalPaymentFees}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={'$'} />
                                  </Typography>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell component="th" scope="row" colSpan={2}>
                                  <Divider />
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell component="th" scope="row">
                                  <Typography variant="body1" className={classes.greenMessage}>
                                      Your Payout
                                  </Typography>
                              </TableCell>
                              <TableCell align="right">
                                  <Typography variant="body1" className={classes.greenMessage}>
                                      <NumberFormat value={buyRequest.calculations.payout}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={'$'} />
                                  </Typography>
                              </TableCell>
                          </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider variant="middle" className={classes.spacer1Top} />
            <ul className={classes.list}>
                <li className={classes.listItem}>
                    <Typography component="span" variant="body2">
                        An additional Stripe payout fee of 0.25% + 25&cent; will be charged on the set payout schedule.
                    </Typography>
                </li>
                <li className={classes.listItem}>
                    <Typography component="span" variant="body2">
                        Tax remittance is the responsibility of the retailer. Try a Buy collects estimated taxes and you are responsible for remitting all taxes.
                    </Typography>
                </li>
                <li className={classes.listItem}>
                    <Typography component="span" variant="body2">
                        You will be paid out within 2-4 business days after shipping.
                    </Typography>
                </li>
                <li className={classes.listItem}>
                    <Typography component="span" variant="body2">
                        Returns are handled offline, directly between the buyer and retailer.
                    </Typography>
                </li>
            </ul>
            <LoaderButton
                color="primary"
                size="large"
                type="submit"
                isLoading={isLoading}
                onClick={completeSale}
                text="Complete Sale"
                loadingText="Completing..."
                fullWidth
            />
            </span>
        );
    }

    function getCompleteStep() {
        switch (stepResult.bizError) {
            case 601:
                return getCompletedErrorForAdmin();
            case 602:
                return getCompletedErrorForRep();
            default:
                return getCompletedSuccess();
        }
    }

    function getCompletedSuccess() {
        return (
            <React.Fragment>
                <Alert severity="success" className={classes.spacer1TopAndBottom}>
                    <AlertTitle>The sale is now completed.</AlertTitle>
                    Thank you. You will be paid out in 2-4 days.
                </Alert>
            </React.Fragment>
        );
    }

    function getCompletedErrorForAdmin() {
        return (
            <React.Fragment>
                <Alert severity="error" className={classes.spacer1TopAndBottom}>
                    <AlertTitle>Could not complete charge.</AlertTitle>
                    You must first log into the Stripe console and supply the required past-due information before making charges.
                </Alert>
                <div className={classes.spacer2Top}></div>
                { !showResubmitButton
                ?
                    <Button variant="contained"
                            color="primary"
                            fullWidth
                            onClick={()=> {
                                setShowResubmitButton(true);
                                window.open(stepResult.link.url, "_blank")
                            }}>
                        Stripe Dashboard
                    </Button>
                    :
                    <LoaderButton
                        color="primary"
                        size="large"
                        type="submit"
                        isLoading={isLoading}
                        onClick={completeSale}
                        text="Resubmit"
                        loadingText="Submitting..."
                        fullWidth
                    />
                }
            </React.Fragment>
        );
    }

    function getCompletedErrorForRep() {
        return (
            <React.Fragment>
                <Alert severity="error" className={classes.spacer1TopAndBottom}>
                    <AlertTitle>Could not complete charge.</AlertTitle>
                    Please have your company administrator log into the Stripe console and supply the required past-due information before making charges.
                </Alert>
            </React.Fragment>
        );
    }

    let nextValidator = null;
    let nextAction = null;

    function validateForm() {
        return nextValidator();
    }


    function validateShippingForm() {
        return trackingNumber.length > 0 && shippingCost > 0;
    }

    function formatAndSetShipping(value) {
        const floatVal = parseFloat(value);
        // console.log(`Raw value = ${value} typeof(value) = ${typeof value} and float = ${floatVal}`);
        setShippingCost(floatVal);
    }

    async function saveShippingAndNextAction() {
        setIsLoading(true);
        const user = await getUser();

        const body = {
            userId: user.userId,
            buyRequestId: buyRequest.buyRequestId,
            retailerId: user.retailerId,
            shipping: {
                shippingCost: shippingCost,
                trackingNumber: trackingNumber
            }
        };

        await API.post("tryabuy", `/buy-request/${buyRequest.buyRequestId}/sale/complete/shipping-and-calculate`, {
            body: body
        }).then(buyRequest => {
            // console.log(`Updated buy request: ${buyRequest}`);
            setBuyRequest(buyRequest);
            setIsLoading(false);
            // setShowSuccessMessage(true);
            // createdBuyRequest = true;
        }).catch(error => {
            console.log(error.response);
            setIsLoading(false);
            // setIsSubmitted(false);
        });
    };

    async function completeSale() {
        setIsLoading(true);
        const user = getUser();

        const body = {
            buyRequestId: buyRequest.buyRequestId,
            buyRequestUserId: buyRequest.userId,
            retailerId: buyRequest.retailerId,
            // pass the user ID of the user logged in, trying to complete the BR
            retailerUserId: user.userId
        };

        await API.post("tryabuy", `/buy-request/${buyRequest.buyRequestId}/sale/complete/complete-sale`, {
            body: body
        }).then(stepResult => {
            setStepResult(stepResult);
            setActiveStep(activeStep+1);
            setIsLoading(false);
        }).catch(error => {
            console.log(error.response);
            setIsLoading(false);
            // setIsSubmitted(false);
        });
    };

    function getSteps() {
        return ['Shipping', 'Review and Charge', 'Complete'];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                nextValidator = validateShippingForm;
                nextAction = saveShippingAndNextAction;
                document.title = 'Complete Sale - Shipping';
                return getShippingStep();

                // return getReviewAndChargeStep();

                // return getCompletedErrorForAdmin();
                // return getCompletedErrorForRep();
                // return getCompletedSuccess();

            case 1:
                nextValidator = null;
                nextAction = null;
                document.title = 'Complete Sale - Review and Charge';
                return getReviewAndChargeStep();
            case 2:
                document.title = 'Complete Sale - Finished';
                return getCompleteStep();
            default:
                return 'Unknown step';
        }
    }


    const handleNext = async () => {

        if (nextAction != null) {
            await nextAction();
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    // Original:
    // const handleNext = () => {
    //     setActiveStep(prevActiveStep => prevActiveStep + 1);
    // };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function renderStepper() {
        return (
            <div className={[classes.spacer3Top].join(' ')}>
                <Container maxWidth="sm">
                    <TPageHeader icon={CreditCardIcon}
                                 title="Complete Sale"
                                subtitle="You are about to complete this sale." />
                    <Paper elevation={1} className={[classes.bodyPaper, classes.spacer3Bottom].join(' ')}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                            {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {activeStep < steps.length && (
                                <div className={[classes.spacer1Top].join(' ')}>
                                        {getStepContent(activeStep)}
                                    <div className={classes.spacer1Top}>
                                        <Grid container justifyContent="flex-end">
                                            <Grid item>
                                                {activeStep === 0 &&
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    disabled={!validateForm()}
                                                >
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                                }
                                                {/*Commented out for now*/}
                                                {/*{activeStep !== 0 &&*/}
                                                {/*    <Button onClick={handleBack} className={[classes.pullRight].join(" ")}>*/}
                                                {/*        Back*/}
                                                {/*    </Button>*/}
                                                {/*}*/}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Paper>
                </Container>
            </div>
        );
    }
    return (
        <div>
            { isLoading
                ? <TProgress />
                : renderStepper()
            }
        </div>
    );
}


const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <AssignmentIcon />,
        2: <PaymentIcon />,
        3: <CheckIcon />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};
