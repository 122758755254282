import React, {useEffect, useState} from "react";
import LoaderButton from "../components/LoaderButton";
import {API, Auth} from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import CreateTwoToneIcon from '@material-ui/icons/CreateTwoTone';
import Grid from "@material-ui/core/Grid";
import {signIn} from "../services/AccountService";
import {utilStyles} from "../Constants";
import {bindActionCreators} from "redux";
import * as accountActions from "../actions/AccountActions";
import TPageHeader from "../widgets/TPageHeader";
import TButtonLink from "../widgets/TButtonLink";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

function Signup(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [emailHelperText, setEmailHelperText] = useState("");
    const [password, setPassword] = useState("");
    const [passwordHelperText, setPasswordHelperText] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [confirmationCodeHelperText, setConfirmationCodeHelperText] = useState("");
    const [createdInCognito, setCreatedInCognito] = useState(null);
    const [isDoneWithSignup, setIsDoneWithSignup] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        document.title = 'Buyer Signup';
    }, []);

    function validateForm() {
        return(
            firstName.length > 0 &&
            lastName.length > 0 &&
            email.length > 0 &&
            password.length > 0 &&
            password === confirmPassword
        );
    }

    function validateConfirmationForm() {
        return confirmationCode.length > 0;
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);

        setEmailHelperText("");
        setPasswordHelperText("");

        try {
            await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    'custom:userType': 'buyer',
                    given_name: firstName,
                    family_name: lastName
                }
            });
            setCreatedInCognito(true);
        } catch (e) {
            if (e.code === "UsernameExistsException") {
                setEmailHelperText(e.message);
            } else if (e.code === "InvalidPasswordException") {
                setPasswordHelperText(e.message);
            } else {
                console.log(e.code);
            }
        }
        setIsLoading(false);
    };

    const handleConfirmationSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        setConfirmationCodeHelperText("");

        try {
            await Auth.confirmSignUp(email, confirmationCode);
            const user = await signIn(email, password, {
                isRegistrationInProcess: true,
                actions: props.actions
            });

            await API.post("tryabuy", '/signup/buyer', {
                body: user
            }).then(response => {
                setIsDoneWithSignup(true);
                setIsLoading(false);
            }).catch(error => {
                console.log(error.response);
                setIsLoading(false);
            });
        } catch (e) {
            if (e.code === "CodeMismatchException") {
                setConfirmationCodeHelperText(e.message);
                setIsLoading(false);
            } else {
                console.error(e);
                setIsLoading(false);
            }
        }
    };

    function renderForm() {
        return (
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            autoComplete="firstName"
                            autoFocus
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            autoComplete="lastName"
                        />
                    </Grid>
                </Grid>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    autoComplete="email"
                    error={emailHelperText !== ""}
                    helperText={emailHelperText}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={passwordHelperText !== ""}
                    helperText={passwordHelperText}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Confirm Password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    type="password"
                    id="confirmPassword"
                    autoComplete="current-confirmPassword"
                />
                <Typography component="div" variant="caption" align="center" className={classes.spacer1TopAndBottom}>
                    By signing up, you agree to the
                    &nbsp;
                    <a href="/terms-of-service" target="_blank">
                        Terms of Service
                    </a>
                    &nbsp;and&nbsp;
                    <a href="/privacy-policy" target="_blank">
                        Privacy Policy
                    </a>
                    .
                </Typography>
                <div className={classes.spacer2Top}>
                    <LoaderButton
                        color="primary"
                        size="large"
                        disabled={!validateForm()}
                        type="submit"
                        isLoading={isLoading}
                        text="Signup"
                        loadingText="Signing up…"
                        fullWidth
                    />
                </div>
            </form>
        );
    }

    function renderConfirmationForm() {
        return (
            <div>
                <Typography component="div" variant="subtitle1" align="center">
                    Please enter the confirmation code emailed to you.
                </Typography>
                <form onSubmit={handleConfirmationSubmit}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Confirmation Code"
                        name="confirmationCode"
                        value={confirmationCode}
                        onChange={e => setConfirmationCode(e.target.value)}
                        id="confirmationCode"
                        error={confirmationCodeHelperText !== ""}
                        helperText={confirmationCodeHelperText}
                    />
                    <LoaderButton
                        color="primary"
                        disabled={!validateConfirmationForm()}
                        type="submit"
                        isLoading={isLoading}
                        text="Verify"
                        fullWidth
                        loadingText="Verifying…"
                    />
                </form>
            </div>
        );
    }


    function getSuccessMessage() {
        return (
            <div>
                <Alert severity="success" className={classes.spacer1Top}>
                    Welcome! Your account has been created.
                </Alert>
                <Typography component="div" variant="subtitle1" align="center" className={classes.spacer1TopAndBottom}>
                    You can now create buy requests or checkout listings.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <TButtonLink text="Create Buy Request"
                               to='/buy-request/new'
                               color="secondary"
                               variant='outlined'
                               props={props}
                               fullWidth />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TButtonLink text="Checkout Listings"
                               to='/browse'
                               color="primary"
                               variant='outlined'
                               props={props}
                               fullWidth />
                    </Grid>
                </Grid>
            </div>
        );
    }

    return (
        <Container maxWidth="xs">
            <TPageHeader icon={CreateTwoToneIcon} title='Buyer Signup' />
            <div className={classes.spacer2Top}>
                {!createdInCognito
                    ? renderForm()
                    : !isDoneWithSignup ?
                        renderConfirmationForm()
                        :
                        getSuccessMessage()
                }
            </div>
        </Container>
        );
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    }
};


export default connect(null, mapDispatchToProps)(Signup);

