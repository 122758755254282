import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {BuyRequestStatus, utilStyles} from '../Constants';
import {API} from "aws-amplify";
import {getUser} from "../services/AccountService";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import MuiTableCell from '@material-ui/core/TableCell';
import NumberFormat from "react-number-format";
import {useTheme, withStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import StoreIcon from '@material-ui/icons/Store';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import BorderClearIcon from '@material-ui/icons/BorderClear';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TProgress from "../widgets/TProgress";
import TPageHeader from "../widgets/TPageHeader";
import * as tmpActions from "../actions/TmpActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import LoaderButton from "../components/LoaderButton";
import TButtonLink from "../widgets/TButtonLink";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles(theme => ({
    table: {
        maxWidth: 300,
        margin: 'auto'
    },
    darkOrange: {
        color: '#e67e00'
    },
    headerPaper: {
        padding: theme.spacing(2, 0, 1, 2)
    },
    bodyPaper: {
        padding: theme.spacing(2, 2, 2, 3)
    },
    list: {
        listStyleType: 'square'
    },
    listItem: {
        marginLeft: '-1.5em',
        // why not working!?!
        '&::before': {
            color: 'red !important;',
        }
    },
    ...utilStyles(theme)
}));

const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);


function OrderPage(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMessageBuyerPage, setIsLoadingMessageBuyerPage] = useState(false);
    const [orderInformation, setOrderInformation] = useState(null);
    const orderNumber = props.match.params.orderNumber;
    const { setTmpObject } = props.actions;
    const HtmlTooltip = withStyles(theme => ({
        tooltip: classes.tooltip
    }))(Tooltip);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.up('xs'));

    useEffect(() => {
        ( () => {
            setIsLoading(true);
            const user = getUser();
            API.get("tryabuy", `/order/${orderNumber}?userId=${user.userId}`).then(orderResponse => {
                setOrderInformation(orderResponse);
                document.title = 'Order Page';
                setIsLoading(false);
            }).catch(error => {
                console.log(`Error: ${JSON.stringify(error.response.data)}`);
                if (error.response) {
                    const response = error.response;
                    if (response.status === 404) {
                        props.history.push('/not-found');
                    } else if (response.status === 403) {
                        props.history.push('/access-denied');
                    }
                }
                setIsLoading(false);
            });
        })();
    }, []);


    function handleMessageBuyerClick() {
        setIsLoadingMessageBuyerPage(true);
        API.get("tryabuy", `/buy-request/${orderInformation.buyRequestId}`).then(buyRequest => {
            setTmpObject(buyRequest);
            setIsLoadingMessageBuyerPage(false);
            props.history.push(`/buy-request/${buyRequest.buyRequestId}/message`);
        }).catch(error => {
            console.log("**** error: " + error.response);
            setIsLoadingMessageBuyerPage(false);
        });
    }



    function getStatusClassNames(status) {
        let statClasses = [classes.uppercase, classes.bold];
        switch (status) {
            case BuyRequestStatus.PENDING.key: statClasses.push(classes.darkOrange);        break;
            case BuyRequestStatus.COMPLETE.key: statClasses.push(classes.greenMessage);     break;
            case BuyRequestStatus.EXPIRED.key: statClasses.push(classes.redMessage);        break;
            default: statClasses.push(classes.redMessage);
        }

        return statClasses.join(' ');
    }

    function getFormattedDate(unixSeconds) {
        // return moment(unixSeconds).format('MMMM Do YYYY');
        return moment(unixSeconds).format('ddd M/D/YY');
    }

    function getFormattedDateAndTime(unixSeconds) {
        return moment(unixSeconds).format('MMMM Do YYYY, h:mm:ss A');
    }

    function renderOrderInformation() {
        let content = '';
        if (orderInformation.renderType === 'buyer-pending') {
            content = getBuyerPendingContent();
        } else if (orderInformation.renderType === 'buyer-complete') {
            content = getBuyerCompleteContent();
        } else if (orderInformation.renderType === 'retailer-pending') {
            content = getRetailerPendingContent();
        } else if (orderInformation.renderType === 'retailer-complete') {
            content = getRetailerCompleteContent();
        } else {
            content = 'Unknown Status'
        }
        return content;
    }


    function HeaderSection() {
        let statusDisplayDateString = 'UNINITIALIZED';
        if (orderInformation.status === BuyRequestStatus.PENDING.key) {
            statusDisplayDateString = getFormattedDate(orderInformation.pendingTimestamp);
        } else if (orderInformation.status === BuyRequestStatus.COMPLETE.key) {
            statusDisplayDateString = getFormattedDate(orderInformation.completeTimestamp);
        }

        return (
            <Paper elevation={2} className={[classes.headerPaper].join(' ')}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography component="h6" variant="h6">
                            {orderInformation.title}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <List dense={true}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <BorderClearIcon fontSize="small" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={
                                    <Typography variant="body2" component="span" className={classes.bold}>
                                        {orderInformation.orderNumber}
                                    </Typography>
                                } secondary="Order Number" />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <List dense={true}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <AccessTimeIcon fontSize="small" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={
                                    <Typography variant="body2" component="span" className={getStatusClassNames(orderInformation.status)}>
                                        {orderInformation.status}
                                    </Typography>
                                } secondary={`${BuyRequestStatus.toLabel(orderInformation.status)} as of ${statusDisplayDateString}`} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
    function getBuyerPendingContent() {
        return (
            <div>
                <HeaderSection />
                <Paper elevation={0} variant="outlined" className={[classes.bodyPaper, classes.spacer2Top].join(' ')}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <div>
                                <Typography component="div" variant="overline" color="textSecondary">
                                    Sale Information
                                </Typography>
                                <Divider />
                                <ul className={classes.list}>
                                    <li className={classes.listItem}>
                                        <Typography component="span" variant="body2" color="textSecondary">
                                            Sold by:&nbsp;
                                        </Typography>
                                        <Typography component="span" variant="body2">
                                            {orderInformation.retailerName}
                                        </Typography>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TableContainer className={classes.table}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body2">
                                                    Buy Request Price
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    <NumberFormat value={orderInformation.price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            Tax is determined once shipped from the retailer.
                                                        </React.Fragment>
                                                    }
                                                    placement="bottom">
                                                    <Typography variant="body2">
                                                        Tax
                                                    </Typography>
                                                </HtmlTooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    --
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            Shipping is determined once shipped from the retailer.
                                                        </React.Fragment>
                                                    }
                                                    placement="bottom">
                                                    <Typography variant="body2">
                                                        Shipping
                                                    </Typography>
                                                </HtmlTooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    --
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <Divider />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            Total is determined once shipped and completed by the retailer.
                                                            You will be notified.
                                                        </React.Fragment>
                                                    }
                                                    placement="bottom">
                                                    <Typography variant="body1">
                                                        Total
                                                    </Typography>
                                                </HtmlTooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">
                                                    --
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <div className={classes.spacer1Top}></div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <div>
                        <Typography component="div" variant="overline" color="textSecondary">
                            Things to Know
                        </Typography>
                        <Divider />
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <Typography component="span" variant="body2">
                                    Tax, shipping and total will be determined when the retailer ships the item and completes the sale.
                                    You will be notified and your card will be charged then.
                                </Typography>
                            </li>
                        </ul>
                    </div>
                </Paper>
            </div>
        );
    }

    function getBuyerCompleteContent() {
        return (
            <div>
                <HeaderSection />
                <Paper elevation={0} variant="outlined" className={[classes.bodyPaper, classes.spacer2Top].join(' ')}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <div>
                                <Typography component="div" variant="overline" color="textSecondary">
                                    Sale Information
                                </Typography>
                                <Divider />
                                <ul className={classes.list}>
                                    <li className={classes.listItem}>
                                        <Typography component="span" variant="body2" color="textSecondary">
                                            Tracking number:&nbsp;
                                        </Typography>
                                        <Typography component="span" variant="body2">
                                            {orderInformation.shipping.trackingNumber}
                                        </Typography>
                                    </li>
                                    <li className={classes.listItem}>
                                        <Typography component="span" variant="body2" color="textSecondary">
                                            Sold by&nbsp;
                                        </Typography>
                                        <Tooltip title={`Sale completed on ${getFormattedDateAndTime(orderInformation.completedTimestamp)}`} placement="bottom">
                                            <Typography component="span" variant="body2">
                                                {orderInformation.retailerName}
                                            </Typography>
                                        </Tooltip>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TableContainer className={classes.table}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body2">
                                                    Buy Request Price
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    <NumberFormat value={orderInformation.price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            Sales tax of the retailer selling to your billing zip code.
                                                        </React.Fragment>
                                                    }
                                                    placement="bottom">
                                                    <Typography variant="body2">
                                                        Tax
                                                    </Typography>
                                                </HtmlTooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    <NumberFormat value={orderInformation.tax} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            Shipping cost of retailer shipping to your shipping address.
                                                        </React.Fragment>
                                                    }
                                                    placement="bottom">
                                                    <Typography variant="body2">
                                                        Shipping
                                                    </Typography>
                                                </HtmlTooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    <NumberFormat value={orderInformation.shipping.cost} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <Divider />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1">
                                                    <span className={classes.bold}>Total</span>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1" className={classes.bold}>
                                                    <NumberFormat value={orderInformation.totalCharge} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <div>
                        <Typography component="div" variant="overline" color="textSecondary">
                            Things to Know
                        </Typography>
                        <Divider />
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <Typography component="span" variant="body2">
                                    Any returns or post-sale actions must be coordinated directly with the retailer.
                                </Typography>
                            </li>
                        </ul>
                    </div>
                </Paper>
            </div>
        );
    }

    function getRetailerPendingContent() {
        return (
            <div>
                <HeaderSection />
                <Paper elevation={0} variant="outlined" className={[classes.bodyPaper, classes.spacer2Top].join(' ')}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <div>
                                <Typography component="div" variant="overline" color="textSecondary">
                                    Sale Information
                                </Typography>
                                <Divider />
                                <ul className={classes.list}>
                                    <li className={classes.listItem}>
                                        <Typography component="span" variant="body2" color="textSecondary">
                                            Buyer:&nbsp;
                                        </Typography>
                                        <Typography component="span" variant="body2">
                                            {orderInformation.buyerName}
                                        </Typography>
                                    </li>
                                    <li className={classes.listItem}>
                                        <Typography component="span" variant="body2" color="textSecondary">
                                            Sale initiated by:&nbsp;
                                        </Typography>
                                        <Tooltip title={`Initiated on ${getFormattedDateAndTime(orderInformation.pendingTimestamp)}`} placement="bottom">
                                            <Typography component="span" variant="body2">
                                                {orderInformation.initiatingRetailerUserName}&nbsp;
                                            </Typography>
                                        </Tooltip>
                                    </li>
                                </ul>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8}>
                                        <TButtonLink text="Complete Sale"
                                                     to={`/buy-request/${orderInformation.buyRequestId}/sale/complete`}
                                                     variant='contained'
                                                     size='small'
                                                     color="primary"
                                                     fullWidth={isXs}
                                                     props={props} />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <LoaderButton
                                            variant='outlined'
                                            color="secondary"
                                            size='small'
                                            isLoading={isLoadingMessageBuyerPage}
                                            text="Message Buyer"
                                            loadingText="Loading..."
                                            onClick={handleMessageBuyerClick}
                                            fullWidth={isXs}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TableContainer className={classes.table}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body2">
                                                    Buy Request Price
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    <NumberFormat value={orderInformation.price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            Calculated based on the sales tax for the jurisdiction the buyer is buying the item from.
                                                            <br /><br />
                                                            <b>
                                                                Remember: You are responsible for remitting and filing sales tax.
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                    placement="bottom">
                                                    <Typography variant="body2">
                                                        Estimated Tax*
                                                    </Typography>
                                                </HtmlTooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    --
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body2">
                                                    Shipping
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    --
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <Divider />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="subtitle2">
                                                    Charge to Customer
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="subtitle2">
                                                    --
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <div className={classes.spacer1Top}></div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <p>
                                                                Payment fees are the sum of the <b>4%</b> Try a Buy fee and the Stripe payment fee of <b>2.9% + 30&cent;</b>.
                                                            </p>
                                                        </React.Fragment>
                                                    }
                                                    placement="bottom">
                                                    <Typography variant="body2">
                                                        Payment Fees*
                                                    </Typography>
                                                </HtmlTooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    --
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <Divider />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1">
                                                    Your Payout
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1">
                                                    --
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <div>
                        <Typography component="div" variant="overline" color="textSecondary">
                            Things to Know
                        </Typography>
                        <Divider />
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <Typography component="span" variant="body2">
                                    An additional Stripe payout fee of 0.25% + 25&cent; will be charged on the set payout schedule.
                                </Typography>
                            </li>
                            <li className={classes.listItem}>
                                <Typography component="span" variant="body2">
                                    Tax, shipping and fees will be determined when completing the sale.
                                </Typography>
                            </li>
                        </ul>
                    </div>
                </Paper>
            </div>
        );
    }

    function getRetailerCompleteContent() {
        return (
            <div>
                <HeaderSection />
                <Paper elevation={0} variant="outlined" className={[classes.bodyPaper, classes.spacer2Top].join(' ')}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <div>
                                <Typography component="div" variant="overline" color="textSecondary">
                                    Sale Information
                                </Typography>
                                <Divider />
                                <ul className={classes.list}>
                                    <li className={classes.listItem}>
                                        <Typography component="span" variant="body2" color="textSecondary">
                                            Buyer:&nbsp;
                                        </Typography>
                                        <Typography component="span" variant="body2">
                                            {orderInformation.buyerName}
                                        </Typography>
                                    </li>
                                    <li className={classes.listItem}>
                                        <Typography component="span" variant="body2" color="textSecondary">
                                            Tracking number:&nbsp;
                                        </Typography>
                                        <Typography component="span" variant="body2">
                                            {orderInformation.shipping.trackingNumber}
                                        </Typography>
                                    </li>
                                    <li className={classes.listItem}>
                                        <Typography component="span" variant="body2" color="textSecondary">
                                            Initiated by:&nbsp;
                                        </Typography>
                                        <Tooltip title={`Initiated on ${getFormattedDateAndTime(orderInformation.pendingTimestamp)}`} placement="bottom">
                                            <Typography component="span" variant="body2">
                                                {orderInformation.initiatingRetailerUserName}&nbsp;
                                            </Typography>
                                        </Tooltip>
                                    </li>
                                    <li className={classes.listItem}>
                                        <Typography component="span" variant="body2" color="textSecondary">
                                            Completed by:&nbsp;
                                        </Typography>
                                        <Tooltip title={`Completed on ${getFormattedDateAndTime(orderInformation.completeTimestamp)}`} placement="bottom">
                                            <Typography component="span" variant="body2">
                                                {orderInformation.completingRetailerUserName}&nbsp;
                                            </Typography>
                                        </Tooltip>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TableContainer className={classes.table}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body2">
                                                    Buy Request Price
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    <NumberFormat value={orderInformation.price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            Calculated based on the sales tax for the jurisdiction the buyer is buying the item from.
                                                            <br /><br />
                                                            <b>
                                                                Remember: You are responsible for remitting and filing sales tax.
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                    placement="bottom">
                                                    <Typography variant="body2">
                                                        Estimated Tax*
                                                    </Typography>
                                                </HtmlTooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    <NumberFormat value={orderInformation.tax} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body2">
                                                    Shipping
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    <NumberFormat value={orderInformation.shipping.cost} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <Divider />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="subtitle2">
                                                    Charge to Customer
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="subtitle2">
                                                    <NumberFormat value={orderInformation.calculations.chargeToCustomer} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <div className={classes.spacer1Top}></div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <HtmlTooltip
                                                    title={
                                                        <Typography component="div" align="center" variant="caption">
                                                            <p>
                                                                Payment fees are the sum of the <b>4%</b> Try a Buy fee and the Stripe payment fee of <b>2.9% + 30&cent;</b>.
                                                            </p>
                                                            <b>
                                                                <NumberFormat value={orderInformation.calculations.totalPaymentFees} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                            </b>
                                                            &nbsp;=&nbsp;
                                                            <NumberFormat value={orderInformation.calculations.applicationFee} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                            &nbsp;+&nbsp;
                                                            <NumberFormat value={orderInformation.calculations.stripeTransactionFee} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                        </Typography>
                                                    }
                                                    placement="bottom">
                                                    <Typography variant="body2">
                                                        Payment Fees*
                                                    </Typography>
                                                </HtmlTooltip>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    <NumberFormat value={orderInformation.calculations.totalPaymentFees} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2}>
                                                <Divider />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1" className={classes.greenMessage}>
                                                    Your Payout
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body1" className={classes.greenMessage}>
                                                    <NumberFormat value={orderInformation.calculations.payout} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2" fixedDecimalScale="true" />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <div>
                        <Typography component="div" variant="overline" color="textSecondary">
                            Things to Know
                        </Typography>
                        <Divider />
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <Typography component="span" variant="body2">
                                    An additional Stripe payout fee of 0.25% + 25&cent; will be charged on the set payout schedule.
                                </Typography>
                            </li>
                            <li className={classes.listItem}>
                                <Typography component="span" variant="body2">
                                    As the retailer, you are responsible for remitting taxes.
                                    They have been estimated and collected for you by the Try a Buy platform, included in your payout.
                                </Typography>
                            </li>
                        </ul>
                    </div>
                </Paper>
            </div>
        );
    }

    return (
        <Container maxWidth="md">
            <TPageHeader icon={StoreIcon} title="Order Information" />
            {
                isLoading
                ? <TProgress />
                :
                  renderOrderInformation()
            }
        </Container>
    );
}

const mapDispatchToProps = (dispatch) => {
    const actions = { ...tmpActions };
    return {
        actions: bindActionCreators(actions,  dispatch)
    }
};

export default connect(null, mapDispatchToProps)(OrderPage);


