import React, {useEffect} from 'react';

import * as Constants from "../Constants";

export default function Test(props) {


    useEffect(() => {
        // callTaxJar();

        console.log("label = " + Constants.BuyRequestStatus.keyToStatus(Constants.BuyRequestStatus.SUSPENDED.key).label);

        // let currentFilterExpression = 'userId = :userId';
        let params = {
            TableName: "buyRequests"
        };
        params = addQueryFilterStatuses(params, [Constants.BuyRequestStatus.ACTIVE, Constants.BuyRequestStatus.PENDING]);
        console.log('params = ' + JSON.stringify(params));



    }, []);


     function addQueryFilterStatuses(queryParams, statuses) {
        const currentFilterExpression = queryParams.FilterExpression;
        console.log('Before filter expression = ' + currentFilterExpression);
        let filterExpression;
        if (currentFilterExpression && currentFilterExpression != null) {
            filterExpression += ' and ';
        } else if (!currentFilterExpression) {
            filterExpression = '';
        } else {
            console.log(`Unexpected filter expression input: "${currentFilterExpression}"`);
        }

        filterExpression += '(';
        statuses.forEach(function(status, i) {
            const statusAttributeName = `#sts${i}`;
            filterExpression += `contains (${statusAttributeName}, :${i}_val)`;
            if (i < statuses.length-1) {
                filterExpression += ' or ';
            }
        });
        filterExpression += ')';

        queryParams.FilterExpression = filterExpression;
        console.log('New filter expression = ' + queryParams.FilterExpression);

        // now add expression attribute keys and values; "status" is a reserved word
        if (!queryParams.ExpressionAttributeNames) {
            queryParams.ExpressionAttributeNames = {};
        }
        statuses.forEach(function(status, i) {
            const statusAttributeName = `#sts${i}`;
            queryParams.ExpressionAttributeNames[statusAttributeName] = "status";
        });


        if (!queryParams.ExpressionAttributeValues) {
            queryParams.ExpressionAttributeValues = {};
        }
        statuses.forEach(function(status, i) {
            queryParams.ExpressionAttributeValues[`:${i}_val`] = status.key;
        });

        console.log("Returning status params object: " + JSON.stringify(queryParams));
        return queryParams;
    }

    return (
        <h4>Hi. 3</h4>
    );
}