import {RETAILER_IS_SET, RETAILER_SET_IS_SET, SHOW_STEPPER_IS_SET} from '../actions/SignupActions';
import {defaultState} from "../defaultState";

export function reducer(state = defaultState.signup, action = {}) {
  switch (action.type) {
    case  RETAILER_IS_SET: {
      return { ...state, retailer: action.data }
    }
    case SHOW_STEPPER_IS_SET: {
      return { ...state, showStepper: action.data }
    }
    case RETAILER_SET_IS_SET: {
      return { ...state, isRetailerSet: action.data }
    }
    default: return state;
  }
}