import React from "react";
import Typography from '@material-ui/core/Typography';
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {makeStyles} from "@material-ui/core";
import {utilStyles} from "../Constants";

const useStyles = makeStyles(theme => ({
    pageHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    ...utilStyles(theme)
}));

export default ({ icon: Icon, title: title, subtitle: subtitle }) => {
    const classes = useStyles();
    return (
        <div className={[classes.spacer3TopAndBottom, classes.pageHeader].join(' ')}>
            <Avatar className={classes.avatar}>
                <Icon />
            </Avatar>
            <Typography component="h5" variant="h5" className={classes.spacer2Top}>
                {title}
            </Typography>
            { subtitle &&
                <Typography component="div" variant="subtitle1" className={classes.spacer1Top}>
                    {subtitle}
                </Typography>
            }
        </div>
    );
}
