import React, {useEffect, useState} from 'react';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import * as Constants from "../Constants";
import {Category, utilStyles} from "../Constants";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete'
import Tooltip from '@material-ui/core/Tooltip';
import LoaderButton from "./LoaderButton";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from "@material-ui/core/Button";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import moment from "moment";
import {API, Storage} from "aws-amplify";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import {getUser} from "../services/AccountService";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {DropzoneArea} from "material-ui-dropzone";
import {generateDisplayName, s3Upload} from "../Utils";
import TPageHeader from "../widgets/TPageHeader";
import Paper from '@material-ui/core/Paper';
import TProgress from "../widgets/TProgress";
import { Alert, AlertTitle } from '@material-ui/lab';
import TButtonLink from "../widgets/TButtonLink";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
    bodyPaper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1, 3, 1, 3)
    },
    textField: {
        width: '100%',
    },
    menu: {
        width: '100%',
    },
    dropzone: {
        width: '100%'
    },
    ...utilStyles(theme)
}));

// References:
//   - https://itnext.io/how-to-build-a-dynamic-controlled-form-with-react-hooks-2019-b39840f75c4f
//   - https://github.com/unicef/material-ui-currency-textfield

export default function CreateEditBuyRequest(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [mode, setMode] = useState("create");
    const [buyRequestId, setBuyRequestId] = useState("");
    const [createTimestamp, setCreateTimestamp] = useState(0); // needed for key later
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState(0);
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [category, setCategory] = useState(null);
    const [files, setFiles] = useState();
    const [initialFiles, setInitialFiles] = useState();
    const [originalImages, setOriginalImages] = useState();
    const [renderDropzone, setRenderDropzone] = useState(true);
    const [expirationTimestamp] = useState(moment().add(1, 'week'));
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showEditSuccessMessage, setShowEditSuccessMessage] = useState(false);
    const [showCannotEditMessage, setShowCannotEditMessage] = useState(false);
    const blankSpecification = { specType: '', value: '' };
    const [specifications, setSpecifications] = useState([
        {...blankSpecification}
    ]);
    const [availableSpecificationsTypes] = useState([
        ...Constants.BuyRequestSpecification.values()
    ]);
    const optionalStatusCallbackHandler = props.optionalStatusCallbackHandler;
    const defaultCategories = {
        options: Constants.Category.values(),
        getOptionLabel: (category) => category.label,
    };
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    // const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        const path = props.location.pathname;
        if (path.endsWith('/edit')) {
            // setIsLoading(true);
            setMode('edit');
            loadBuyRequestEdit();
        } else {
            setIsLoading(false);
        }
        return () => {
            setRenderDropzone(false);
            setIsLoading(false);
        };
    }, []);

    function isBlankSpecification(specification) {
        let isBlankSpec = false;
        if (specification.specType === blankSpecification.specType &&
            specification.value === blankSpecification.value) {
            isBlankSpec = true;
        }
        return isBlankSpec;
    }

    async function loadBuyRequestEdit() {
        const buyRequestId = props.match.params.buyRequestId;
        setBuyRequestId(buyRequestId);
        const user = getUser();
        const userId = user.userId;
        const endpoint = `/buy-request/${buyRequestId}/edit?userId=${userId}`;

        API.get("tryabuy", endpoint).then(buyRequest => {
            // console.log("*** Found buy request: " + JSON.stringify(buyRequest));
            setCreateTimestamp(buyRequest.createTimestamp); // needed for key
            setTitle(buyRequest.title);
            // price set below, after isLoading; see reason
            setSpecifications(buyRequest.specifications);
            setCategory(Category.keyToCategory(buyRequest.category));
            setAdditionalInfo(buyRequest.additionalInfo);
            setOriginalImages(buyRequest.images);
            loadImagesForEdit(buyRequest.images, buyRequestId);
            setIsLoading(false);

            // WEIRD BEHAVIOR: Code library issue: Entering the price before setting isLoading is false, causes an AutoNumeric
            //      library error. This is probably due to the stored multiple values during the re-rendering. Therefore,
            //      setPrice is done once after the re-rendering.
            setPrice(buyRequest.price);
            // console.log(`onLoad Price = \"${buyRequest.price}\" isNaN = ${isNaN(buyRequest.price)} and is-string? ${typeof buyRequest.price === 'string'}`)
        }).catch(error => {
            if (error.response) {
                const response = error.response;
                if (response.status === 404) {
                    props.history.push('/not-found');
                } else if (response.status === 403) {
                    props.history.push('/access-denied');
                } else if (response.status === 520) {
                    setShowCannotEditMessage(true);
                }
            } else {
                console.log("**** error: " + error);
            }
            setIsLoading(false);
        });
    }

    async function loadImagesForEdit(images, buyRequestId) {
        if (images.length >= 1) {
            setRenderDropzone(false);
            let initialFilesForEdit = [];
            let imagesToBeLoaded = images.length;
            images.forEach(function (image) {
                const key = `buy-requests/${buyRequestId}/${image.fileName}`;
                Storage.get(key, {expires: 60})
                    .then(absoluteImageUrl => {
                        // console.log(absoluteImageUrl);
                        var request = new XMLHttpRequest();
                        request.open('GET', absoluteImageUrl, true);
                        request.responseType = 'blob';
                        request.onload = function() {
                            var reader = new FileReader();
                            reader.readAsDataURL(request.response);
                            reader.onload =  function(e){
                                var dataUrl = e.target.result;
                                // console.log('DataURL:', dataUrl);

                                // get content type
                                const cTypeStartIdx = dataUrl.indexOf(':') + 1;
                                const cTypeEndIdx = dataUrl.indexOf(';');
                                const contentType = dataUrl.substr(cTypeStartIdx, (cTypeEndIdx - cTypeStartIdx));

                                // https://stackoverflow.com/questions/38263325/convert-url-to-file-object-with-javascript/38263409#38263409
                                var byteString = atob(dataUrl.split(',')[1]);
                                var ab = new ArrayBuffer(byteString.length);
                                var ia = new Uint8Array(ab);
                                for (var i = 0; i < byteString.length; i++) {
                                    ia[i] = byteString.charCodeAt(i);
                                }
                                // var blob = new Blob([ia], { type: 'image/jpeg' });
                                // var f = new File([blob], image.fileName, { type: 'image/jpeg' });
                                var blob = new Blob([ia], { type: contentType });
                                var f = new File([blob], image.fileName, { type: contentType });
                                initialFilesForEdit.push(f);
                                imagesToBeLoaded--;
                                if (imagesToBeLoaded === 0) {
                                    setInitialFiles(initialFilesForEdit);
                                    setRenderDropzone(true)
                                }
                            };
                        };
                        request.send();
                    }
                )
                .catch(err => console.log(err));
            });
        }
    }

    function deleteImages() {
        originalImages.forEach(function (image) {
            const key = `buy-requests/${buyRequestId}/${image.fileName}`;
            Storage.remove(key)
                .then(result => {
                        // console.log('deleted ' + JSON.stringify(image.fileName));
                    }
                )
                .catch(err => console.log(err));
        });
    }

    function getFormattedExpiration() {
        return expirationTimestamp.format('MMMM Do YYYY');
    }

    const handleSpecificsTypeChange = (e, ignore, specificationIndex) => {
        const specType = e.target.value;
        // console.log('*** specType = ' + specType + " and index = " + specificationIndex);
        const updatedSpecifications = [...specifications];
        // debugger;
        let currentSpecification = updatedSpecifications[specificationIndex];
        currentSpecification.specType = specType;
        // updateAvailableSpecifications(updatedSpecifications);
        setSpecifications(updatedSpecifications);
    };

    const handleSpecificsValueChange = (e, ignore, specificationIndex) => {
        const value = e.target.value;
        // console.log('*** value = ' + value + " and index = " + specificationIndex);
        const updatedSpecifications = [...specifications];
        // debugger;
        let currentSpecification = updatedSpecifications[specificationIndex];
        currentSpecification.value = value;
        // updateAvailableSpecifications(updatedSpecifications);
        setSpecifications(updatedSpecifications);
    };

    function addSpecific() {
        setSpecifications([...specifications, {...blankSpecification}]);
    }

    function deleteSpecific(e, ignore, deleteIndex) {
        const updatedSpecifications = [...specifications];
        updatedSpecifications.splice(deleteIndex, 1);
        setSpecifications(updatedSpecifications);
    }

    function validateForm() {
        let result = (
            title.length > 0 &&
            price > 0 &&
            (specifications.length > 0 &&
                specifications[0].specType !== blankSpecification.specType &&
                specifications[0].value !== blankSpecification.value) &&
            category != null
        );
        // console.log(`validateForm = ${result}`)
        // if (optionalStatusCallbackHandler) {
        //     optionalStatusCallbackHandler({isValidForm: result});
        // }
        return result;
    }

    function getConditionedSpecifications() {
        let specs = specifications;
        if (specifications.length === 1 && isBlankSpecification(specifications[0])) {
            specs = [];
            setSpecifications(specs);
        }
        return specs;
    }

    function addImagesToBuyRequest(buyRequest) {
        buyRequest.images = [];
        if (files && files.length >= 1) {
            files.forEach(async function (file) {
                // console.log(JSON.stringify(file));
                const image = {
                    fileName: file.name
                    // could later add order here...
                };
                buyRequest.images.push(image);
            });
        }
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);

        let createdBuyRequest = false;
        const user = await getUser();
        const userId = user.userId;
        const buyRequest = {
            title: title,
            price: price,
            specifications: getConditionedSpecifications(),
            additionalInfo: additionalInfo,
            category: category.key,
            expirationTimestamp: expirationTimestamp.valueOf(),
            displayName: generateDisplayName(user),
            userId: userId
        };
        addImagesToBuyRequest(buyRequest);

        setIsSubmitted(true);
        await API.post("tryabuy", `/buy-request`, {
            body: buyRequest
        }).then(response => {
            const buyRequest = response;
            // console.log(buyRequest);

            setBuyRequestId(buyRequest.buyRequestId);
            uploadFiles(buyRequest);

            setRenderDropzone(false);
            setIsLoading(false);
            setShowSuccessMessage(true);
            createdBuyRequest = true;
        }).catch(error => {
            console.log(error.response);
            setIsLoading(false);
            setIsSubmitted(false);
        });

        if (optionalStatusCallbackHandler) {
            optionalStatusCallbackHandler({createdBuyRequest: createdBuyRequest});
        }
    };

    const handleSave = async event => {
        event.preventDefault();

        deleteImages(buyRequestId);

        const user = await getUser();
        const userId = user.userId;
        const buyRequest = {
            buyRequestId: buyRequestId,
            createTimestamp: createTimestamp,
            title: title,
            price: price,
            specifications: getConditionedSpecifications(),
            additionalInfo: additionalInfo,
            category: category.key,
            userId: userId
        };
        addImagesToBuyRequest(buyRequest);
        const endpoint = `/buy-request/${buyRequestId}/edit`;

        setIsLoading(true);
        setIsSubmitted(true);
        API.post("tryabuy", endpoint, {
            body: buyRequest
        }).then(buyRequest => {
            // console.log(`handleSave buyRequest = ${JSON.stringify(buyRequest)}`);
            setShowEditSuccessMessage(true);
            // setIsSubmitted(false);

            setRenderDropzone(false);
            // Due to AutoNumeric.js code library error, this price component value needs to be cleared out
            //      before doing isLoading=false, which causes everything to re-render, but with multiple
            //      input values. Therefore, clear it out with null so no existing values, re-render with
            //      setIsLoading, then finally go back and set price value.
            setPrice(null);
            setIsLoading(false);
            setPrice(buyRequest.price);

            uploadFiles(buyRequest);

        }).catch(error => {
            console.log(error);
            setIsSubmitted(false);
            setIsLoading(false);
        });
    };

    function uploadFiles(buyRequest) {
        if (files && files.length >= 1) {

            for (const file of files) {
                (async () => {
                    // console.log(JSON.stringify(file));
                    // build key
                    const s3Key = `buy-requests/${buyRequest.buyRequestId}/${file.name}`;
                    const retval = await s3Upload(s3Key, file);
                    // console.log('uploaded ' + JSON.stringify(retval));
                })();
            }
        }
    }

    function handleFilesChange(files) {
        // console.log(JSON.stringify(files));
        setFiles(files);
    }

    const HtmlTooltip = withStyles(theme => ({
        tooltip: classes.tooltip
    }))(Tooltip);

    return (
        <div>
            {
                isLoading ?
                    <TProgress />
                    :
                    showCannotEditMessage ?
                        getCannotEditMessage()
                        :
                        renderFields()
            }
        </div>
    );

    function renderFields() {
        return (
            <div>
                <TPageHeader icon={AddShoppingCartIcon} title={mode === 'create' ? 'Create a Buy Request' : 'Edit Your Buy Request'} />
                <Paper elevation={2} className={classes.bodyPaper}>
                    {   !showSuccessMessage && !showEditSuccessMessage &&
                        getFormBody()
                    }
                    {getSuccessMessage()}
                    {getEditSuccessMessage()}
                </Paper>
                <div className={classes.spacer4Bottom}></div>
            </div>
        );
    }

    function getFormBody() {
        return (
            <form noValidate onSubmit={mode === 'create' ? handleSubmit : handleSave}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Buy Request"
                            name="title"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            autoComplete="title"
                            autoFocus
                        />
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <p>
                                        A "buy request" is a request to buy a new item from any retailer who can supply
                                        it, at the requested discounted price.
                                        You should make your request title, clear and concise of what you are looking
                                        for.
                                    </p>
                                </React.Fragment>
                            }
                            placement="bottom-end"
                        >
                            <Typography component="div" variant="caption">
                                What is a buy request?
                            </Typography>
                        </HtmlTooltip>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CurrencyTextField
                            required
                            fullWidth
                            label="Requested Price"
                            variant="standard"
                            currencySymbol="$"
                            value={price}
                            onChange={(e, value) => setPrice(value)}
                            placeholder="Requested Price"
                            margin="normal"
                        />
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    <p>
                                        This requested price for item that a retailer will sell it for if they agree to make a sale.
                                        Remember, retailers need to make <i>some</i> profit to stay in business.
                                        Different retail industries have minimum margins they must meet in order to consider selling an item.
                                    </p>
                                    <p>
                                        Rule of thumb: A good price request would be (roughly) 85% of the
                                        item for normal retail price online or in-store.
                                    </p>
                                    <p>
                                        For example, say you wanted a piece of furniture that normally retailed at $500,
                                        you could request it for $425 ($500 * .85).
                                    </p>
                                </React.Fragment>
                            }
                            placement="bottom-end"
                        >
                            <Typography component="div" variant="caption">
                                What is the requested price?
                            </Typography>
                        </HtmlTooltip>
                    </Grid>
                </Grid>
                {renderSpecifics()}
                <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                    <Grid item xs={12} sm={6}>
                        <HtmlTooltip
                            title={
                                <React.Fragment>
                                    You must specify at lease one specification.
                                    <br/><br/>
                                    Specifications are requirements of your item that the retailer must
                                    meet in their commitment to sell you the item you have requested.
                                    <br/><br/>
                                    Be specific so if a retailer decides to sell you the item, you know you
                                    will be receiving the the correct item you intended to purchase.
                                    <br/><br/>
                                    If requirements are lacking, unclear or ambiguous, a retailer could
                                    unintentionally sell you the wrong item.
                                </React.Fragment>
                            }
                            placement="bottom"
                        >
                            <Typography component="span" variant="caption">
                                What are specifications?
                            </Typography>
                        </HtmlTooltip>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            startIcon={<AddCircleOutlineIcon/>}
                            size="small"
                            onClick={addSpecific}
                            className={classes.pullRight}
                        >
                            <Typography component="span" variant="caption">
                                Add specification
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>

                <Autocomplete
                    {...defaultCategories}
                    id="controlled-demo"
                    value={category}
                    onChange={(event, newValue) => {
                        setCategory(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Category" margin="normal"/>}
                />

                <TextField
                    id="standard-textarea"
                    label="Additional Information"
                    fullWidth
                    value={additionalInfo}
                    onChange={e => setAdditionalInfo(e.target.value)}
                    placeholder="Add any additional information to help the retailer better understand."
                    multiline
                    margin="normal"
                />

                <Accordion className={classes.spacer2TopAndBottom}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        { mode === 'create' ?
                            <Typography component="span" variant="caption" className={classes.heading}>
                                Add an Image
                            </Typography>
                            :
                            <Typography component="span" variant="caption" className={classes.heading}>
                                Edit Image
                            </Typography>
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        { renderDropzone ?
                            <DropzoneArea
                                dropzoneClass={classes.dropzone}
                                onChange={handleFilesChange}
                                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                showPreviews={false}
                                maxFileSize={5000000}
                                dropzoneText="Drag-n-Drop or Click"
                                filesLimit={3}
                                initialFiles={initialFiles}
                            />
                            : ""
                        }
                    </AccordionDetails>
                </Accordion>

                <Typography component="div" variant="caption" align="center">
                    Listing expiration: <b>{getFormattedExpiration()}</b>
                </Typography>
                <div className={classes.spacer2TopAndBottom}>
                    {
                        mode === 'create' ?
                            <div>
                                <LoaderButton
                                    color="primary"
                                    size="large"
                                    disabled={!validateForm() || isSubmitted}
                                    type="submit"
                                    isLoading={isLoading}
                                    text="Submit Buy Request"
                                    loadingText="Submitting…"
                                    fullWidth
                                />
                                <div className={classes.spacer2Top}></div>
                                <TButtonLink text="Cancel"
                                             to={`/buy-requests/list`}
                                             variant='outlined'
                                             size="small"
                                             props={props}
                                             fullWidth />
                            </div>
                            :
                            <div>
                                <LoaderButton
                                    color="primary"
                                    size="large"
                                    disabled={!validateForm() || isSubmitted}
                                    type="submit"
                                    isLoading={isLoading}
                                    text="Save Buy Request"
                                    loadingText="Saving…"
                                    fullWidth
                                />
                                <div className={classes.spacer2Top}></div>
                                <TButtonLink text="Cancel"
                                             to={`/buy-request/${buyRequestId}`}
                                             variant='outlined'
                                             size="small"
                                             props={props}
                                             fullWidth />
                            </div>
                    }
                </div>
            </form>
        );
    }
    function renderSpecifics() {
        return (
            <div>
            {specifications.map((val, idx) => {
                    return (
                        <div key={`specification-${idx}`}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        select
                                        label="Specifications Type"
                                        className={classes.textField}
                                        onChange={(e, ignore) => {
                                            // console.log("select idx = " + idx);
                                            handleSpecificsTypeChange(e, ignore, idx);
                                        }}
                                        value={specifications[idx].specType}
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        margin="normal"
                                    >
                                        {
                                            availableSpecificationsTypes.map((option, oIdx) => (
                                            <MenuItem key={oIdx} value={option.key}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={0} alignItems="flex-end" >
                                        <Grid item xs={11}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                label="Value"
                                                onChange={(e, ignore) => {
                                                    // console.log("value idx = " + idx);
                                                    handleSpecificsValueChange(e, ignore, idx);
                                                }}
                                                value={specifications[idx].value}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton className={classes.button}
                                                        size="small"
                                                        onClick={(e, ignore) => deleteSpecific(e, ignore, idx)}>
                                                <HighlightOffIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    );
                })}
            </div>
            );
    }

    function getSuccessMessage() {
        var retval = '';
        if (showSuccessMessage) {
            retval =
            <div className={classes.spacer2TopAndBottom}>
                <Alert severity="success">
                    <AlertTitle>Great! Your buy request has been created.</AlertTitle>
                    It is active immediately, posted for retailers to see.
                </Alert>
                <Grid container spacing={2} className={classes.spacer1Top}>
                    <Grid item sm={6} xs={12}>
                        <TButtonLink text="See Buy Request"
                                     to={`/buy-request/${buyRequestId}`}
                                     color="secondary"
                                     variant='outlined'
                                     props={props}
                                     fullWidth />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TButtonLink text="Browse"
                                     to='/browse'
                                     color="primary"
                                     variant='outlined'
                                     props={props}
                                     fullWidth />
                    </Grid>
                </Grid>
            </div>
        }

        return retval;
    }

    function getEditSuccessMessage() {
        var retval = '';
        if (showEditSuccessMessage) {
            retval =
                <div className={classes.spacer2TopAndBottom}>
                    <Alert severity="success">
                        Your buy request has been saved.
                    </Alert>
                    <Grid container spacing={2} className={classes.spacer1Top}>
                        <Grid item sm={6} xs={12}>
                            <TButtonLink text="See Buy Request"
                                         to={`/buy-request/${buyRequestId}`}
                                         color="secondary"
                                         variant='outlined'
                                         props={props}
                                         fullWidth />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TButtonLink text="Browse"
                                         to='/browse'
                                         color="primary"
                                         variant='outlined'
                                         props={props}
                                         fullWidth />
                        </Grid>
                    </Grid>
                </div>
        }

        return retval;
    }

    function getCannotEditMessage() {
        return (
            <div className={classes.spacer2TopAndBottom}>
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    You cannot edit a buy request that is not in active status.
                </Alert>
            </div>
        );
    }

}

