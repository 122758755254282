import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {getUser, isBuyer, isLoggedIn, signOut} from "../services/AccountService";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import * as accountActions from "../actions/AccountActions";
import {utilStyles} from "../Constants";
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Grid from "@material-ui/core/Grid";
import TIcon from "../widgets/TIcon";
import TButtonLink from "../widgets/TButtonLink";
import {Link} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from '@material-ui/icons/Add';
import Hidden from "@material-ui/core/Hidden";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

const useStyles = makeStyles(theme => ({
    AppBar: {
        backgroundColor: '#fff',
        margin: 0
    },
    homeLogoImageXs: {
        height: '30px',
        width: 'auto',
        verticalAlign: 'middle'
    },
    homeLogoImageSmAndUp: {
        height: '40px',
        width: 'auto',
        verticalAlign: 'middle'
    },
    ...utilStyles(theme)
}));

function ButtonAppBar(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    // see: https://material-ui.com/customization/breakpoints/
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmAndUp = useMediaQuery(theme.breakpoints.up('sm'));

    const handleNewBuyRequest = (event) => {
        setAnchorEl(null);
        props.history.push('/buy-request/new');
    };

    const handleMyRequests = (event) => {
        setAnchorEl(null);
        props.history.push('/buy-requests/list');
    };

    const handleAccount = (event) => {
        setAnchorEl(null);
        props.history.push('/account');
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function logout() {
        handleClose();
        await signOut({actions: props.actions});
        props.history.push('/');
    }

    function renderAuthenticatedSection() {
        return (
            <div>
                <Grid container alignItems="center">
                    <Grid item>
                        <TButtonLink text="Browse"
                                     to='/browse'
                                     color="primary"
                                     variant='outlined'
                                     size="small"
                                     props={props} />
                        <span className={isXs ? classes.spacer1Right : classes.spacer2Right}></span>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item>
                            <TButtonLink text="My Requests"
                                         to='/buy-requests/list'
                                         color="secondary"
                                         variant='outlined'
                                         props={props} />
                            <span className={classes.spacer1Right}></span>
                        </Grid>
                    </Hidden>
                    {isBuyer(getUser()) &&
                        <Hidden xsDown>
                            <Grid item>
                                <IconButton
                                    aria-label="new buy request"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={() => {
                                        props.history.push('/buy-request/new')
                                    }}
                                    color="primary">
                                    <TIcon component={AddIcon} text={"New"}/>
                                </IconButton>
                            </Grid>
                        </Hidden>
                    }
                    <Grid item>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="primary">
                            <TIcon component={AccountCircle} text={"Other"} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}>
                            {isBuyer(getUser()) &&
                                <span>
                                    <Hidden smUp>
                                        <MenuItem onClick={handleNewBuyRequest}>
                                            <AddIcon color="primary" fontSize="small" />
                                            <span className={classes.spacer1Left}>
                                                New Buy Request
                                            </span>
                                        </MenuItem>
                                    </Hidden>
                                </span>
                            }
                            <span>
                                <Hidden smUp>
                                    <MenuItem onClick={handleMyRequests}>
                                        <ShoppingCartOutlinedIcon color="primary" fontSize="small" />
                                        <span className={classes.spacer1Left}>
                                            My Requests
                                        </span>
                                    </MenuItem>
                                </Hidden>
                            </span>
                            <MenuItem onClick={handleAccount}>
                                <AccountCircle color="primary" fontSize="small" />
                                <span className={classes.spacer1Left}>
                                    Account
                                </span>
                            </MenuItem>
                            <MenuItem onClick={logout}>
                                <ExitToAppIcon color="primary" fontSize="small" />
                                <span className={classes.spacer1Left}>
                                    Logout
                                </span>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </div>
        );
    }

    function renderUnauthenticatedSection() {
        return (
            <div>
                <Hidden xsDown>
                    <TButtonLink text="Browse"
                                 to='/browse'
                                 color="primary"
                                 variant='outlined'
                                 size="small"
                                 props={props} />
                    <span className={isXs ? classes.spacer1Right : classes.spacer2Right}></span>
                </Hidden>
                <TButtonLink text="Signup"
                             to='/signup'
                             color="secondary"
                             variant='contained'
                             props={props} />
                <span className={isXs ? classes.spacer1Right : classes.spacer2Right}></span>
                <TButtonLink text="Login"
                             to='/login'
                             color="primary"
                             variant='outlined'
                             props={props} />
            </div>
        );
    }

    return (
        <AppBar position="fixed" className={classes.AppBar}>
            <Toolbar>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Link href="/" onClick={() => {props.history.push('/')}}>
                            <img src="/logo/horizontal-transparent.png"
                                 className={isXs ? classes.homeLogoImageXs : classes.homeLogoImageSmAndUp}
                                 alt="Try a Buy Home" />
                        </Link>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" justifyContent="flex-end">
                            <Grid item>
                                {isLoggedIn() &&
                                    renderAuthenticatedSection()
                                }
                                {!isLoggedIn() &&
                                    renderUnauthenticatedSection()
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(accountActions, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(ButtonAppBar);




