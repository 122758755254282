import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


export default function AccessDenied() {

    useEffect(() => {
        document.title = 'Access Denied';
    }, []);

    return (
        <Container maxWidth="sm">
            <Grid container justifyContent="center">
                <Grid item>
                    <Typography component="h6" variant="h6">
                        Access Denied
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}
