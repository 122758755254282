import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from "react-router-dom";
import Amplify from "aws-amplify";
import config from "./config";
import tabStore, {history} from './store';
import {Provider} from 'react-redux';
import {ConnectedRouter} from "connected-react-router";
// import polyfills from './polyfills';
//
// polyfills();

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [
            {
                name: "tryabuy",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            },
        ]
    }
});

const { store } = tabStore();
ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Router >
                <App />
            </Router>
        </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();







/*
 * BEFORE REMOVING PERSIST STORE
 */
/*
const { store, persistor } = tabStore();
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <Router >
                    <App />
                </Router>
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);
*/
