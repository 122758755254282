import {RETAILER_IS_SET} from '../actions/AccountActions';
import {defaultState} from "../defaultState";

export function reducer(state = defaultState.account, action = {}) {
  switch (action.type) {
    case  RETAILER_IS_SET: {
      return { ...state, retailer: action.data }
    }
    default: return state;
  }
}
