import {API, Auth} from "aws-amplify";
import {generateUserFullName} from "../Utils";

let user = undefined;

export function getUser() {
    return user;
}


export async function initializeAccount(options) {
    // see https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#retrieve-current-authenticated-user

    const callback = options.callback; // optional and could be undefined
    await Auth.currentAuthenticatedUser({
        // by default is false. If set to true, this call will send a request to Cognito to get the latest user data
        bypassCache: false
    }).then(async cognitoUser => {
        // console.log("Cognito user: " + JSON.stringify(cognitoUser));
        const userId = cognitoUser.attributes.sub;

        if (!options.isRegistrationInProcess || options.isRegistrationInProcess === false) {
            await API.get("tryabuy", `/user/${userId}`).then(dbUser => {
                user = convertCognitoUserToAppUser(cognitoUser);

                // add db fields
                user.status = dbUser.status;
                user.hasPaymentInfo = dbUser.hasPaymentInfo;

                if (isRetailer(user)) {
                    (async () => {
                        await loadRetailer(options, user);
                    })();
                }
                if (callback) {
                    callback();
                }
            }).catch(error => {
                console.log("Error in completing user signin: " + error.response);
            });
        } else {
            user = convertCognitoUserToAppUser(cognitoUser);
            if (callback) {
                callback();
            }
        }
    })
    .catch((e) => {
        // console.log("Not signed in.");
        user = undefined;
        if (callback) {
            callback();
        }
    });

    return user;
}

function convertCognitoUserToAppUser(cognitoUser) {
    const a = cognitoUser.attributes;
    let user = {
        firstName: a.given_name,
        lastName: a.family_name,
        email: a.email,
        userId: a.sub,
        userType: a['custom:userType'],
        toString: function () {
            return this.firstName + " " + this.lastName + ", email = " + this.email;
        }
    };

    // potentially load retailerId
    const retailerId = a['custom:retailerId'];
    if (retailerId) {
        user.retailerId = retailerId;
    }

    user = decorateUserObject(user);
    return user;
}


export function decorateUserObject(user) {
    return {
        ...user,
        userFullName: generateUserFullName(user),
        toString: function () {
            return `${this.firstName} ${this.lastName}, email = ${this.email}`;
        }
    }
}

export function isLoggedIn() {
    return user !== undefined;
}

export async function signIn(email, password, options) {
    // first sign in
    await Auth.signIn(email, password);
    // then re-init the app
    let user = await initializeAccount(options);
    return user;
}

export async function signOut(options) {
    await Auth.signOut();
    user = undefined;
    updateRetailer('logout', options);
}

async function loadRetailer(options, user) {
    // could be undefined if during registration and not yet loaded
    const retailerId = user.retailerId;
    if (retailerId) {
        let retailer = await API.get("tryabuy", "/retailer/" + retailerId);
        updateRetailer('login', options, retailer);
    }
}

function updateRetailer(targetLoginState, options, retailer) {
    const { setRetailer } = options.actions;

    // assert first
    if (!setRetailer) {
        const msg = "No setRetailer action passed. It is required.";
        console.error(msg);
        throw msg;
    }

    // now set
    if (targetLoginState === 'login') {
        if (!retailer) {
            const msg = "No retailer passed. It is required to set.";
            console.error(msg);
            throw msg;
        }

        setRetailer(retailer);
    } else if (targetLoginState === 'logout') {
        setRetailer(null);
    } else {
        const msg = `Invalid targetLoginState state passed: ${targetLoginState}`;
        console.error(msg);
        throw msg;
    }
}

export function isBuyer(optionalUser) {
    let retval = false;
    let user = optionalUser ? optionalUser : getUser();

    // could still be undefined if not logged in
    if (user && user.userType === 'buyer') {
        retval = true;
    }
    return retval;
}

export function isRetailer(optionalUser) {
    let isRetailer = false;
    let user = optionalUser ? optionalUser : getUser();

    // could be undefined if not logged in or optionally not passed
    if (user && user.userType.includes("retailer-")) {
        isRetailer = true;
    }
    return isRetailer;
}

