import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export default function NotFound() {

    useEffect(() => {
        document.title = 'Page Not Found';
    }, []);

    return (
        <Container maxWidth="sm">
            <Grid container justifyContent="center">
                <Grid item>
                    <Typography component="h6" variant="h6">
                        Page Not Found
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}
