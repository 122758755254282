import React from 'react';
import {CardElement, injectStripe} from "react-stripe-elements";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {utilStyles} from "../Constants";

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

function CreditCardElement(props) {
    const classes = useStyles();
    const stripe = props.stripe;
    const updatePaymentInfo = props.tokenCallback;

    const handleBlur = (e) => {
        // console.log('[blur]');
    };
    const handleChange = (change) => {
        // console.log('[change]', change);
        // console.log("stripe = " + stripe);
        if (change.complete === true) {
            // debugger;
            stripe
                .createToken()
                .then((payload) => {
                    // console.log('[token]', payload);
                    // createStripeCustomer(payload);
                    updatePaymentInfo(true, payload);
                });
        } else {
            updatePaymentInfo(false, '');
        }
    };
    // const handleClick = (e) => {
    //     console.log('[click]');
    // };
    // const handleFocus = (e) => {
    //     console.log('[focus]');
    // };
    // const handleReady = (e) => {
    //     console.log('[ready]');
    // };

    const createOptions = () => {
        return {
            style: {
                base: {
                    fontSize: '1em',
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Roboto, Nunito, Arial, "Helvetica Neue"',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    padding: 1,
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };

    return (
        <label>
            <Typography variant="body2" component="div" className={classes.spacer1Bottom}>
                Card Details
            </Typography>
            <CardElement
                onBlur={handleBlur}
                onChange={handleChange}
                // onFocus={handleFocus}
                // onReady={handleReady}
                {...createOptions()}
            />
        </label>
    );

}


export default injectStripe(CreditCardElement);
