import React, {useEffect, useState} from "react";
import {makeStyles, Paper} from "@material-ui/core";
import {utilStyles} from '../Constants';
import {getUser} from "../services/AccountService";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import LoaderButton from "../components/LoaderButton";
import {API} from "aws-amplify";
import Grid from "@material-ui/core/Grid";
import TPageHeader from "../widgets/TPageHeader";
import EmailSharpIcon from "@material-ui/icons/EmailSharp";
import Alert from "@material-ui/lab/Alert";
import TProgress from "../widgets/TProgress";

const useStyles = makeStyles(theme => ({
    replyMessagePaper: {
        padding: theme.spacing(2),
    },
    ...utilStyles(theme)
}));

export default function BuyRequestMessageReplyPage(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [replyMessage, setReplyMessage] = useState("");
    const [message, setMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [buyRequestTitle, setBuyRequestTitle] = useState();
    const [titleMessage, setTitleMessage] = useState();
    const [senderFirstName, setSenderFirstName] = useState();
    const [retailerCompanyName, setRetailerCompanyName] = useState();
    const buyRequestId = props.match.params.buyRequestId;
    const queryString = props.location.search;
    const params = new URLSearchParams(queryString);
    const [emailId] = useState(params.get("e"));

    useEffect( () => {
        const endpoint = `/buy-request/${buyRequestId}/message/reply?e=${emailId}&u=${getUser().userId}`;
        API.get("tryabuy", endpoint).then(messageResponse => {
            setSenderFirstName(messageResponse.senderFirstName);
            setBuyRequestTitle(messageResponse.buyRequestTitle);
            setTitleMessage(messageResponse.titleMessage);
            setRetailerCompanyName(messageResponse.retailerCompanyName);
            setReplyMessage(messageResponse.message);
            document.title = 'Reply to Message';
            setIsLoaded(true);
        }).catch(error => {
            if (error.response) {
                const response = error.response;
                if (response.status === 404) {
                    props.history.push('/not-found');
                } else if (response.status === 403) {
                    props.history.push('/access-denied');
                }
            } else {
                console.log("**** error: " + error);
            }
            setIsLoaded(true);
        });
    }, []);

    function validateForm() {
        return message.length > 0 ;
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setIsSubmitting(true);
        let body = {
            previousEmailId: emailId,
            senderUserId: getUser().userId,
            message: message
        };
        API.post("tryabuy", `/buy-request/${buyRequestId}/message/reply`, {
            body: body
        }).then(response => {
            console.log(response);
            setIsSubmitting(false);
            setIsLoaded(true);
            setIsSubmitted(true);
        }).catch(error => {
            console.error(error);
            setIsSubmitting(false);
        });
    };

    function renderMessageArea() {
        return (
            <div>
                <TPageHeader icon={EmailSharpIcon} title="Message Reply" />
                <div className={classes.spacer3Top}></div>
                <form noValidate onSubmit={handleSubmit}>
                    {!isSubmitted ?
                        <span>
                            <Typography component="div" variant="subtitle1" align='center' className={classes.spacer2Top}>
                                {titleMessage}
                            </Typography>
                            <div className={classes.spacer3Top}></div>
                                <Paper className={classes.replyMessagePaper} elevation={1}>
                                    <Grid container justifyContent="flex-start">
                                        <Grid item xs={12}>
                                            <Typography component="div" variant="body2">
                                                {replyMessage}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <TextField
                                    margin="normal"
                                    label="Reply Message"
                                    multiline
                                    fullWidth
                                    name="message"
                                    rows={2}
                                    rowsMax={4}
                                    value={message}
                                    inputProps={{ maxLength: 500 }}
                                    onChange={e => setMessage(e.target.value)}
                                />
                                <LoaderButton
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    isLoading={isSubmitting}
                                    disabled={!validateForm()}
                                    text="Submit"
                                    loadingText="Submitting..."
                                    fullWidth
                                />
                        </span>
                        :
                        <Alert severity="success" className={classes.spacer2Top}>
                            Thank you. This message has been sent to {senderFirstName}.
                        </Alert>
                    }
                </form>
            </div>
        );
    }

    return (
        <Container maxWidth="sm">
            {isLoaded
                ? renderMessageArea()
                : <TProgress />
            }
        </Container>
    );
}

