import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import {API} from "aws-amplify";
import * as signupActions from "../actions/SignupActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

let suggestions = [];

function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;
    return (
        <TextField
            InputProps={{
                inputRef: ref,
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                ...InputProps,
            }}
            {...other}
        />
    );
}

renderInput.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

    return (
        <MenuItem
            {...itemProps}
            key={suggestion.label}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.label}
        </MenuItem>
    );
}

renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
    index: PropTypes.number.isRequired,
    itemProps: PropTypes.object.isRequired,
    selectedItem: PropTypes.string.isRequired,
    suggestion: PropTypes.shape({
        label: PropTypes.string.isRequired,
    }).isRequired,
};

function getSuggestions(value, { showEmpty = false } = {}) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0 && !showEmpty
        ? []
        : suggestions.filter(suggestion => {
            const keep =
                count < 5 && suggestion.label.toLowerCase().includes(inputValue);
            // count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
                count += 1;
            }

            return keep;
        });
}


let areRetailersInitialized = false;
let clearIntervalId = null;

async function loadRetailers() {
    try {
        await API.get("tryabuy", '/retailers')
            .then(response => {
                let filterLookup = {};
                suggestions = [];
                let retailers = response;
                retailers.forEach(function (item, index) {
                    // console.log(item, index);
                    if (!filterLookup.hasOwnProperty(item.companySearchName)) {
                        suggestions.push({
                            label: item.companyName,
                            retailer: item
                        });
                        filterLookup[item.companySearchName] = '';
                    }
                });
                
                areRetailersInitialized = true;
            });
    } catch (err) {
        console.log(err);
    }
}


const useStyles = makeStyles(theme => ({
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
    }
}));

function IntegrationDownshift(props) {
    const classes = useStyles();
    const { setRetailer, setShowStepper, setIsRetailerSet } = props.actions;

    let loadingInProgress = false;
    if (!areRetailersInitialized) {
        clearIntervalId = setInterval(function () {
            if (!loadingInProgress && !areRetailersInitialized) {
                loadingInProgress = true;
                loadRetailers();
            }
            if (areRetailersInitialized) {
                clearInterval(clearIntervalId);
                loadingInProgress = false;
            }
        }, 500);
    }

    return (
        <div>
            <Downshift id="downshift-simple"
            onSelect={ selection => {
                // console.log("*****  SELECTED: " + selection);
                suggestions.forEach(function (item, index) {
                    if (item.label === selection) {
                        let retailer = item.retailer;
                        // console.log("retailer found: " + retailer);
                        setRetailer(retailer);
                        setIsRetailerSet(true);
                        setShowStepper(true);
                    }
                });
            }}
            >
                {({
                      getInputProps,
                      getItemProps,
                      getLabelProps,
                      getMenuProps,
                      highlightedIndex,
                      inputValue,
                      isOpen,
                      selectedItem,
                  }) => {
                    const { onBlur, onFocus, ...inputProps } = getInputProps({
                        placeholder: 'Select your company name',
                    });

                    return (
                        <div>
                            {renderInput({
                                fullWidth: true,
                                classes,
                                label: 'Retail Company Name',
                                InputLabelProps: getLabelProps({ shrink: true }),
                                InputProps: { onBlur, onFocus },
                                inputProps,
                            })}

                            <div {...getMenuProps()}>
                                {isOpen ? (
                                    <Paper square>
                                        {getSuggestions(inputValue).map((suggestion, index) =>
                                            renderSuggestion({
                                                suggestion,
                                                index,
                                                itemProps: getItemProps({ item: suggestion.label }),
                                                highlightedIndex,
                                                selectedItem,
                                            }),
                                        )}
                                    </Paper>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            </Downshift>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        signup: state.signup
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(signupActions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationDownshift);
