import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {utilStyles} from "../Constants";
import {Grid, Hidden} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Paper from '@material-ui/core/Paper';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DoneIcon from '@material-ui/icons/Done';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TButtonLink from "../widgets/TButtonLink";

const useStyles = makeStyles(theme => ({
    logoBannerXs: {
        height: '150px',
        width: 'auto',
        margin: theme.spacing(2, 1, 2, 1)
    },
    logoBannerSmAndUp: {
        height: '200px',
        width: 'auto',
        margin: theme.spacing(2, 2, 3, 2)
    },
    paperTopLevel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 2)
    },
    iconTopLevel: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    howItWorksTimeline: {
        marginTop: theme.spacing(1)
    },
    howItWorksTimelinePaper: {
        padding: '6px 16px',
    },
    howItWorksTimelineEmphasizedConnector: {
        backgroundColor: theme.palette.primary.main,
    },
    linkedInTag: {
        display: "none"
    },
    ...utilStyles(theme)
}));

export default function Home(props) {
    const classes = useStyles();
    // see: https://material-ui.com/customization/breakpoints/
    const theme = useTheme();
    // const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmAndUp = useMediaQuery(theme.breakpoints.up('sm'));

    // className={[classes.iconTopLevel, classes.center].join(' ')}

    useEffect(() => {
        document.title = 'Try a Buy';
    }, []);

    return (
        <div>
            <Banner />
            <Container maxWidth="md" className={classes.spacer1Top}>
                <ValueAdd />
                <HowItWorks />
                <ActionBar />
                <Marketing />
            </Container>
        </div>
    );

    function Banner() {
        return (
            <div align="center">
                {/*Xs*/}
                <Hidden smUp>
                    <img src="/logo/vertical-transparent.png"
                         className={classes.logoBannerXs}
                         alt="" />
                </Hidden>
                {/*Sm, Md, Lg */}
                <Hidden xsDown>
                    <img src="/logo/horizontal-transparent.png"
                         className={classes.logoBannerSmAndUp}
                         alt="" />
                </Hidden>
            </div>
        );
    }

    function ValueAdd() {
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        {/*Note: need another layer of container to provide the breakpoints above and the */}
                        {/*justify=center for the content within*/}
                        <Grid container justifyContent="center">
                            <Paper className={classes.paperTopLevel}>
                                <ShoppingCartIcon color="primary" fontSize="large" className={classes.iconTopLevel} />
                                <div className={classes.spacer1Top}></div>
                                <Typography component="h6" variant="h6" align="center">
                                    Buyers save on new items from retailers for reasonably-discounted, requested prices.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container justifyContent="center">
                            <Paper className={classes.paperTopLevel}>
                                <AttachMoneyIcon color="primary" fontSize="large" className={classes.iconTopLevel} />
                                <div className={classes.spacer1Top}></div>
                                <Typography component="h6" variant="h6" align="center">
                                    Retailers choose to sell new inventory to buyers for the requested price in an immediate sale.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    function HowItWorks() {
        return (
            <div>
                <Typography component="h5"
                            variant="h5"
                            className={classes.spacer4Top}
                            align="center">
                    How It Works
                </Typography>
                <Timeline align="alternate" className={classes.howItWorksTimeline}>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary">
                                <ArrowDownwardIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={2} className={classes.howItWorksTimelinePaper}>
                                <Typography variant={isSmAndUp ? 'h6' : 'subtitle1'} component="div">
                                    Buyers open a buy request
                                </Typography>
                                <Hidden xsDown>
                                    <Typography variant="body2" component="div" color="textSecondary">
                                        Buyers open a "buy request" with a desired item to purchase.
                                        <div className={classes.spacer1Top}></div>
                                        A discounted price is listed that the buyer agrees to pay immediately if a retailer agrees to sell the item.
                                    </Typography>
                                </Hidden>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary" variant="outlined">
                                <VisibilityIcon color="primary" />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={2} className={classes.howItWorksTimelinePaper}>
                                <Typography variant={isSmAndUp ? 'h6' : 'subtitle1'} component="div">
                                    Retailers watch for new requests
                                </Typography>
                                <Hidden xsDown>
                                    <Typography variant="body2" component="div" color="textSecondary">
                                        Retailers watch for new buy requests in the feed.
                                        <div className={classes.spacer1Top}></div>
                                        If a request falls in the retail category they sell and they can meet the buyer's specifications, they may choose to jump on the sale.
                                    </Typography>
                                </Hidden>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary">
                                <FlashOnIcon />
                            </TimelineDot>
                            <TimelineConnector className={classes.howItWorksTimelineEmphasizedConnector} />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={2} className={classes.howItWorksTimelinePaper}>
                                <Typography variant={isSmAndUp ? 'h6' : 'subtitle1'} component="div">
                                    Retailers initiate a sale
                                </Typography>
                                <Hidden xsDown>
                                    <Typography variant="body2" component="div" color="textSecondary">
                                        Retailers initiate the sale on an item that meets the buyers' specifications.
                                        <div className={classes.spacer1Top}></div>
                                        The buy request is put into pending status and the item must be shipped before charging the buyer.
                                    </Typography>
                                </Hidden>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="secondary">
                                <DoneIcon />
                            </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={2} className={classes.howItWorksTimelinePaper}>
                                <Typography variant={isSmAndUp ? 'h6' : 'subtitle1'} component="div">
                                    Item shipped and sale complete
                                </Typography>
                                <Hidden xsDown>
                                    <Typography variant="body2" component="div" color="textSecondary">
                                        Once a retailer ships an item and on its way to the buyer, they buyer's card is charged.
                                        <div className={classes.spacer1Top}></div>
                                        They buyer will be supplied with a tracking number. Any returns and post-sale handling occurs direct between the buyer and retailer.
                                    </Typography>
                                </Hidden>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </div>
        );
    }

    function ActionBar() {
        return (
            <div>
                <Grid container spacing={isSmAndUp ? 3 : 2}>
                    <Grid item xs={12} sm={6}>
                        <TButtonLink text="Learn More"
                                     to='/learn-more'
                                     color="secondary"
                                     variant='outlined'
                                     props={props}
                                     fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TButtonLink text="Browse Requests"
                                     to='/browse'
                                     color="primary"
                                     variant='outlined'
                                     props={props}
                                     fullWidth />
                    </Grid>
                </Grid>
            </div>
        );
    }

    function Marketing() {
        return (
            <div>
                {/*LinkedIn Event-Specific Pixel for "Retailer Signup Direct"*/}
                <img height="1" width="1" className={classes.linkedInTag} src="https://px.ads.linkedin.com/collect/?pid=2800402&conversionId=3552930&fmt=gif" />
            </div>
        )
    }
}






