const dev = {
    publicDomain: 'https://localhost:3000',
    stage: 'dev',
    s3: {
        REGION: "us-east-1",
        BUCKET: "tryabuy-api-dev-attachmentsbucket-p7jqtjqyzj1f"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://0wyqpmnl6d.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_X5GtQ5SLI",
        APP_CLIENT_ID: "3elhg530g1rmuhkelnbjken5hu",
        IDENTITY_POOL_ID: "us-east-1:c50bfe37-9033-4d79-898c-b47e753cabdd"
    },
    stripe: {
        publishableKey: 'pk_test_Ll4ER82qw10jTkZ0WL3LLmjG00zKbWvhYH',
        clientId: 'ca_GTsuB0An2nYckGv0qdQjPZhj52jWYkGn',
        authorizeUri: 'https://connect.stripe.com/express/oauth/authorize',
        tokenUri: 'https://connect.stripe.com/oauth/token'
    },
    social: {
        FB: "314423046179836"
    }
};

const prod = {
    publicDomain: 'https://tryabuy.com',
    stage: 'prod',
    s3: {
        REGION: "us-east-1",
        BUCKET: "tryabuy-api-prod-attachmentsbucket-1jr15l7pwptcf"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://o52b3e7nkf.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_SMrKSEvAX",
        APP_CLIENT_ID: "29np1ctdi3ak3c6v8h13n7dv7r",
        IDENTITY_POOL_ID: "us-east-1:c13b1c73-2fe2-4aeb-b666-dafd34036525"
    },
    stripe: {
        publishableKey: 'pk_live_c9go6mByA3k0khBUY2s2riHF00WOYz8XqN',
        clientId: 'ca_GTsuSi0dusOb6EA8o7JEn92IRYwyicQs',
        authorizeUri: 'https://connect.stripe.com/express/oauth/authorize',
        tokenUri: 'https://connect.stripe.com/oauth/token'
    },
    social: {
        FB: "342390366426296"
    }
};

// console.log(`REACT_APP_STAGE=${process.env.REACT_APP_STAGE}`);
// Default to dev if not set - t1
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;
// console.log(`***** CONFIG: ${JSON.stringify(config)}`);

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};
