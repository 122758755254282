import React from "react";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import {makeStyles} from "@material-ui/core";
import {utilStyles} from "../Constants";

const useStyles = makeStyles(theme => ({
    progress: {
        height: '2px'
    },
    ...utilStyles(theme)
}));

export default ({ props: cProps, text: text, ...rest }) => {
    const classes = useStyles();
    let textToDisplay = 'Loading...';
    if (text) {
        textToDisplay = text;
    }
    return (
        <div>
            <div className={classes.spacer3Top}></div>
            <Typography component="h6" variant="h6" align="center">
                {textToDisplay}
            </Typography>
            <div className={classes.spacer1Top}></div>
            <LinearProgress className={classes.progress} />
        </div>
    );
}
