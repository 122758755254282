import React, {useState} from 'react';
import config from "../config";
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LoaderButton from "./LoaderButton";
import {bindActionCreators} from "redux";
import * as signupActions from "../actions/SignupActions";
import {connect} from "react-redux";
import {API, Auth} from "aws-amplify";
import {utilStyles} from "../Constants";
import {signIn} from "../services/AccountService";
import * as accountActions from "../actions/AccountActions";
import TPageHeader from "../widgets/TPageHeader";
import BusinessIcon from "@material-ui/icons/Business";
import TButtonLink from "../widgets/TButtonLink";
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    ...utilStyles(theme)
}));


function RepresentativeSignupStepper(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
    const [isDoneWithSignup, setIsDoneWithSignup] = useState(false);
    const steps = getSteps();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [emailHelperText, setEmailHelperText] = useState("");
    const [password, setPassword] = useState("");
    const [passwordHelperText, setPasswordHelperText] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [repButtonDisabled, setRepButtonDisabled] = useState(false);
    const [confirmationCodeHelperText, setConfirmationCodeHelperText] = useState("");
    const [confirmationCodeButtonDisabled, setConfirmationCodeButtonDisabled] = useState(false);

    document.title = 'Representative Signup';

    function getSteps() {
        return ['Representative Signup', 'Confirm Email', 'Next Steps'];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return getCollectRepresentativeInfoStep();
            case 1:
                return getConfirmEmailCodeStep();
            case 2:
                return getFinishStep();
            default:
                return 'Unknown step';
        }
    }

    function isRepInfoValid() {
        return(
            firstName.length >= 2 &&
            lastName.length >= 2 &&
            email.length >= 2 &&
            password.length >= 2 &&
            password === confirmPassword
        );
    }

    function isConfirmationCodeValid() {
        return confirmationCode.length > 0;
    }

    const handleRepSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);

        setEmailHelperText("");
        setPasswordHelperText("");

        try {
            await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    'custom:userType': 'retailer-rep',
                    'custom:retailerId': props.retailer.retailerId,
                    given_name: firstName,
                    family_name: lastName
                }
            });
            setIsNextDisabled(false);
            setShowFeedbackMessage(true);
            setRepButtonDisabled(true);

        } catch (e) {
            if (e.code === "UsernameExistsException") {
                setEmailHelperText(e.message);
            } else if (e.code === "InvalidPasswordException") {
                setPasswordHelperText(e.message);
            } else {
                console.log(e.code);
            }
        }
        setIsLoading(false);
    };

    const handleConfirmationCodeSubmit = async event => {
        event.preventDefault();

        setIsLoading(true);
        setConfirmationCodeHelperText("");

        try {
            await Auth.confirmSignUp(email, confirmationCode);
            const repUser = await signIn(email, password, {
                isRegistrationInProcess: true,
                actions: props.actions
            });

            // Call endpoint to fire off email
            const repData = {
                retailer: props.retailer,
                repUser: repUser,
                endpoint: config.apiGateway.URL
            };

            await API.post("tryabuy", `/signup/retailer/representative`, {
                body: repData
            }).then(response => {
                // console.log(response);

                setIsNextDisabled(false);
                setShowFeedbackMessage(true);
                setConfirmationCodeButtonDisabled(true);
            }).catch(error => {
                console.log(error.response);
            });
        } catch (e) {
            if (e.code === "CodeMismatchException") {
                setConfirmationCodeHelperText(e.message);
            } else {
                console.log(e);
            }
        }
        setIsLoading(false);
    };


    function getCollectRepresentativeInfoStep() {
        return (
            <form onSubmit={handleRepSubmit}>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            autoComplete="firstName"
                            autoFocus
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            autoComplete="lastName"
                        />
                    </Grid>
                </Grid>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    autoComplete="email"
                    error={emailHelperText !== ""}
                    helperText={emailHelperText}
                />
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            name="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={passwordHelperText !== ""}
                            helperText={passwordHelperText}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Confirm Password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            type="password"
                            id="confirmPassword"
                            autoComplete="current-confirmPassword"
                        />
                    </Grid>
                </Grid>
                <Typography component="div" variant="caption" align="center" className={classes.spacer1TopAndBottom}>
                    By signing up, you agree to the
                    &nbsp;
                    <a href="/terms-of-service" target="_blank">
                        Terms of Service
                    </a>
                    &nbsp;and&nbsp;
                    <a href="/privacy-policy" target="_blank">
                        Privacy Policy
                    </a>
                    .
                </Typography>
                {!showFeedbackMessage &&
                    <LoaderButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        isLoading={isLoading}
                        disabled={!isRepInfoValid() || repButtonDisabled}
                        text="Signup"
                        loadingText="Registering admin..." />
                }
                {showFeedbackMessage &&
                    <Alert variant="outlined" severity="success">
                        Account created.
                    </Alert>
                }
        </form>
        );
    }

    function getConfirmEmailCodeStep() {
        return (
            <div>
                <form onSubmit={handleConfirmationCodeSubmit}>
                    <Typography component="div" variant="subtitle1" align="center">
                        Please enter the code sent to you in your email.
                    </Typography>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        margin="normal"
                        label="Confirmation Code"
                        name="confirmationCode"
                        value={confirmationCode}
                        onChange={e => setConfirmationCode(e.target.value)}
                        id="confirmationCode"
                        error={confirmationCodeHelperText !== ""}
                        helperText={confirmationCodeHelperText}
                    />
                    {!showFeedbackMessage &&
                        <LoaderButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            isLoading={isLoading}
                            disabled={!isConfirmationCodeValid() || confirmationCodeButtonDisabled}
                            text="Confirm"
                            loadingText="Confirming..." />
                    }
                    {showFeedbackMessage &&
                        <Alert variant="outlined" severity="success">
                            Confirmed.
                        </Alert>
                    }
                </form>
            </div>
        );
    }


    function getFinishStep() {
        return (
            <div>
                <Alert severity="success" className={classes.spacer1Top}>
                    Great! Your account was created.
                </Alert>
                <Typography component="div" variant="subtitle1" align="center" className={classes.spacer1Top}>
                    We sent a confirmation request to the administrator of <b>{props.retailer.companyName}</b> for Try a Buy.
                </Typography>
                <Typography component="div" variant="subtitle1" align="center" className={classes.spacer1TopAndBottom}>
                    You should hear back in 1-2 business days.
                </Typography>
                <TButtonLink text="Browse Buy Requests"
                             to='/browse'
                             color="primary"
                             variant='outlined'
                             props={props}
                             fullWidth />
            </div>
        );
    }

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setIsNextDisabled(true);
        setShowFeedbackMessage(false);

        // minus 2 because hook doesn't take affect until after function call
        if (activeStep === steps.length - 2) {
            setIsDoneWithSignup(true);
        }
    };

    // const handleBack = () => {
    //     setActiveStep(prevActiveStep => prevActiveStep - 1);
    //
    //     setIsNextDisabled(false);
    // };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div>
            <TPageHeader icon={BusinessIcon}
                         title="Retailer Representative Signup"
                            subtitle="Your company is already registered." />
            <Typography component="div" variant="subtitle1" align="center">
                Let's get you signed up as a representative of {props.retailer.companyName}
            </Typography>
            <Stepper activeStep={activeStep} orientation="vertical" className={classes.spacer2Top}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <Typography component={'span'}>{getStepContent(index)}</Typography>
                                <div className={classes.actionsContainer}>
                                    <div>
                                        {!isDoneWithSignup &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={isNextDisabled}
                                            onClick={handleNext}
                                            className={[classes.button, classes.pullRight].join(" ")}
                                            align="center"
                                        >
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                        }
                                        {isDoneWithSignup &&
                                        <TButtonLink text="Checkout Listings"
                                                     to='/browse'
                                                     color="primary"
                                                     variant='outlined'
                                                     props={props}
                                                     fullWidth />
                                        }
                                    </div>
                                </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                        Reset
                    </Button>
                </Paper>
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        retailer: state.signup.retailer,
        showStepper: state.signup.showStepper
    };
};

const mapDispatchToProps = (dispatch) => {
    const actions = { ...signupActions, ...accountActions };
    return {
        actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RepresentativeSignupStepper);
