import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {EventType, utilStyles} from "../Constants";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {API} from "aws-amplify";
import {getUser, isBuyer, isRetailer} from "../services/AccountService";
import LinearProgress from "@material-ui/core/LinearProgress";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
    ...utilStyles(theme)
}));


function SubscriptionsPage(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);
    const [buyRequestCreate, setBuyRequestCreate] = useState(false);


    useEffect(() => {
        const user = getUser();
        API.get("tryabuy", `/user/${user.userId}/account/subscription`).then(subscriptions => {
            // console.log(`subscriptions = ${JSON.stringify(subscriptions)}`);
            subscriptions.forEach(function (subscription) {
                if (subscription.eventKey.startsWith(EventType.BUY_REQUEST_CREATE.key)) {
                    setBuyRequestCreate(true);
                }
            });
            document.title = 'Subscriptions';
            setIsLoaded(true);
        }).catch(error => {
            console.log("**** error: " + error.response);
            setIsLoaded(true);
        });
    }, []);


    // const handleChange = (event) => {
    //     // setState({ ...state, [event.target.name]: event.target.checked });
    // };

    // BUY_REQUEST_CREATE


    const handleChange = async event => {
        event.preventDefault();

        setIsRequestBeingMade(true);
        let body = {
            onOffFlag: event.target.checked
        };
        switch (event.target.name) {
            case "buyRequestCreate":
                body.eventTypeKey = EventType.BUY_REQUEST_CREATE.key;
                body.retailerId = props.retailer.retailerId;
                setBuyRequestCreate(event.target.checked);
                break;
        }

        const user = getUser();
        API.post("tryabuy", `/user/${user.userId}/account/subscription`, {
            body: body
        }).then(response => {
            // console.log(response);
            setIsRequestBeingMade(false);
        }).catch(error => {
            console.error(error);
            setIsRequestBeingMade(false);
        });
    };

    function renderContent() {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paperCentered}>
                    <Avatar className={classes.avatar}>
                        <NotificationsIcon />
                    </Avatar>
                    <Typography component="h5" variant="h5">
                        Subscriptions
                    </Typography>
                    <div className={classes.spacer4Top}></div>
                    {isBuyer() &&
                        renderBuyerSubscriptions()
                    }
                    {isRetailer() &&
                        renderRetailerSubscriptions()
                    }
                </div>
            </Container>
        );
    }

    function renderBuyerSubscriptions() {
        return (
            <div>
                No subscriptions as of now.
            </div>
        );
    }

    function renderRetailerSubscriptions() {
        return (
            <div>
                <FormControlLabel
                    control={
                        <Switch
                            checked={buyRequestCreate}
                            onChange={e => handleChange(e)}
                            name="buyRequestCreate"
                            color="primary"
                            disabled={isRequestBeingMade}
                        />
                    }
                    label="Notifications for buy requests created in categories your company sells."
                />
            </div>
        );
    }

    function renderProgress() {
        return (
            <div>
                <div className={classes.spacer4Top}></div>
                <Typography component="h5" variant="h5" align="center">
                    Loading...
                </Typography>
                <div className={classes.spacer3}></div>
                <LinearProgress />
            </div>
        );
    }

    return (
        <Container component="main" maxWidth="sm">
            <div className={classes.spacer3Top}>
                { isLoaded
                    ?
                    renderContent()
                    :
                        <div className={classes.spacer5Top}>{renderProgress()}
                        </div>
                }
            </div>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        retailer: state.account.retailer
    };
};

export default connect(mapStateToProps, null)(SubscriptionsPage);





