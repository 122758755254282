import {ADMIN_USER_IS_SET, TMP_OBJECT_IS_SET} from '../actions/TmpActions';
import {defaultState} from "../defaultState";

export function reducer(state = defaultState.tmp, action = {}) {
  switch (action.type) {
    case  ADMIN_USER_IS_SET: {
      return { ...state, adminUser: action.data }
    }
    case  TMP_OBJECT_IS_SET: {
      return { ...state, tmpObject: action.data }
    }
    default: return state;
  }
}