import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {utilStyles} from "../Constants";
import Grid from "@material-ui/core/Grid";
import TLink from "../widgets/TLink";
import {Container, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    margins: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(3)
    },
    ...utilStyles(theme)
}));

export default function Footer(props) {
    const classes = useStyles();

    return (
        <Container maxWidth="xs" className={classes.margins}>
            <Grid container justifyContent="center" spacing={1}>
                <Grid item>
                    <span className={classes.spacer1Right}>
                        <TLink props={props} to="/terms-of-service" text="Terms of Service" />
                    </span>
                    &middot;
                    <span className={[classes.spacer1Left, classes.spacer1Right].join(' ')}>
                        <TLink props={props} to="/privacy-policy" text="Privacy Policy" />
                    </span>
                    &middot;
                    <span className={classes.spacer1Left}>
                        <TLink props={props} to="/contact-us" text="Contact Us" />
                    </span>
                </Grid>
                <Grid item>
                    <Typography variant="caption" component="div">
                        &copy; 2021 Try a Buy All Rights Reserved
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}




