import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {utilStyles} from '../Constants';
import {getUser, isRetailer} from "../services/AccountService";
import * as tmpActions from "../actions/TmpActions";
import {bindActionCreators} from "redux";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import LoaderButton from "../components/LoaderButton";
import {API} from "aws-amplify";
import TProgress from "../widgets/TProgress";
import EmailSharpIcon from '@material-ui/icons/EmailSharp';
import TPageHeader from "../widgets/TPageHeader";
import Alert from "@material-ui/lab/Alert";


const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

function BuyRequestMessagePage(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [message, setMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [buyRequest] = useState(props.tmpObject);
    const [retailer] = useState(props.retailer);
    const { setTmpObject } = props.actions;
    // console.log(`tmpObject = ${JSON.stringify(props.tmpObject)}`);

    useEffect( () => {
        setTmpObject(null);
        (async () => {
            // validate page
            const user = getUser();
            // console.log(JSON.stringify(retailer));
            if (!buyRequest || !user || !isRetailer(user)) {
                props.history.push('/access-denied');
            } else {
                setIsLoaded(true);
            }
        })();
        document.title = 'Message Buyer';
    }, []);


    function validateForm() {
        return message.length > 0 ;
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setIsSubmitting(true);
        const user = getUser();
        let body = {
            buyRequestId: buyRequest.buyRequestId,
            senderUserId: user.userId,
            senderEmail: user.email,
            senderFirstName: user.firstName,
            retailerId: retailer.retailerId,
            retailerName: retailer.companyName,
            message: message
        };
        API.post("tryabuy", `/buy-request/${buyRequest.buyRequestId}/message`, {
            body: body
        }).then(response => {
            // console.log(response);
            setIsSubmitting(false);
            setIsSubmitted(true);
        }).catch(error => {
            console.error(error);
            setIsSubmitting(false);
        });
    };

    function renderMessageArea() {
        return (
            <div>
                <TPageHeader icon={EmailSharpIcon} title="Message Buyer" />
                <div className={classes.spacer3Top}></div>
                <form noValidate onSubmit={handleSubmit}>
                    {!isSubmitted ?
                        <span>
                            <Typography component="h6" variant="h6" align='center'>
                                {buyRequest.title}
                            </Typography>
                            <Typography component="div" variant="body2" className={classes.spacer2Top}>
                                Send a message to <b>{buyRequest.displayName}</b> regarding this item.
                            </Typography>
                            <TextField
                                margin="normal"
                                label="Message"
                                multiline
                                fullWidth
                                name="message"
                                rows={2}
                                rowsMax={4}
                                value={message}
                                inputProps={{ maxLength: 500 }}
                                onChange={e => setMessage(e.target.value)}
                            />
                            <LoaderButton
                                color="primary"
                                size="large"
                                type="submit"
                                isLoading={isSubmitting}
                                disabled={!validateForm()}
                                text="Submit"
                                loadingText="Submitting in..."
                                fullWidth
                            />
                        </span>
                        :
                        <Alert severity="success" className={classes.spacer2Top}>
                            Thank you. This message has been sent.
                        </Alert>
                    }
                </form>
            </div>
        );
    }

    return (
        <Container maxWidth="xs">
            { isLoaded
                    ? renderMessageArea()
                    : <TProgress />
            }
        </Container>
    );
}



const mapStateToProps = state => {
    return {
        retailer: state.account.retailer,
        tmpObject: state.tmp.tmpObject
    };
};

const mapDispatchToProps = (dispatch) => {
    // const actions = { ...signupActions, ...accountActions, ...tmpActions };
    const actions = { ...tmpActions };
    return {
        actions: bindActionCreators(actions,  dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyRequestMessagePage);



