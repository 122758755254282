
export const defaultState = {
  account: {
    retailer: {}
  },
  signup: {
    retailer: {
      retailerId: "",
      companyName: "",
      domainName: "",
      contactPhone: "",
      adminUserId: "",
      representatives: []
    },
    isRetailerSet: false,
    showStepper: false
  },
  tmp: {
    newRetailerId: ""
  }
};
