import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {utilStyles} from '../Constants';
import {API} from "aws-amplify";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LoaderButton from "../components/LoaderButton";
import {getUser} from "../services/AccountService";
import TProgress from "../widgets/TProgress";
import TPageHeader from "../widgets/TPageHeader";
import StopIcon from '@material-ui/icons/Stop';
import FlashOnSharpIcon from '@material-ui/icons/FlashOnSharp';
import {Alert, AlertTitle} from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

export default function ConfirmSale(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [buyRequest, setBuyRequest] = useState(null);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        const buyRequestId = props.match.params.buyRequestId;
        API.get("tryabuy", '/buy-request/' + buyRequestId).then(response => {
            // console.log("*** found buy request: " + response);
            setBuyRequest(response);
            document.title = 'Initiate Sale';
            setIsLoading(false);
        }).catch(error => {
            console.log("**** error: " + error.response);
            setIsLoading(false);
        });
    }, []);

    async function handleConfirmSale() {
        setIsLoading(true);
        const user = await getUser();
        let body = {
            buyRequestUserId: buyRequest.userId,
            buyRequestId: buyRequest.buyRequestId,
            buyRequestCreateTimestamp: buyRequest.createTimestamp,
            buyRequestTitle: buyRequest.title,
            retailerId: user.retailerId,
            retailerUserId: user.userId
        };
        API.post("tryabuy", `/buy-request/${buyRequest.buyRequestId}/sale/initiate`, {
            body: body
        }).then(response => {
            console.log(response);

            setIsLoading(false);
            setIsComplete(true);
        }).catch(error => {
            console.log(error.response);
            setIsLoading(false);
        });
    }

    return (
        <Container maxWidth="sm">
        { isLoading
            ? <TProgress />
            : renderBody()
        }
        </Container>
    );

    function renderBody() {
        return (
            <div>
                <TPageHeader icon={FlashOnSharpIcon} title="Initiate Sale" />
                <div className={classes.spacer3}></div>
                {!isComplete
                    ?
                    <div>
                    <Typography component="div" variant="subtitle1" align="center">
                        You are about to sell "<b>{buyRequest.title}</b>" to {buyRequest.displayName}.
                    </Typography>
                    <List dense={true}>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon>
                                <StopIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="By initiating the sale below, you are agreeing to follow-through on selling the item to this individual." />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon>
                                <StopIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="When you initiate, this will put the buy request to a pending status such that no one else can sell the item." />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon>
                                <StopIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="You have one week from confirming to shipping the item. Anything beyond that week will cancel the sale." />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon>
                                <StopIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography component="span" variant="body2">
                                    The customer will not charged until you supply the shipping tracking number and amount on the <b>Complete Sale</b> screen.
                                </Typography>
                            } />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ListItemIcon>
                                <StopIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="You will be paid out within 2-4 business days after shipping." />
                        </ListItem>
                    </List>
                    <LoaderButton
                        color="primary"
                        type="submit"
                        isLoading={isLoading}
                        onClick={handleConfirmSale}
                        text="Initiate Sale"
                        loadingText="Confirming..."
                        fullWidth
                    />
                    </div>
                :
                    getFinishStep()
                }
            </div>
        );
    }

    function getFinishStep() {
        return (
            <React.Fragment>
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    <Typography component="span" variant="subtitle1">
                        You have initiated this sale!
                    </Typography>
                </Alert>
                <Typography variant="body2" component="div" className={classes.spacer3Top}>
                    Remember to ship this as soon as possible.
                    Then return to the <b>Complete Sale</b> screen to supply the tracking number and shipping cost.
                    <br /><br />
                    After that, the buyer will be charged and you will be paid out in 2-4 business days.
                </Typography>
            </React.Fragment>
        );
    }
}


