import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LoaderButton from "../components/LoaderButton";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import {API} from "aws-amplify";
import {utilStyles} from "../Constants";
import TPageHeader from "../widgets/TPageHeader";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));


export default function ContactUs(props) {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        document.title = 'Contact Us';
    }, []);

    function validateForm() {
        return email.length > 0 && name.length > 0 && message.length > 0;
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);

        let body = {
            name: name,
            email: email,
            message: message
        };
        API.post("tryabuy", `/contact-us`, {
            body: body
        }).then(response => {
            // console.log(response);
            setIsLoading(false);
            setIsSubmitted(true);
        }).catch(error => {
            console.error(error);
            setIsLoading(false);
        });
    };

    return (
        <Container maxWidth="xs">
            <TPageHeader icon={ContactSupportIcon} title="Contact Us" />
            <div className={classes.spacer3Top}>
                <form noValidate onSubmit={handleSubmit}>
                    {!isSubmitted ?
                        <span>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                label="Name"
                                type="name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Email"
                                name="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                autoComplete="email"
                            />
                            <TextField
                                margin="normal"
                                label="Message"
                                multiline
                                fullWidth
                                name="message"
                                rows={2}
                                rowsMax={4}
                                value={message}
                                inputProps={{ maxLength: 1000 }}
                                onChange={e => setMessage(e.target.value)}
                            />
                            <div className={classes.spacer1Top}></div>
                            <LoaderButton
                                color="primary"
                                size="large"
                                type="submit"
                                isLoading={isLoading}
                                disabled={!validateForm()}
                                text="Submit"
                                loadingText="Submitting in..."
                                fullWidth
                            />
                        </span>
                    :
                        <Alert severity="success" className={classes.spacer1Top}>
                            Thank you. We received your submission.
                        </Alert>
                    }
                </form>
            </div>
        </Container>
    );
}






