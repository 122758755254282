

export const RETAILER_IS_SET = "tryabuy/signup/RETAILER_IS_SET";
export const SHOW_STEPPER_IS_SET = "tryabuy/signup/SHOW_STEPPER_IS_SET";
export const RETAILER_SET_IS_SET = "tryabuy/signup/RETAILER_SET_IS_SET";

export function setRetailer(retailer) {
  return {
    type: RETAILER_IS_SET,
    data: retailer
  }
}

export function setShowStepper(showStepper) {
  return {
    type: SHOW_STEPPER_IS_SET,
    data: showStepper
  }
}

export function setIsRetailerSet(isRetailerSet) {
  return {
    type: RETAILER_SET_IS_SET,
    data: isRetailerSet
  }
}

