import {applyMiddleware, combineReducers, createStore} from "redux";
import {reducer as accountReducer} from "./reducers/AccountReducers";
import {reducer as signupReducer} from "./reducers/SignupReducers";
import {reducer as tmpReducer} from "./reducers/TmpReducers";
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from "redux-thunk";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from 'history';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  account: accountReducer,
  signup: signupReducer,
  tmp: tmpReducer
});

export const history = createBrowserHistory();
const historyReducer = rootReducer(history);

export default function configureStore(preloadedState) {
  const store =  createStore(
                    historyReducer,
                    preloadedState,
                    composeWithDevTools(
                        applyMiddleware(
                            routerMiddleware(history),
                            thunk
                        )));
  return { store };
}



/*
 * PERSIST REDUCER BELOW
 *
 * Removed because the blacklisting was preventing all redux in-memory storing from happening.
 * Removed because nonessential.
 */
/*
const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  account: accountReducer,
  signup: signupReducer,
  tmp: tmpReducer
});

const persistConfig = {
  key: 'root',
  storage: localStorage,
  // blacklist: ['router', 'signup', 'account']
  blacklist: ['router', 'signup']
};


export const history = createBrowserHistory();
const historyReducer = rootReducer(history);
export const persistedReducer = persistReducer(persistConfig, historyReducer);

export default function configureStore(preloadedState) {
  const store =  createStore(
      persistedReducer,
      preloadedState,
      composeWithDevTools(
          applyMiddleware(
              routerMiddleware(history),
              thunk
          )));
  const persistor = persistStore(store);
  return { store, persistor };
}
*/
