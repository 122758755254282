import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LoaderButton from "../components/LoaderButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {Auth} from "aws-amplify";
import {utilStyles} from "../Constants";
import Alert from '@material-ui/lab/Alert';
import TPageHeader from "../widgets/TPageHeader";

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

export default function ChangePassword(props) {
    const classes = useStyles();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        document.title = 'Change Password';
    }, []);

    function validateForm() {
        return oldPassword.length > 0 &&
            newPassword.length >= 6 &&
            newPasswordConfirm.length >= 6 &&
            newPassword === newPasswordConfirm;
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        setErrorMessage(undefined);

        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(
                currentUser,
                oldPassword,
                newPassword
            );
            setIsSubmitted(true);
        } catch (e) {
            console.error(e);
            setErrorMessage(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="xs">
            <TPageHeader icon={VisibilityIcon} title="Change Password" />
            <form noValidate onSubmit={handleSubmit}>
                {!isSubmitted ?
                    <span>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="oldPassword"
                            value={oldPassword}
                            onChange={e => setOldPassword(e.target.value)}
                            label="Old Password"
                            type="password"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="New Password"
                            name="newPassword"
                            type="password"
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                            autoComplete="newPassword"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="New Password Confirm"
                            name="newPasswordConfirm"
                            type="password"
                            value={newPasswordConfirm}
                            onChange={e => setNewPasswordConfirm(e.target.value)}
                            autoComplete="newPasswordConfirm"
                        />
                        <LoaderButton
                            color="primary"
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                            text="Submit"
                            loadingText="Submitting..."
                            fullWidth
                        />
                    </span>
                :
                    <Alert severity="error">
                        Password changed.
                    </Alert>
                }
                {errorMessage ?
                    <Alert severity="error">{errorMessage}</Alert>
                    :
                    ""
                }
            </form>
        </Container>
    );
}






