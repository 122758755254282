import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {Category, utilStyles} from '../Constants';
import BuyRequestCard from "../components/BuyRequestCard";
import {connect} from "react-redux";
import {API} from "aws-amplify";
import {getUser} from "../services/AccountService";
import Container from "@material-ui/core/Container";
import TProgress from "../widgets/TProgress";
import Grid from "@material-ui/core/Grid";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {Alert} from '@material-ui/lab';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

function BuyRequestsPage(props) {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [buyRequests, setBuyRequests] = useState(null);
    const [user, setUser] = useState(null);
    const ALL_CATEGORIES = "all";
    const [categoryKey, setCategoryKey] = useState(ALL_CATEGORIES);

    useEffect(() => {
        const queryString = props.location.search;
        const params = new URLSearchParams(queryString);
        let categoryKey = params.get("c") || undefined;
        if (!categoryKey) {
            categoryKey = ALL_CATEGORIES;
        }
        setCategoryKey(categoryKey);
        loadBuyRequests(categoryKey);
    }, []);

    const handleCategoryChange = async (newCategoryKey) => {
        let url = `${props.history.location.pathname}`;
        if (newCategoryKey !== ALL_CATEGORIES) {
            url += `?c=${newCategoryKey}`;
        }
        props.history.push(`${url}`);
    };

    async function loadBuyRequests(categoryKey) {
        setIsLoaded(false);
        setUser(getUser());
        let apiUrl = '/buy-requests';
        if (categoryKey !== ALL_CATEGORIES) {
            apiUrl += `?categoryKey=${categoryKey}`;
        }

        API.get("tryabuy", apiUrl).then(response => {
            setBuyRequests(response);
            setIsLoaded(true);
            document.title = 'Browse Buy Requests';
            window.scrollTo(0, 0)
        }).catch(error => {
            console.log("**** error: " + error.response);
        });
    }

    function getSkeletons() {
        let retval = [];
        let i = 0;
        for (; i < 5; i++) {
            retval.push(<BuyRequestCard key={i} />);
        }
        return retval;
    }

    return (
        <Container maxWidth="sm" className={classes.spacer3Top}>
        { isLoaded
        ?
            <Content />
        :
            <div>
                <div className={classes.spacer3Top}></div>
                <TProgress />
                <div className={classes.spacer3Top}></div>
                {getSkeletons()}
            </div>
        }
        </Container>
    );

    function Content() {
        return (
            <div>
                <Grid item>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={categoryKey}
                            onChange={e => handleCategoryChange(e.target.value)}
                            label="Category"
                            autoWidth={true}
                        >
                            <MenuItem value={ALL_CATEGORIES}>
                                All
                            </MenuItem>
                            {Category.values().map(function(category) {
                                return (
                                    <MenuItem value={category.key} key={category.key}>
                                        {category.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    {buyRequests.length >= 1 ?
                        buyRequests.map(function (buyRequest) {
                            const keyVal = buyRequest.buyRequestId;
                            return (
                                <div key={keyVal}>
                                    <BuyRequestCard buyRequest={buyRequest} isLoaded={isLoaded}
                                                    user={user} {...props} />
                                </div>
                            )
                        })
                        :
                        <div className={classes.spacer2Top}>
                            <Alert severity="warning">
                                No items in this category
                            </Alert>
                            <Button onClick={() => {handleCategoryChange(ALL_CATEGORIES)}}
                                    color="primary"
                                    variant="outlined"
                                    className={classes.spacer2Top}
                                    fullWidth>
                                Reset Search
                            </Button>
                        </div>
                    }
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        retailer: state.account.retailer
    };
};

export default connect(mapStateToProps, null)(BuyRequestsPage);
