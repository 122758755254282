import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {blue, green, orange, red} from '@material-ui/core/colors';
import {connect} from "react-redux";
import {BuyRequestStatus, utilStyles} from '../Constants';
import {API, Storage} from "aws-amplify";
import {getUser, isBuyer, isRetailer} from "../services/AccountService";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Link from "@material-ui/core/Link";
import moment from "moment";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import Grid from "@material-ui/core/Grid";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import LoaderButton from "../components/LoaderButton";
import TLink from "../widgets/TLink";
import TIcon from "../widgets/TIcon";
import Hidden from '@material-ui/core/Hidden';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TPageHeader from "../widgets/TPageHeader";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TProgress from "../widgets/TProgress";
import TIconLink from "../widgets/TIconLink";
import TButtonLink from "../widgets/TButtonLink";
import PostAddIcon from '@material-ui/icons/PostAdd';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = makeStyles(theme => ({
    orange: {
        // color: theme.palette.getContrastText(orange[500]),
        color: '#fff',
        backgroundColor: orange[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    blue: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
    },
    red: {
        color: '#fff',
        backgroundColor: red[500],
    },
    ...utilStyles(theme)
}));

function BuyRequestListingPage(props) {

    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [buyRequests, setBuyRequests] = useState(null);
    const [user, setUser] = useState(null);
    const [showDeleteFlags, setShowDeleteFlags] = useState({});
    const [isDeleting, setIsDeleting] = useState(false);
    const [repostOccurringFlags, setRepostOccurringFlags] = useState({});
    const [showRepostSuccessMessage, setShowRepostSuccessMessage] = useState(false);

    useEffect(() => {
        loadBuyRequests();
    }, []);

    async function loadBuyRequests() {
        const user = getUser();
        setUser(user);
        let url = '/buy-requests/list?';
        if (isBuyer(user)) {
            url += `userId=${user.userId}`;
        } else if (isRetailer(user)) {
            url += `retailerId=${user.retailerId}`;
        }

        API.get("tryabuy", url).then(buyRequests => {
            const initialDeleteFlags = {};
            const initialRepostOccurringFlags = {};
            buyRequests.forEach(function(buyRequest) {
                initialDeleteFlags[buyRequest.buyRequestId] = false;
                initialRepostOccurringFlags[buyRequest.buyRequestId] = false;
            });
            setShowDeleteFlags(initialDeleteFlags);
            setRepostOccurringFlags(initialRepostOccurringFlags);

            setBuyRequests(buyRequests);
            document.title = 'My Buy Requests';
            setIsLoaded(true);
        }).catch(error => {
            console.log("**** error: " + error.response);
        });
    }

    const handleDelete = async (event, buyRequest) => {
        event.preventDefault();
        setIsDeleting(true);

        const buyRequestId = buyRequest.buyRequestId;
        const deleteBuyRequest = async () => {
            await API.del("tryabuy", `/buy-request/${buyRequestId}`, {
                body: {
                    createTimestamp: buyRequest.createTimestamp
                }
            }).then(buyRequest => {
                // console.log(buyRequest);
                const newBuyRequests = [];
                buyRequests.forEach(function(existingBuyRequest) {
                    if (existingBuyRequest.buyRequestId !== buyRequestId) {
                        newBuyRequests.push(existingBuyRequest);
                    }
                });
                setBuyRequests(newBuyRequests);
                setIsDeleting(false);
            }).catch(error => {
                console.log(error.response);
                setIsDeleting(false);
            });
        };

        if (buyRequest.images.length === 0) {
            deleteBuyRequest();
        } else {
            let imageCountToDelete = buyRequest.images.length;
            buyRequest.images.forEach(function (image) {
                const key = `buy-requests/${buyRequestId}/${image.fileName}`;
                Storage.remove(key)
                    .then(result => {
                            // console.log('deleted ' + JSON.stringify(image.fileName));
                            imageCountToDelete--;
                            if (imageCountToDelete === 0) {
                                deleteBuyRequest();
                            }
                        }
                    )
                    .catch(err => console.log(err));
            });
        }

    };

    const handleRepost = async (event, buyRequest) => {
        event.preventDefault();
        setRepostOccurringFlagValue(buyRequest.buyRequestId, true);
        const buyRequestId = buyRequest.buyRequestId;
        await API.post("tryabuy", `/buy-request/${buyRequestId}/repost`, {
            body: {
                createTimestamp: buyRequest.createTimestamp,
                category: buyRequest.category
            }
        }).then(response => {
            const activeBuyRequest = response;
            console.log(buyRequest);

            const newBuyRequests = [...buyRequests];
            buyRequests.forEach(function (existingBuyRequest, idx) {
                if (existingBuyRequest.buyRequestId === buyRequestId) {
                    newBuyRequests[idx] = activeBuyRequest;
                }
            });
            setBuyRequests(newBuyRequests);
            setShowRepostSuccessMessage(true);
            setRepostOccurringFlagValue(buyRequest.buyRequestId, true);
        }).catch(error => {
            console.log(error.response);
        });
    };

    function setRepostOccurringFlagValue(buyRequestId, val) {
        const newFlags = {...repostOccurringFlags};
        newFlags[buyRequestId] = val;
        setRepostOccurringFlags(newFlags);
    }


    function getFormattedDate(unixSeconds) {
        return moment(unixSeconds).format('MMMM Do YYYY');
    }

    function renderList() {
        return (
            <Container maxWidth="sm">
                <TPageHeader icon={ShoppingCartIcon} title="Buy Requests" />
                <div className={classes.spacer2Top}></div>
                { isRetailer(user) && props.retailer &&
                    <div>
                        <Typography variant="subtitle1" component="div" align="center">
                            Includes all buy requests for <span className={classes.bold}>{props.retailer.companyName}</span>
                        </Typography>
                    </div>
                }

                {/*<Hidden smUp>*/}
                {/*    <div className={classes.spacer2TopAndBottom}></div>*/}
                {/*    <Alert severity="warning">*/}
                {/*            Please use a device with a larger screen size to modify buy requests.*/}
                {/*    </Alert>*/}
                {/*</Hidden>*/}

                <div className={classes.spacer2Top}></div>
                <Card>
                    <CardContent>
                {
                    buyRequests.length >= 1
                    ?
                        buyRequests.map(function(buyRequest, index) {
                            const keyVal = buyRequest.buyRequestId;
                            return (
                                <div key={keyVal}>
                                    {renderBuyRequest(buyRequest, {
                                        isLastElement: (index+1) == buyRequests.length
                                    })}
                                </div>
                            )
                        }):
                        <Typography component="div" variant="subtitle1" align="center">
                            You have no buy requests at this moment.
                        </Typography>
                }
                    </CardContent>
                </Card>
            </Container>
        );
    }

    function renderBuyRequest(buyRequest, config) {
        const brViewLink = `/buy-request/${buyRequest.buyRequestId}`;
        const statusInfo = getStatusInformation(buyRequest);
        return (
            <span>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={statusInfo.iconClass}>
                            {statusInfo.icon}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <TLink props={props} to={brViewLink} text={buyRequest.title} />
                        }
                        secondary={
                            <span>
                                <span className={statusInfo.statusClass}>{BuyRequestStatus.toLabel(buyRequest.status)}</span>
                                {statusInfo.secondaryTextAddon}
                            </span>
                        }
                    />

                    {/*Desktop and Tablet Renderings*/}
                    <Hidden xsDown>
                        <ListItemSecondaryAction>
                        { buyRequest.status === BuyRequestStatus.EXPIRED.key
                        &&
                        <LoaderButton
                            variant="outlined"
                            size="small"
                            color="secondary"
                            onClick={e => handleRepost(e, buyRequest)}
                            isLoading={repostOccurringFlags[buyRequest.buyRequestId]}
                            text="Repost"
                            loadingText="Reposting" />
                        }
                        {buyRequest.status === BuyRequestStatus.ACTIVE.key &&
                                <IconButton aria-label="edit">
                                    <TIconLink props={props}
                                           to={`/buy-request/${buyRequest.buyRequestId}/edit`}
                                           text="Edit"
                                            icon={EditIcon} />
                                </IconButton>
                        }
                        {(buyRequest.status === BuyRequestStatus.ACTIVE.key ||
                            buyRequest.status === BuyRequestStatus.EXPIRED.key)
                        &&
                                <IconButton aria-label="delete" onClick={e => {
                                    const newFlags = { ...showDeleteFlags };
                                    newFlags[buyRequest.buyRequestId] = true;
                                    setShowDeleteFlags(newFlags)
                                }}>
                                    <Link>
                                        <TIcon component={DeleteIcon} text={"Delete"} />
                                    </Link>
                                </IconButton>
                        }

                        { (buyRequest.status === BuyRequestStatus.PENDING.key ||
                            buyRequest.status === BuyRequestStatus.COMPLETE.key)
                        &&
                        <TButtonLink text="View Order"
                                     to={`/order/${buyRequest.orderNumber}`}
                                     variant='outlined'
                                     size="small"
                                     color="primary"
                                     props={props} />
                        }
                        </ListItemSecondaryAction>
                    </Hidden>
                </ListItem>

                {/*XSmall / Mobile Rendering*/}
                <Hidden smUp>
                    <Grid container
                          justifyContent="flex-end"
                          spacing={2}
                          className={classes.spacer1Bottom}>
                        { buyRequest.status === BuyRequestStatus.EXPIRED.key
                        &&
                        <Grid item>
                            <LoaderButton
                                variant="outlined"
                                size="medium"
                                color="secondary"
                                onClick={e => handleRepost(e, buyRequest)}
                                isLoading={repostOccurringFlags[buyRequest.buyRequestId]}
                                text="Repost"
                                loadingText="Reposting"
                                startIcon={<PostAddIcon />} />
                        </Grid>
                        }
                        {buyRequest.status === BuyRequestStatus.ACTIVE.key &&
                        <Grid item>
                                <TButtonLink text="Edit"
                                    to={`/buy-request/${buyRequest.buyRequestId}/edit`}
                                    variant='outlined'
                                    size="medium"
                                    color="primary"
                                    props={props}
                                    startIcon={<EditOutlinedIcon />}
                                />
                        </Grid>
                        }
                        {(buyRequest.status === BuyRequestStatus.ACTIVE.key ||
                            buyRequest.status === BuyRequestStatus.EXPIRED.key)
                        &&
                        <Grid item>
                            <TButtonLink text="Delete"
                                         to={`/buy-request/${buyRequest.buyRequestId}/edit`}
                                         variant='outlined'
                                         size="medium"
                                         color="primary"
                                         props={props}
                                         startIcon={<DeleteOutlinedIcon />}
                                         onClick={e => {
                                            const newFlags = { ...showDeleteFlags };
                                            newFlags[buyRequest.buyRequestId] = true;
                                            setShowDeleteFlags(newFlags)
                                            }}
                            />
                        </Grid>
                        }
                        { (buyRequest.status === BuyRequestStatus.PENDING.key ||
                            buyRequest.status === BuyRequestStatus.COMPLETE.key)
                        &&
                        <Grid item xs={10}>
                            <TButtonLink text="View Order"
                                         to={`/order/${buyRequest.orderNumber}`}
                                         variant='outlined'
                                         size="medium"
                                         color="primary"
                                         fullWidth={true}
                                         startIcon={<ShoppingCartOutlinedIcon />}
                                         props={props} />
                        </Grid>
                        }
                        </Grid>

                    </Hidden>
                {!config.isLastElement &&
                    <Divider variant="inset" component="li" />
                }
            </List>
                <Collapse in={showDeleteFlags[buyRequest.buyRequestId]}>
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item>
                            Delete buy request?
                        </Grid>
                        <Grid item>
                            <div className={classes.pullRight}>
                                <LoaderButton
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    onClick={e => handleDelete(e, buyRequest)}
                                    isLoading={isDeleting}
                                    text="Delete"
                                    loadingText="Deleting" />
                            </div>
                        </Grid>
                        <Grid item>
                            <Button
                                size="small"
                                onClick={e => {
                                const newFlags = { ...showDeleteFlags };
                                newFlags[buyRequest.buyRequestId] = false;
                                setShowDeleteFlags(newFlags)
                            }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Collapse>
            </span>
        );
    }

    function getStatusInformation(buyRequest) {
        let retval = {};
        switch (buyRequest.status) {
            case BuyRequestStatus.ACTIVE.key:
                retval.icon = <FlashOnIcon />;
                retval.iconClass = classes.green;
                retval.statusClass = [classes.greenMessage].join(' ');
                retval.secondaryTextAddon = `, expires ${getFormattedDate(buyRequest.expirationTimestamp)}`;
                break;
            case BuyRequestStatus.PENDING.key:
                retval.icon = <AutorenewIcon />;
                retval.iconClass = classes.orange;
                retval.statusClass = [classes.orangeMessage].join(' ');
                retval.secondaryTextAddon = ` as of ${getFormattedDate(buyRequest.pendingTimestamp)}`;
                break;
            case BuyRequestStatus.COMPLETE.key:
                retval.icon = <DoneIcon />;
                retval.iconClass = classes.blue;
                retval.statusClass = [classes.blueMessage].join(' ');
                retval.secondaryTextAddon = ` as of ${getFormattedDate(buyRequest.completeTimestamp)}`;
                break;
            case BuyRequestStatus.EXPIRED.key:
                retval.icon = <CloseIcon />;
                retval.iconClass = classes.red;
                retval.statusClass = [classes.redMessage].join(' ');
                retval.secondaryTextAddon = ` on ${getFormattedDate(buyRequest.expirationTimestamp)}`;
                break;
            // TODO: Add image avatar if image is supplied
            default:
                return 'Unknown status';
        }
        return retval;
    }

    function renderSnackbarMessages() {
        return (
            <div>
                <Snackbar open={showRepostSuccessMessage} autoHideDuration={5000} onClose={e => {
                    setShowRepostSuccessMessage(false);
                }}>
                    <Alert severity="success">
                        You buy request is now reactivated and will be available for selling.
                    </Alert>
                </Snackbar>
            </div>
        );
    }

    return (
        <div>
        { isLoaded ? renderList() : <TProgress /> }
        {renderSnackbarMessages()}
        </div>
    );
}


const mapStateToProps = state => {
    return {
        retailer: state.account.retailer
    };
};

export default connect(mapStateToProps, null)(BuyRequestListingPage);
