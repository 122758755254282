import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import {utilStyles} from '../Constants';
import CreateEditBuyRequest from "../components/CreateEditBuyRequest";

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

export default function EditBuyRequestPage(props) {
    const classes = useStyles();

    useEffect(() => {
        document.title = 'Edit Buy Request';
    }, []);

    return (
        <Container component="main" maxWidth="sm">
            <div className={classes.spacer5}></div>
            <CreateEditBuyRequest {...props} />
        </Container>
    );
}

