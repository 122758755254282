import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core";
import {utilStyles} from '../Constants';
import {API} from "aws-amplify";
import DoneIcon from '@material-ui/icons/Done';
import TPageHeader from "../widgets/TPageHeader";
import TProgress from "../widgets/TProgress";

const useStyles = makeStyles(theme => ({
    ...utilStyles(theme)
}));

export default function SignupRepConfirmationLandingPage(props) {
    const classes = useStyles();
    const queryString = props.location.search;
    const params = new URLSearchParams(queryString);
    const [isLoading, setIsLoading] = useState(true);
    const [repUserId] = useState(params.get("u"));
    const [adminFirstName] = useState(params.get("afn"));
    const [retailerId] = useState(params.get("r"));
    const [rep, setRep] = useState(null);
    console.log(repUserId + " " + adminFirstName + " " + retailerId);

    const reqBody = {
        repUserId: repUserId,
        retailerId: retailerId
    };
    useEffect(() => {
        API.post("tryabuy", `/signup/retailer/representative/verify`, {
            body: reqBody
        }).then(rep => {
            setRep(rep);
            document.title = 'Retailer Representative Signup';
            setIsLoading(false);
        }).catch(error => {
            console.log(error.response);
        });
    }, []);

    function renderConfirmation() {
        return (
            <div>
                <TPageHeader icon={DoneIcon}
                             title="Confirmed."
                             subtitle={`${rep.firstName} ${rep.lastName} has been confirmed to sell at Try a Buy.`} />
            </div>
        );
    }

    return (
        <Container maxWidth="sm">
            <div className={classes.spacer5}></div>
            {
                isLoading
                ? <TProgress />
                : renderConfirmation()
            }
        </Container>
    );
}

