
export const ADMIN_USER_IS_SET = "tryabuy/tmp/ADMIN_USER_IS_SET";
export const TMP_OBJECT_IS_SET = "tryabuy/tmp/TMP_OBJECT_IS_SET";

export function setAdminUser(adminUser) {
  return {
    type: ADMIN_USER_IS_SET,
    data: adminUser
  }
}

export function setTmpObject(tmpObject) {
  return {
    type: TMP_OBJECT_IS_SET,
    data: tmpObject
  }
}
